import React from 'react';
import { Text } from 'react-native';
import { Container } from './MyAppointmentsScreen.style';

export default function MyAppointmentsScreen(): JSX.Element {
  return (
    <>
      <Container>
        <Text>Web Home Screen</Text>
      </Container>
    </>
  );
}
