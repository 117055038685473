import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import CandidateIdentity from '../screens/CandidateIdentity/CandidateIdentity';
import FindCareGiverBeneficiaryInfo from '../screens/FindCaregiverBeneficiaryInfo/FindCareGiverBeneficiaryInfo';
import IdentityDocuments from '../screens/IdentityDocuments/IdentityDocuments';
import MissionScreen from '../screens/MissionScreen/MissionScreen';
import CandidateStatus from '../screens/CandidateStatus/CandidateStatus';
import { DrawerNavigator } from './DrawerNavigator/DrawerNavigator';
import { headerWithCustomTitleComponentScreenOptions } from './navigationOptions';
import { AuthenticatedStackParamList } from './types';
import CameraScreen from '../screens/IdentityDocuments/components/CameraScreen/CameraScreen';
import EditableDeclarationHourDetailScreen from '../screens/EditableDeclarationHourDetailScreen/EditableDeclarationHourDetailScreen';
import MonthlyCareGiverHourDeclarationScreen from '../screens/MonthlyCareGiverHourDeclarationScreen/MonthlyCareGiverHourDeclarationScreen';
import HourDeclarationDetailScreen from '../screens/HourDeclarationDetailScreen/HourDeclarationDetailScreen';
import MonthlyFamilyHourDeclarationScreen from '../screens/MonthlyFamilyHourDeclarationScreen/MonthlyFamilyHourDeclarationScreen';
import PaymentInfosScreen from '../screens/PaymentInfosScreen/PaymentInfosScreen';

const AuthenticatedStack = createStackNavigator<AuthenticatedStackParamList>();

export const AuthenticatedNavigator = (): JSX.Element => {
  const { top } = useSafeAreaInsets();

  return (
    <AuthenticatedStack.Navigator>
      <AuthenticatedStack.Screen
        name="DrawerNavigator"
        component={DrawerNavigator}
        options={{ headerShown: false }}
      />
      <AuthenticatedStack.Screen
        name="MissionScreen"
        component={MissionScreen}
        options={headerWithCustomTitleComponentScreenOptions(
          `Détail de la mission`,
          top,
        )}
      />
      <AuthenticatedStack.Screen
        name="FindCareGiverBeneficiaryInfo"
        component={FindCareGiverBeneficiaryInfo}
        options={headerWithCustomTitleComponentScreenOptions(
          'Renseignez vos besoins',
          top,
        )}
      />
      <AuthenticatedStack.Screen
        name="CandidateIdentity"
        component={CandidateIdentity}
        options={headerWithCustomTitleComponentScreenOptions(
          'Mon identité',
          top,
        )}
      />
      <AuthenticatedStack.Screen
        name="CameraScreen"
        component={CameraScreen}
        options={headerWithCustomTitleComponentScreenOptions(
          'Mon identité',
          top,
        )}
      />
      <AuthenticatedStack.Screen
        name="CandidateStatus"
        component={CandidateStatus}
        options={headerWithCustomTitleComponentScreenOptions(
          'Mes statuts',
          top,
        )}
      />
      <AuthenticatedStack.Screen
        name="IdentityDocuments"
        component={IdentityDocuments}
        options={headerWithCustomTitleComponentScreenOptions(
          'Mon identité',
          top,
        )}
      />
      <AuthenticatedStack.Screen
        name="EditableDeclarationHourDetailScreen"
        component={EditableDeclarationHourDetailScreen}
        options={({ route }) => {
          return headerWithCustomTitleComponentScreenOptions(
            route.params.dateRange,
            top,
          );
        }}
      />
      <AuthenticatedStack.Screen
        name="MonthlyCareGiverHourDeclarationScreen"
        component={MonthlyCareGiverHourDeclarationScreen}
        options={({ route }) => {
          return headerWithCustomTitleComponentScreenOptions(
            `Déclarations ${route.params.month}`,
            top,
          );
        }}
      />
      <AuthenticatedStack.Screen
        name="MonthlyFamilyHourDeclarationScreen"
        component={MonthlyFamilyHourDeclarationScreen}
        options={({ route }) => {
          return headerWithCustomTitleComponentScreenOptions(
            `Déclarations ${route.params.month}`,
            top,
          );
        }}
      />
      <AuthenticatedStack.Screen
        name="HourDeclarationDetailScreen"
        component={HourDeclarationDetailScreen}
        options={({ route }) => {
          return headerWithCustomTitleComponentScreenOptions(
            route.params.dateRange,
            top,
          );
        }}
      />
      <AuthenticatedStack.Screen
        name="PaymentInfosScreen"
        component={PaymentInfosScreen}
        options={({ route }) => {
          return headerWithCustomTitleComponentScreenOptions(
            'Informations paiement',
            top,
          );
        }}
      />
    </AuthenticatedStack.Navigator>
  );
};
