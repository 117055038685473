import React from 'react';
import { FourCharacterSpacedInput } from '../../../../components/molecules/FourCharacterSpacedInput/FourCharacterSpacedInput';
import {
  ErrorMessage,
  InputAndErrorContainer,
} from '../../PaymentInfosScreen.style';

type Props = {
  onChangeText: (text: string) => void;
  value: string;
  error?: string;
};

const BicInput = ({ onChangeText, value, error }: Props): JSX.Element => {
  const bicHasError = !!error && !!value;
  return (
    <InputAndErrorContainer>
      <FourCharacterSpacedInput
        label="BIC"
        onChangeText={onChangeText}
        value={value}
        maxLength={13}
        isRequired
        hasError={bicHasError}
        testID="BicInput"
      />
      {bicHasError && <ErrorMessage>{error}</ErrorMessage>}
    </InputAndErrorContainer>
  );
};

export default BicInput;
