import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import {
  ANNOUNCE_TYPES,
  CONTRACT_TYPES,
  InstitutionAnnounce,
  FamilyAnnounce,
} from '../../redux/announce/types';
import { Address, CareGiver, Gender } from '../../redux/users/types';
import { calcDistance } from '../../utils';

export const MAX_HOUR_WORKED = 40;

export const formatDate = (date: Date | null): string | null => {
  if (date === null) return date;
  return `le ${format(date, 'd MMM yyyy', { locale: frenchLocale })}`;
};

export const formatDateRange = (startDate: Date, endDate: Date): string => {
  return `${format(startDate, `d MMM`, {
    locale: frenchLocale,
  })} - ${format(endDate, 'd MMM yyyy', { locale: frenchLocale })}`;
};

// Format Hour Salary from cents to euros
export const getSalaryFromCentsToEuros = (hourSalary: number): string => {
  return (hourSalary / 100).toFixed(2).replace('.', ',');
};

export const formatHourSalary = (
  hourSalary: number,
  totalHourWorked: number,
): string => {
  const totalHourSalary =
    Math.min(totalHourWorked, MAX_HOUR_WORKED) * 4 * hourSalary;
  return (
    (totalHourWorked > MAX_HOUR_WORKED ? "jusqu'à " : '') +
    getSalaryFromCentsToEuros(totalHourSalary)
  );
};

export const formatTotalWorkedHours = (totalHourWorked: number): string => {
  return totalHourWorked < MAX_HOUR_WORKED
    ? totalHourWorked.toString()
    : "jusqu'à " + MAX_HOUR_WORKED;
};

export const getInitials = (firstName: string, lastName: string): string => {
  return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
};

export const removeLeadingZero = (str: string): string => {
  if (str !== '00' && str[0] === '0') return str.substring(1);
  return str;
};

export const formatHour = (hour: string): string => {
  return `${removeLeadingZero(hour.substring(0, 2))}h${hour.substring(3, 5)}`;
};

export const formatPhoneNumber = (phoneNumber: string): string | undefined => {
  if (phoneNumber.length === 10) return phoneNumber.match(/.{1,2}/g)?.join(' ');
  if (phoneNumber.length === 11)
    return ('0' + phoneNumber.substring(2)).match(/.{1,2}/g)?.join(' ');
  return phoneNumber;
};

export const getHonorificFromGender = (gender: Gender): string => {
  switch (gender) {
    case 'man':
      return 'M.';
    case 'woman':
      return 'Mme';
    default:
      return '';
  }
};

export const formatDateWithDayInWeek = (date: string): string =>
  format(new Date(date), 'cccc d LLL', {
    locale: frenchLocale,
  });

export const formatInstitutionAnnounceTitle = (
  announce: InstitutionAnnounce,
): string => {
  const { institution, certificateOfProfiles, contractType } = announce;

  return `🏥 ${certificateOfProfiles.role} ${CONTRACT_TYPES[contractType]} à ${institution.address?.city}`;
};

export const formatFamilyAnnounceTitle = (
  announce: FamilyAnnounce,
  me?: CareGiver,
): string => {
  const { city, gpsLat, gpsLng } = announce.beneficiary.person
    ?.address as Address;

  if (me?.person?.address?.gpsLat && me?.person?.address?.gpsLng) {
    return `🏠 Aide à domicile ${
      CONTRACT_TYPES[announce.contractType]
    } à ${city} (${calcDistance(
      gpsLat as number,
      gpsLng as number,
      me.person?.address.gpsLat,
      me.person?.address.gpsLng,
    ).toFixed(1)} km)`;
  }

  return `🏠 Aide à domicile ${
    CONTRACT_TYPES[announce.contractType]
  } à ${city}`;
};

export const formatAnnounceTitle = (
  announce: InstitutionAnnounce | FamilyAnnounce,
  me?: CareGiver,
): string =>
  announce.announceType === ANNOUNCE_TYPES.FAMILY
    ? formatFamilyAnnounceTitle(announce as FamilyAnnounce, me)
    : formatInstitutionAnnounceTitle(announce as InstitutionAnnounce);

export const getCompleteAddress = (
  address: string,
  city: string,
  postCode: string,
): string => {
  let addressComplete = `${address}`;

  if (city !== '') addressComplete = `${addressComplete}, ${city}`;

  if (postCode !== '') addressComplete = `${addressComplete} - ${postCode}`;

  return addressComplete;
};
