/* istanbul ignore file */

import { USER_TYPES } from '../../redux/users/types';
import { apiClient } from '../networking/apiClient';
import { isWeb } from '../platform';
import {
  clearStore,
  getRefreshTokenCookie,
  storeAccessToken,
  storeRefreshTokenCookie,
} from './store';

export type LoginParams = {
  email: string;
  password: string;
};

export type SignUpParams = LoginParams & {
  type: USER_TYPES;
};

export type UserTokenInfo = {
  account_id: string;
  type: string;
  email: string;
  roles: string[];
  exp: number;
};

export type Token = {
  access: string;
};

export class Client {
  async login(data: LoginParams): Promise<string> {
    const {
      data: result,
      headers,
    }: { data: Token; headers: any } = await apiClient.post(
      '/auth/jwt/create',
      data,
    );

    if (!isWeb()) {
      const refreshTokenCookie = headers['set-cookie'][0];
      if (refreshTokenCookie)
        await storeRefreshTokenCookie(JSON.stringify(refreshTokenCookie));
    }
    if (result.access) await storeAccessToken(result.access);
    return result.access;
  }

  async signUp(data: SignUpParams): Promise<string> {
    await apiClient.post('/users', data);
    return this.login({ email: data.email, password: data.password });
  }

  async logout(): Promise<unknown> {
    const { data } = await apiClient.post('/auth/jwt/logout');
    await clearStore();
    return data;
  }

  async refreshToken(): Promise<string> {
    const refreshTokenCookie = await getRefreshTokenCookie();
    if (refreshTokenCookie === null) this.logout();

    const { data: result }: { data: Token } = await apiClient.post(
      '/auth/jwt/refresh',
      {
        headers: { Cookie: JSON.parse(refreshTokenCookie as string) },
      },
    );
    if (result.access) storeAccessToken(result.access);
    return result.access;
  }
}

const client = new Client();
export default client;
