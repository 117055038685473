import { AntDesign, Feather } from '@expo/vector-icons';
import { minutesToHours, subDays } from 'date-fns';
import React from 'react';
import PrimaryButton from '../../molecules/PrimaryButton/PrimaryButton';
import { useNavigation } from '../../../navigation/types';
import { HourDeclaration } from '../../../redux/hourDeclaration/types';
import { colorUsage } from '../../../stylesheet';
import { getTotalWorkedMinutes } from '../../../screens/__Utils__/hourDeclarationProcessing';
import {
  formatDateRange,
  getHonorificFromGender,
  getInitials,
  getSalaryFromCentsToEuros,
} from '../../../screens/__Utils__/textFormatting';
import {
  HourDeclarationCardContainer,
  HourDeclarationCardTitle,
  HourDeclarationInfoContainer,
  HourDeclarationSubtitle,
  HourDeclarationSubtitleContainer,
  PhotoCircle,
  Initials,
  Capitalize,
  SeeDetailsContainer,
  SeeDetailsText,
  UnderlineDetails,
} from './hourDeclarationCard.style';
import { Gender } from '../../../redux/users/types';

type Props = {
  hourDeclaration: HourDeclaration;
  isReadOnly?: boolean;
  type: 'FAMILY' | 'CAREGIVER';
  onPress: () => void;
};

const HourDeclarationCard = ({
  hourDeclaration,
  isReadOnly,
  type,
  onPress,
}: Props): JSX.Element => {
  const { navigate } = useNavigation();
  const totalWorkedMinutes = getTotalWorkedMinutes(hourDeclaration.details);
  const totalSalary = getSalaryFromCentsToEuros(
    (hourDeclaration.hourSalary * totalWorkedMinutes) / 60,
  );
  const dateRange = formatDateRange(
    new Date(hourDeclaration.startDate),
    subDays(new Date(hourDeclaration.endDate), 1),
  );
  const person =
    type === 'FAMILY'
      ? hourDeclaration.mission.careGiver.person
      : hourDeclaration.mission.announce.beneficiary.person;
  return (
    <HourDeclarationCardContainer>
      <PhotoCircle>
        <Initials>
          {getInitials(person.firstName as string, person.lastName as string)}
        </Initials>
      </PhotoCircle>
      <HourDeclarationInfoContainer>
        <HourDeclarationCardTitle>
          <Capitalize>
            {`${dateRange}\navec ${getHonorificFromGender(
              person.gender as Gender,
            )} ${person.firstName} ${person.lastName}`}
          </Capitalize>
        </HourDeclarationCardTitle>
        <HourDeclarationSubtitleContainer>
          <Feather
            name="clock"
            size={13}
            color={colorUsage.hourDeclarationIcon}
          />
          <HourDeclarationSubtitle>
            {minutesToHours(totalWorkedMinutes)}h au total ({totalSalary}€)
          </HourDeclarationSubtitle>
        </HourDeclarationSubtitleContainer>
        {!isReadOnly ? (
          <PrimaryButton
            title="Valider les heures"
            onPress={onPress}
            RightIcon={
              <AntDesign
                name="arrowright"
                size={13}
                color={colorUsage.buttonText}
              />
            }
          />
        ) : (
          <SeeDetailsContainer
            onPress={() =>
              navigate('HourDeclarationDetailScreen', {
                dateRange: dateRange,
                hourDeclaration: hourDeclaration,
              })
            }
          >
            <UnderlineDetails>
              <SeeDetailsText>Voir le détail</SeeDetailsText>
            </UnderlineDetails>
            <AntDesign name="eyeo" size={18} color={colorUsage.link} />
          </SeeDetailsContainer>
        )}
      </HourDeclarationInfoContainer>
    </HourDeclarationCardContainer>
  );
};

export default HourDeclarationCard;
