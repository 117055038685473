import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage } from '../../../../stylesheet';
import {
  ConfirmationModalButtonContainer,
  PrimaryButtonTopMargin,
} from './ConfirmationModalContent.style';

type Props = {
  firstName: string;
  onPressAbort: () => void;
  onPressConfirm: () => void;
};

const ConfirmationModalContent = ({
  firstName,
  onPressAbort,
  onPressConfirm,
}: Props): JSX.Element => {
  return (
    <ConfirmationModalButtonContainer>
      <PrimaryButton
        title={`Oui, je recrute ${firstName}`}
        onPress={onPressConfirm}
        LeftIcon={
          <AntDesign name="check" size={18} color={colorUsage.buttonText} />
        }
      />
      <PrimaryButtonTopMargin
        title="Non, je continue ma recherche"
        light
        onPress={onPressAbort}
      />
    </ConfirmationModalButtonContainer>
  );
};

export default ConfirmationModalContent;
