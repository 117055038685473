import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HourDeclaration } from './types';

export type HourDeclarationState = Readonly<{
  hourDeclarations: HourDeclaration[];
}>;

const initialState: HourDeclarationState = {
  hourDeclarations: [],
};

const hourDeclarationSlice = createSlice({
  name: 'HourDeclaration',
  initialState,
  reducers: {
    updateHourDeclarations: (
      state,
      action: PayloadAction<HourDeclaration[]>,
    ) => {
      state.hourDeclarations = action.payload;
    },
  },
});

export const { updateHourDeclarations } = hourDeclarationSlice.actions;
export default hourDeclarationSlice.reducer;
