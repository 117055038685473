import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { ChipContainer, ChipText } from './Chip.style';

type Props = {
  dark?: boolean;
  text: string;
  style?: StyleProp<ViewStyle>;
};

const Chip = ({ dark, text, style }: Props): JSX.Element => {
  return (
    <ChipContainer dark={dark} style={style}>
      <ChipText>{text}</ChipText>
    </ChipContainer>
  );
};

export default Chip;
