import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  colorUsage,
  SpacingNumbers,
  textP2Regular,
} from '../../../stylesheet';

export const ChipContainer = styled.View<{ dark?: boolean }>(props => ({
  paddingVertical: SpacingNumbers.xs,
  paddingHorizontal: SpacingNumbers.s,
  backgroundColor: props.dark
    ? colorUsage.chipDarkerBackgroundColor
    : colorUsage.chipLighterBackgroundColor,
  borderRadius: BorderRadiusNumbers.xxl,
}));

export const ChipText = styled(textP2Regular)({
  paddingHorizontal: SpacingNumbers.s,
  color: colorUsage.chipTextColor,
});
