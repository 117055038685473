import React, { useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import {
  InputContainer,
  InputView,
  Label,
  RequiredLabel,
  StyledChoiceButton,
} from './SingleChoiceInput.style';

type Props = {
  label: string;
  style?: StyleProp<ViewStyle>;
  isRequired?: boolean;
  choices: { [s: string]: string };
  chosenTitle?: string;
  onPressChoice: (values: string) => void;
};

const SingleChoiceInput = ({
  label,
  style,
  isRequired,
  choices,
  chosenTitle,
  onPressChoice,
}: Props): JSX.Element => {
  const [chosenOptionTitle, setChosenOptionTitle] = useState<string>(
    chosenTitle ?? '',
  );
  const onPressButton = (title: string) => {
    onPressChoice(choices[title]);
    setChosenOptionTitle(choices[title]);
  };

  return (
    <InputView>
      <Label>
        {label}
        {isRequired && <RequiredLabel> (obligatoire)</RequiredLabel>}
      </Label>
      <InputContainer style={style}>
        {Object.keys(choices).map(title => (
          <StyledChoiceButton
            key={title}
            isPressed={chosenOptionTitle === choices[title]}
            title={title}
            onPress={() => onPressButton(title)}
          />
        ))}
      </InputContainer>
    </InputView>
  );
};

export default SingleChoiceInput;
