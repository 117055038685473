import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  colorUsage,
  SpacingNumbers,
  titleH1StyleSheet,
} from '../../../../stylesheet';

export const NumberInputText = styled.Text<{
  isCompleted: boolean;
  hasError: boolean;
}>(props => ({
  ...titleH1StyleSheet,
  ...(props.hasError && {
    color: colorUsage.numberBlockError,
  }),
  ...(!props.hasError &&
    !props.isCompleted && {
      color: colorUsage.placeHolderText,
    }),
  ...(!props.hasError &&
    props.isCompleted && {
      color: colorUsage.numberBlockText,
    }),
}));

export const NumberInputBlock = styled.View<{
  isFocus: boolean;
  hasError: boolean;
}>(props => ({
  height: 50,
  width: '12%',
  backgroundColor: colorUsage.numberBlockBackground,
  borderRadius: BorderRadiusNumbers.xs,
  marginRight: SpacingNumbers.s,
  justifyContent: 'center',
  ...(props.hasError && {
    borderWidth: 1,
    borderColor: colorUsage.numberBlockError,
  }),
  ...(!props.hasError &&
    props.isFocus && {
      borderWidth: 1,
      borderColor: colorUsage.inputBorderColor,
    }),
}));
