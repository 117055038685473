import styled from '@emotion/native';
import Input from '../../components/atoms/Input/Input';
import { NumberInput } from '../../components/molecules/NumberInput/NumberInput';
import SingleChoiceInput from '../../components/molecules/SingleChoiceInput/SingleChoiceInput';
import { SpacingNumbers, colorUsage, textP2Bold } from '../../stylesheet';

export const Container = styled.View({
  backgroundColor: colorUsage.contentBackground,
  height: '100%',
  paddingHorizontal: SpacingNumbers.m,
  paddingTop: SpacingNumbers.s,
  paddingBottom: SpacingNumbers.xl,
});

export const FormContainer = styled.View({
  height: '100%',
});

export const StyledInput = styled(Input)({ marginBottom: SpacingNumbers.l });

export const StyledNumberInput = styled(NumberInput)({
  marginBottom: SpacingNumbers.l,
});

export const StyledSingleChoiceInput = styled(SingleChoiceInput)({
  marginBottom: SpacingNumbers.l,
});

export const CheckBoxContainer = styled.View({
  marginBottom: SpacingNumbers.l,
  justifyContent: 'space-between',
  flexDirection: 'row',
});

export const Label = styled(textP2Bold)({
  color: colorUsage.labelText,
});
