import React, { useRef } from 'react';
import {
  ErrorContainer,
  InputContainer,
  TextError,
} from './CodeVerification.style';
import NumberBlockInput from '../NumberBlockInput/NumberBlockInput';
import { TextInput } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { colorUsage } from '../../../../stylesheet';

interface Props {
  hasError: boolean;
  emailCode: string;
  onEmailCodeChange: (code: string) => void;
}

const CodeVerification = ({
  hasError,
  emailCode,
  onEmailCodeChange,
}: Props): JSX.Element => {
  const textInputRef = useRef<TextInput>(null);

  const indicatorIndexes = [0, 1, 2, 3, 4, 5];

  return (
    <>
      <InputContainer
        onPress={() => textInputRef.current?.focus()}
        testID="CodeVerification"
      >
        {indicatorIndexes.map(indicatorIndex => (
          <NumberBlockInput
            key={indicatorIndex}
            value={emailCode[indicatorIndex]}
            isFocus={indicatorIndex === emailCode.length}
            hasError={hasError}
          />
        ))}
        <TextInput
          style={{ opacity: 0, top: 0, position: 'absolute' }}
          maxLength={6}
          ref={textInputRef}
          testID="CodeVerificationTextInput"
          autoCorrect={false}
          keyboardType="number-pad"
          value={emailCode}
          onChangeText={onEmailCodeChange}
        />
      </InputContainer>
      {hasError && (
        <ErrorContainer>
          <Ionicons
            name="warning-outline"
            size={16}
            color={colorUsage.numberBlockError}
          />
          <TextError>Votre code d’authentification n’est pas valide.</TextError>
        </ErrorContainer>
      )}
    </>
  );
};

export default CodeVerification;
