import { TouchableOpacity } from 'react-native';
import styled from '@emotion/native';
import { colorUsage, SpacingNumbers, titleH1 } from '../../../stylesheet';
import CustomKeyboardAvoidingView from '../KeyBoardAvoidingView/KeyboardAvoidingView';

export const ModalTitle = styled(titleH1)({
  marginBottom: SpacingNumbers.xl,
  marginHorizontal: SpacingNumbers.xxxl,
});

export const CloseIconContainer = styled(TouchableOpacity)({
  position: 'absolute',
  padding: SpacingNumbers.l,
  right: 0,
  top: 0,
});

export const Container = styled.View({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  justifyContent: 'flex-end',
});

export const Opacity = styled.View({
  backgroundColor: colorUsage.popinBackground,
  opacity: 0.5,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
});

export const ModalFullContentContainer = styled.View({
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  backgroundColor: 'white',
  maxHeight: '95%',
});

export const ContentContainer = styled.ScrollView({
  marginTop: SpacingNumbers.XXL,
});

export const KeyboardAvoidingView = styled(CustomKeyboardAvoidingView)({
  flex: 1,
  justifyContent: 'flex-end',
});
