import React from 'react';
import { colorUsage } from '../../../stylesheet';
import Button from '../../atoms/Button/Button';
import { Color } from '../../atoms/LinearGradient/LinearGradient';
import { AntDesign } from '@expo/vector-icons';
import {
  ButtonContainer,
  Container,
  DescriptionText,
  Title,
} from './UserTypeCard.style';

interface Props {
  title: string;
  description: string;
  buttonTitle: string;
  color: Color;
  onPress: () => void;
}

export default function UserTypeCard({
  title,
  description,
  buttonTitle,
  color,
  onPress,
}: Props): JSX.Element {
  return (
    <Container>
      <Title>{title}</Title>

      <DescriptionText>{description}</DescriptionText>
      <ButtonContainer>
        <Button
          title={buttonTitle}
          onPress={onPress}
          textColor={colorUsage.buttonText}
          backgroundColor={color}
          RightIcon={
            <AntDesign
              name="arrowright"
              size={18}
              color={colorUsage.buttonText}
            />
          }
        />
      </ButtonContainer>
    </Container>
  );
}
