import styled from '@emotion/native';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import { SpacingNumbers } from '../../../../stylesheet';

export const PrimaryButtonTopMargin = styled(PrimaryButton)({
  marginTop: SpacingNumbers.m,
});

export const ConfirmationModalButtonContainer = styled.View({
  paddingHorizontal: SpacingNumbers.l,
  marginBottom: SpacingNumbers.xl,
});
