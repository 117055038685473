import * as Yup from 'yup';
import { PaymentInfos } from '../../redux/paymentInfos/types';
import { Address } from '../../redux/users/types';
import { getCompleteAddress } from '../__Utils__/textFormatting';

export interface PaymentInfosFormValuesType extends PaymentInfos {
  addressComplete: string;
}

export const validationSchema = Yup.object({
  iban: Yup.string()
    .matches(/FR[0-9]{2}[0-9]{5}[A-Z0-9]{18}/, 'IBAN invalide')
    .required(''),
  bic: Yup.string()
    .matches(/^[A-Z]{4}[A-Z]{2}[0-9A-Z]{2}([0-9A-Z]{3})?$/, 'BIC invalide')
    .required(''),
  addressComplete: Yup.string().required(),
  ownerName: Yup.string().required(),
});

export const initValues = (
  paymentInfos: PaymentInfos | undefined,
): PaymentInfosFormValuesType => {
  return {
    iban: paymentInfos?.iban || 'FR',
    bic: paymentInfos?.bic || '',
    address: paymentInfos?.address || ({} as Address),
    addressComplete: getCompleteAddress(
      paymentInfos?.address?.address || '',
      paymentInfos?.address?.city || '',
      paymentInfos?.address?.postCode || '',
    ),
    ownerName: paymentInfos?.ownerName || '',
  };
};
