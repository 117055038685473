import styled from '@emotion/native';
import { colorUsage, textP2Regular } from '../../../stylesheet';

export const TooltipBackground = styled.View({
  backgroundColor: colorUsage.tooltipBackground,
});

export const TooltipText = styled(textP2Regular)({
  color: colorUsage.tooltipText,
});
