import Tooltip from 'react-native-walkthrough-tooltip';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { colorUsage } from '../../../stylesheet';
import { TooltipBackground, TooltipText } from './HelpButton.style';

interface Props {
  description: string;
}

const HelpButton = ({ description }: Props): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Tooltip
      isVisible={isVisible}
      placement="top"
      content={
        <TooltipBackground>
          <TooltipText>{description}</TooltipText>
        </TooltipBackground>
      }
      onClose={() => setIsVisible(false)}
      contentStyle={{ backgroundColor: colorUsage.tooltipBackground }}
      backgroundStyle={{ opacity: 1 }}
    >
      <TouchableOpacity
        onPress={() => {
          setIsVisible(true);
        }}
        testID="tooltipTouchableOpacity"
      >
        <MaterialIcons
          name="info-outline"
          size={24}
          color={colorUsage.tooltip}
        />
      </TouchableOpacity>
    </Tooltip>
  );
};

export default HelpButton;
