import { Ionicons } from '@expo/vector-icons';
import React, { FC } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { colorUsage } from '../../../stylesheet';
import { ErrorContainer, TextError } from './ErrorBlock.style';

type Props = {
  error: string;
  style?: StyleProp<ViewStyle>;
};

export const ErrorBlock: FC<Props> = ({ error, style }) => (
  <ErrorContainer style={style}>
    <Ionicons
      name="alert-circle-outline"
      size={16}
      color={colorUsage.numberBlockError}
    />
    <TextError>{error}</TextError>
  </ErrorContainer>
);
