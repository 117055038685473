import { useHeaderHeight } from '@react-navigation/elements';
import React from 'react';
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
} from 'react-native';

type Props = {
  children: React.ReactNode;
} & KeyboardAvoidingViewProps;

const CustomKeyboardAvoidingView = ({
  children,
  behavior,
  keyboardVerticalOffset,
  style,
}: Props): JSX.Element => {
  const headerHeight = useHeaderHeight();
  return (
    <KeyboardAvoidingView
      behavior={behavior ?? (Platform.OS === 'ios' ? 'padding' : 'height')}
      keyboardVerticalOffset={keyboardVerticalOffset ?? headerHeight}
      style={style}
    >
      {children}
    </KeyboardAvoidingView>
  );
};

export default CustomKeyboardAvoidingView;
