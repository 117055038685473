import React from 'react';

import { Switch as BaseSwitch } from 'react-native';
import { colorUsage } from '../../../stylesheet';

type Props = {
  onValueChange: (value: boolean) => void;
  value: boolean;
  testID?: string;
};

const Switch = ({ onValueChange, value, testID }: Props): JSX.Element => {
  return (
    <BaseSwitch
      trackColor={{
        false: colorUsage.switchOff,
        true: colorUsage.switchOn,
      }}
      thumbColor={colorUsage.thumbColor}
      testID={testID}
      ios_backgroundColor={colorUsage.switchIosBackground}
      onValueChange={onValueChange}
      value={value}
    />
  );
};

export default Switch;
