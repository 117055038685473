import { AntDesign, Feather, MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import HelpButton from '../../../../components/atoms/HelpButton/HelpButton';
import {
  InstitutionAnnounce,
  FamilyAnnounce,
} from '../../../../redux/announce/types';
import { colorUsage } from '../../../../stylesheet';
import {
  MissionTitle,
  StyledText,
  IconTextContainer,
} from './AnnounceCard.style';
import {
  formatAnnounceTitle,
  formatDate,
  formatHourSalary,
  formatTotalWorkedHours,
  getSalaryFromCentsToEuros,
} from '../../../__Utils__/textFormatting';
import { minutesToHours } from 'date-fns';
import { getTotalWorkedMinutes } from '../../../__Utils__/hourDeclarationProcessing';
import { CareGiver } from '../../../../redux/users/types';
import { getAnnounceStartDate } from '../../../__Utils__/scheduleProcessing';

type Props = {
  announce: FamilyAnnounce | InstitutionAnnounce;
  careGiver?: CareGiver;
  hideTooltip?: boolean;
};

const AnnounceCard = ({
  announce,
  careGiver,
  hideTooltip,
}: Props): JSX.Element => {
  const totalWorkedHours = minutesToHours(
    getTotalWorkedMinutes(announce.schedule),
  );
  return (
    <>
      <MissionTitle>{formatAnnounceTitle(announce, careGiver)}</MissionTitle>
      <IconTextContainer>
        <Feather
          name="clock"
          size={16}
          color={colorUsage.missionCardIconColor}
        />
        <StyledText>
          {totalWorkedHours !== 0
            ? formatTotalWorkedHours(totalWorkedHours) + ' heures / semaine'
            : 'A définir'}
        </StyledText>
      </IconTextContainer>
      <IconTextContainer>
        <MaterialIcons
          name="euro"
          size={16}
          color={colorUsage.missionCardIconColor}
        />
        <StyledText>
          {announce.hourSalary && totalWorkedHours !== 0
            ? formatHourSalary(announce.hourSalary, totalWorkedHours) +
              '€ brut / mois'
            : 'A définir'}
        </StyledText>
        {!hideTooltip && (
          <HelpButton
            description={`Salaire horaire ${getSalaryFromCentsToEuros(
              announce.hourSalary,
            )}€ brut / heure en calculant sur un mois de 4 semaines. En fonction du mois, il est possible que vous receviez plus ou moins.`}
          />
        )}
      </IconTextContainer>
      <IconTextContainer>
        <AntDesign
          name="calendar"
          size={16}
          color={colorUsage.missionCardIconColor}
        />
        <StyledText>{formatDate(getAnnounceStartDate(announce))}</StyledText>
      </IconTextContainer>
    </>
  );
};

export default AnnounceCard;
