import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { Linking } from 'react-native';
import { colorUsage } from '../../../stylesheet';
import {
  HelpSectionContainer,
  HelpSectionTitle,
  FAQLinkContainer,
  Underline,
  FAQLinkText,
  HelpSectionTextContainer,
} from './HelpSection.style';

type Props = { title: string; link: string };

const HelpSection = ({ title, link }: Props): JSX.Element => {
  return (
    <HelpSectionContainer>
      <MaterialIcons
        name="info-outline"
        size={24}
        color={colorUsage.helpSectionIcon}
      />
      <HelpSectionTextContainer>
        <HelpSectionTitle>{title}</HelpSectionTitle>
        <FAQLinkContainer onPress={() => Linking.openURL(link)}>
          <Underline>
            <FAQLinkText>En savoir plus</FAQLinkText>
          </Underline>
          <AntDesign name="arrowright" size={18} color={colorUsage.link} />
        </FAQLinkContainer>
      </HelpSectionTextContainer>
    </HelpSectionContainer>
  );
};

export default HelpSection;
