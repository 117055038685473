import styled from '@emotion/native';
import { colorUsage, SpacingNumbers, textP2Regular } from '../../stylesheet';

export const ScreenContainer = styled.View({
  height: '100%',
  paddingBottom: SpacingNumbers.xl,
  paddingHorizontal: SpacingNumbers.m,
  backgroundColor: colorUsage.contentBackground,
});

export const FormContainer = styled.View({
  height: '100%',
  marginTop: SpacingNumbers.xl,
});

export const ErrorMessage = styled(textP2Regular)({
  color: colorUsage.loginErrorText,
});

export const InputAndErrorContainer = styled.View({
  marginBottom: SpacingNumbers.l,
});

export const SavePaymentInfosError = styled.Text({
  color: colorUsage.paymentsInfoSaveError,
  marginBottom: SpacingNumbers.s,
});
