import { TextInput } from 'react-native';
import styled from '@emotion/native';
import {
  SpacingNumbers,
  colorUsage,
  textP1RegularStyleSheet,
  BorderRadiusNumbers,
} from '../../../stylesheet';

export const InputContainer = styled.View<{
  isFocus?: boolean;
}>(({ isFocus }) => ({
  flexDirection: 'row',
  backgroundColor: colorUsage.inputBackgroundColor,
  borderRadius: BorderRadiusNumbers.s,
  alignItems: 'center',
  width: 134,
  paddingHorizontal: SpacingNumbers.m,
  borderWidth: isFocus ? 1 : 0,
  borderColor: colorUsage.inputBorderColor,
}));
export const StyledTimeInput = styled(TextInput)({
  color: colorUsage.labelText,
  ...textP1RegularStyleSheet,
  height: 43,
  paddingHorizontal: SpacingNumbers.s,
});
export const TextInputContainer = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
});
