/* istanbul ignore file */
import Constants from 'expo-constants';

/**
 * We force the typing because typescript does not know our environment
 */
export const Environment = {
  apiUrl: Constants.manifest?.extra?.apiUrl as string,
  nodeEnv: Constants.manifest?.extra?.nodeEnv as string,
  webflowUrl: Constants.manifest?.extra?.webflowUrl as string,
};

if (!Environment.apiUrl) {
  console.error('Api URL not defined ! Check your environement variables');
}

if (!Environment.nodeEnv) {
  console.error('Node env not defined ! Check your environement variables');
}

if (!Environment.webflowUrl) {
  console.error('Webflow URL not defined ! Check your environement variables');
}
