import React, { useState } from 'react';
import { DAY, Schedule } from '../../../../redux/announce/types';
import { FlatList } from 'react-native-gesture-handler';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import {
  DayListContainer,
  DayTitle,
  FlatListContainer,
  ListContainer,
  StyledChoiceButton,
} from './ApplicationScheduleModalContent.style';
import { getGroupedSchedulesSortedByDays } from '../../../__Utils__/scheduleProcessing';
import { formatHour } from '../../../__Utils__/textFormatting';

type Props = {
  schedules: Schedule[];
  onPressApply: (schedulesId: string[]) => void;
};

const renderScheduleButton = (
  chosenSchedule: { [scheduleId: string]: boolean },
  setChosenSchedule: (newChosenSchedule: {
    [scheduleId: string]: boolean;
  }) => void,
  item: Schedule,
) => {
  const onPressAction = () => {
    const newChosenSchedule = { ...chosenSchedule };
    newChosenSchedule[item.id] = !newChosenSchedule[item.id];
    setChosenSchedule(newChosenSchedule);
  };
  return (
    <StyledChoiceButton
      greyUnpressedBackground
      isPressed={chosenSchedule[item.id]}
      title={`${formatHour(item.startHour)}-${formatHour(item.endHour)}`}
      onPress={onPressAction}
    />
  );
};

const getSelectedScheduleIds = (chosenSchedules: Record<string, boolean>) => {
  return Object.keys(chosenSchedules).filter(key => chosenSchedules[key]);
};

const ApplicationScheduleModalContent = ({
  schedules,
  onPressApply,
}: Props): JSX.Element => {
  const groupedSchedulesSortedByDays = getGroupedSchedulesSortedByDays(
    schedules,
  );
  const initialChosenSchedule = schedules.reduce<Record<string, boolean>>(
    (previousValue, currentValue) => {
      previousValue[currentValue.id] = true;
      return previousValue;
    },
    {},
  );

  const [chosenSchedule, setChosenSchedule] = useState<{
    [scheduleId: string]: boolean;
  }>(initialChosenSchedule);

  const selectedScheduleIds = getSelectedScheduleIds(chosenSchedule);
  const isButtonDisabled = selectedScheduleIds.length === 0;

  return (
    <ListContainer>
      {groupedSchedulesSortedByDays.map(daySchedule => (
        <DayListContainer key={daySchedule.day}>
          <DayTitle>{DAY[daySchedule.day]}</DayTitle>
          <FlatListContainer>
            <FlatList
              data={daySchedule.schedules}
              renderItem={({ item }) =>
                renderScheduleButton(chosenSchedule, setChosenSchedule, item)
              }
              extraData={chosenSchedule}
              numColumns={2}
              keyExtractor={item => item.id}
            />
          </FlatListContainer>
        </DayListContainer>
      ))}
      <PrimaryButton
        title="Postuler"
        onPress={() => onPressApply(selectedScheduleIds)}
        disabled={isButtonDisabled}
      />
    </ListContainer>
  );
};

export default ApplicationScheduleModalContent;
