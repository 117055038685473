import React, { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { UnderlineContainer } from './Underline.style';

type Props = {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
};

const Underline = ({ children, style }: Props): JSX.Element => {
  return <UnderlineContainer style={style}>{children}</UnderlineContainer>;
};

export default Underline;
