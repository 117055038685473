import { format, minutesToHours } from 'date-fns';
import React from 'react';
import { HourDeclaration } from '../../../redux/hourDeclaration/types';
import frenchLocale from 'date-fns/locale/fr';
import { capitalize } from 'lodash';
import {
  CardDetails,
  MonthTitle,
  CardContainer,
  CardDetailsContainer,
  ReportsCountContainer,
} from './MonthlyDeclarationCard.style';
import { Entypo, Feather } from '@expo/vector-icons';
import { colorUsage } from '../../../stylesheet';
import {
  getSalaryForDeclaration,
  getWorkedMinutesForDeclarations,
} from '../../../screens/__Utils__/hourDeclarationProcessing';
import { useNavigation } from '../../../navigation/types';

type Props = {
  monthlyDeclaration: HourDeclaration[];
  type: 'CAREGIVER' | 'FAMILY';
};

export const formatNumberOfReportsTitle = (
  monthlyDeclaration: HourDeclaration[],
): string => {
  if (monthlyDeclaration.length > 1)
    return `${monthlyDeclaration.length} rapports déclarés`;
  return `${monthlyDeclaration.length} rapport déclaré`;
};

export const formatNumberOfBeneficiaries = (
  beneficiaries: Set<string>,
): string => {
  return `${beneficiaries.size} bénéficiaire${
    beneficiaries.size > 1 ? 's' : ''
  }`;
};

const MonthlyDeclarationCard = ({
  monthlyDeclaration,
  type,
}: Props): JSX.Element => {
  const beneficiaries = new Set(
    monthlyDeclaration.map(
      declaration => declaration.mission.announce.beneficiary.id,
    ),
  );
  const totalWorkedMinutes = getWorkedMinutesForDeclarations(
    monthlyDeclaration,
  );
  const totalSalary = getSalaryForDeclaration(monthlyDeclaration);
  const { navigate } = useNavigation();

  const navigationPage =
    type === 'CAREGIVER'
      ? 'MonthlyCareGiverHourDeclarationScreen'
      : 'MonthlyFamilyHourDeclarationScreen';
  return (
    <CardContainer
      onPress={() =>
        navigate(navigationPage, {
          month: capitalize(
            format(new Date(monthlyDeclaration[0].startDate), 'MMM yyyy', {
              locale: frenchLocale,
            }),
          ),
          hourDeclarations: monthlyDeclaration,
        })
      }
    >
      <MonthTitle>
        {capitalize(
          format(new Date(monthlyDeclaration[0].startDate), 'MMMM yyyy', {
            locale: frenchLocale,
          }),
        )}
      </MonthTitle>
      <ReportsCountContainer testID="ReportsCountContainer">
        <CardDetailsContainer>
          <Feather
            name="calendar"
            size={13}
            color={colorUsage.monthlyDeclarationIcon}
          />
          <CardDetails>
            {formatNumberOfReportsTitle(monthlyDeclaration)}
          </CardDetails>
        </CardDetailsContainer>

        <Entypo
          name="chevron-right"
          size={23}
          color={colorUsage.monthlyDeclarationCarret}
        />
      </ReportsCountContainer>
      <CardDetailsContainer>
        <Feather
          name="clock"
          size={13}
          color={colorUsage.monthlyDeclarationIcon}
        />
        <CardDetails>
          {minutesToHours(totalWorkedMinutes)}h au total ({totalSalary}
          €)
        </CardDetails>
      </CardDetailsContainer>
      {type === 'CAREGIVER' && (
        <CardDetailsContainer>
          <Feather
            name="users"
            size={13}
            color={colorUsage.monthlyDeclarationIcon}
          />
          <CardDetails>
            {formatNumberOfBeneficiaries(beneficiaries)}
          </CardDetails>
        </CardDetailsContainer>
      )}
    </CardContainer>
  );
};

export default MonthlyDeclarationCard;
