import { createStackNavigator } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AccountCreationProfileChoiceScreen from '../screens/AccountCreationProfileChoiceScreen/AccountCreationProfileChoiceScreen';
import AccountCreationScreen from '../screens/AccountCreationScreen/AccountCreationScreen';
import EmailVerificationScreen from '../screens/EmailVerificationScreen/EmailVerificationScreen';
import HomeScreen from '../screens/HomeScreen/HomeScreen';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import {
  headerWithCustomTitleComponentScreenOptions,
  noHeaderScreenOptions,
} from './navigationOptions';
import { UnauthenticatedStackParamList } from './types';

const UnauthenticatedStack = createStackNavigator<
  UnauthenticatedStackParamList
>();

export const UnauthenticatedNavigator: FunctionComponent = () => {
  const { top } = useSafeAreaInsets();

  return (
    <UnauthenticatedStack.Navigator initialRouteName="Home">
      <UnauthenticatedStack.Screen
        name="Home"
        component={HomeScreen}
        options={noHeaderScreenOptions}
      />
      <UnauthenticatedStack.Screen
        name="Login"
        component={LoginScreen}
        options={headerWithCustomTitleComponentScreenOptions(
          'Se connecter',
          top,
        )}
      />
      <UnauthenticatedStack.Screen
        name="AccountCreation"
        component={AccountCreationScreen}
        options={headerWithCustomTitleComponentScreenOptions(
          'Créer un compte',
          top,
        )}
      />
      <UnauthenticatedStack.Screen
        name="EmailVerification"
        component={EmailVerificationScreen}
        options={headerWithCustomTitleComponentScreenOptions(
          'Créer un compte',
          top,
        )}
      />
      <UnauthenticatedStack.Screen
        name="AccountCreationProfileChoice"
        component={AccountCreationProfileChoiceScreen}
        options={headerWithCustomTitleComponentScreenOptions(
          'Créer un compte',
          top,
        )}
      />
    </UnauthenticatedStack.Navigator>
  );
};
