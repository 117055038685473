import { Beneficiary, Institution, Person } from '../users/types';

export enum CONTRACT_TYPES {
  cdi = 'CDI',
  cdd = 'CDD',
  'cdd-long' = 'CDD long durée',
  internship = 'Stage',
  liberal = 'Libéral',
}

export interface TechnicalInfo {
  title: string;
  description: string;
}

export enum DAY {
  MONDAY = 'Lundi',
  TUESDAY = 'Mardi',
  WEDNESDAY = 'Mercredi',
  THURSDAY = 'Jeudi',
  FRIDAY = 'Vendredi',
  SATURDAY = 'Samedi',
  SUNDAY = 'Dimanche',
}

export interface Substitution {
  id: string;
  startDate: string;
  endDate?: string | null;
}
export interface Schedule {
  id: string;
  day: keyof typeof DAY;
  startHour: string;
  endHour: string;
  substitution?: Substitution | null;
}

export enum STATUS {
  DRAFT = 'draft',
  SEARCH = 'search',
  STAFFED = 'staffed',
  ENDED = 'ended',
}

export enum LEAD_STATUS {
  NOT_CONTACTED = 'not-contacted',
  CONTACTED = 'contacted',
  ESTIMATED = 'estimated',
  REFUSED_ESTIMATION = 'refused-estimation',
  ACCEPTED_ESTIMATION = 'accepted-estimation',
}

export interface Certificate {
  role: string;
}

export interface Lead {
  status: LEAD_STATUS;
}

export interface Announce {
  id: string;
  announceType: ANNOUNCE_TYPES;
  startDate: string;
  endDate?: string;
  contractType: keyof typeof CONTRACT_TYPES;
  hourSalary: number;
  status: STATUS;
  schedule: Schedule[];
  services: (keyof typeof SERVICES)[];
}

export interface FamilyAnnounce extends Announce {
  beneficiary: Beneficiary;
  additionalNeeds: (keyof typeof SERVICES)[];
  lead?: Lead;
}

export interface InstitutionAnnounce extends Announce {
  institution: Institution;
  certificateOfProfiles: Certificate;
}

export type FamilyAnnounceWithDetails = FamilyAnnounce & {
  technicalInfo: TechnicalInfo[];
};

export type InstitutionAnnounceWithDetails = InstitutionAnnounce & {
  technicalInfo: TechnicalInfo[];
};

export type Application = {
  id: string;
  createdAt?: string;
  careGiver: { person: Person };
  announce: FamilyAnnounce | InstitutionAnnounce;
  status: APPLICATION_STATUS;
  schedules: Schedule[];
};

export enum APPLICATION_STATUS {
  TO_VALIDATE = 'to-validate',
  VALIDATED_BY_CC = 'validated-by-cc',
  REFUSED_BY_CC = 'refused-by-cc',
  ACCEPTED_BY_CUSTOMER = 'accepted-by-customer',
  REFUSED_BY_CUSTOMER = 'refused-by-customer',
  EXPIRED = 'expired',
}

export enum ANNOUNCE_TYPES {
  INSTITUTION = 'institution',
  FAMILY = 'family',
}

export enum SERVICES {
  'bed-time' = '🛌  Lever/coucher',
  'washing-dressing' = '🚿  Toilette/habillage',
  'movement' = '💺  Mobilité',
  'rappels' = '💊  Aide à la prise de médicaments',
  'assistance-with-eating' = '🍴  Aide au repas',
  'food-delivery' = '🛒  Courses/livraisons',
  'cleaning' = '✨  Aide ménagère',
  'laundry-ironing' = '👕  Lessive/Repassage',
  'company-stimulation' = '💬  Compagnie',
  'night-watch' = '🌙  Garde de nuit',
  'teleassistance' = '🔔  Téléassistance',
  'alimentary-portage' = '🥗  Portage alimentaire',
  'motorised-transport' = '🚗  Transport',
  'home-handiwork' = '🛠️  Bricolage à domicile',
  'medical-device' = '⛑️  Matériel médical',
  'drug-dispensing' = '💊  Portage de médicaments',
  'emergency' = '🚑  Urgences',
  'oncology' = 'Oncologie',
  'intensive-care' = 'Soins intentifs',
  'geriatrics' = 'Geriatries',
  'general-care' = 'Médecine générale',
  'psychiatry' = 'Psychatrie',
  'neurology' = '🧠  Neurologie',
  'surgery' = 'Chirurgie',
  'internal-medicine' = 'Medicine interne',
  'maternity' = '👶  Maternité',
  'anesthesia' = '  Anesthésie',
  'cardiology' = '🫀 Cardiologie',
  'resuscitation' = 'Réanimation',
  'urgoly' = 'Urologie',
  'pediatrics' = '👦  Pédiatrie',
  'radiology' = '📸 Radiologie',
  'others' = 'Autres',
}
