import React from 'react';
import { colorUsage } from '../../../stylesheet';
import Button, { ButtonProps } from '../../atoms/Button/Button';
import { Color } from '../../atoms/LinearGradient/LinearGradient';

type PrimaryAdditionalProps = {
  light?: boolean;
  disabled?: boolean;
};

type PrimaryProps = Omit<
  PrimaryAdditionalProps & ButtonProps,
  'textColor' | 'borderColor' | 'backgroundColor'
>;

export const colorGradient = (light?: boolean, disabled?: boolean): Color => {
  if (light) return 'white';
  if (disabled) return 'greyDark';
  return 'blue';
};

export const buttonTextColorState = (
  light?: boolean,
  disabled?: boolean,
): string => {
  if (!light) return colorUsage.buttonText;
  if (disabled) return colorUsage.buttonDisabledTextLight;
  return colorUsage.buttonTextLight;
};

export const buttonBorderColor = (
  light?: boolean,
  disabled?: boolean,
): string | undefined => {
  if (light) {
    if (disabled) return colorUsage.buttonDisabledTextLight;
    return colorUsage.buttonTextLight;
  }
};

const PrimaryButton = ({
  light,
  disabled,
  ...props
}: PrimaryProps): JSX.Element => {
  return (
    <Button
      {...props}
      disabled={disabled}
      borderColor={buttonBorderColor(light, disabled)}
      textColor={buttonTextColorState(light, disabled)}
      backgroundColor={colorGradient(light, disabled)}
    />
  );
};

export default PrimaryButton;
