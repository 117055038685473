import { EvilIcons, MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { Text } from 'react-native';
import { useNavigation } from '../../../../navigation/types';
import { colorUsage } from '../../../../stylesheet';
import {
  ButtonContainer,
  CameraText,
  CameraTextContainer,
  Container,
  Description,
  Detail,
  ImageContainer,
  Link,
  LinkText,
  MainContainer,
  PhotoLink,
  PreviousPhotoText,
  Required,
  StyledImage,
  Title,
} from './CameraButton.style';
import { CameraCapturedPicture } from 'expo-camera';
import { TouchableOpacity } from 'react-native';

interface Props {
  title: string;
  description: string;
  details?: string;
  isRequired?: boolean;
  photo: CameraCapturedPicture | null;
  onPhotoChange: (photo: CameraCapturedPicture) => void;
  isPhoto: boolean;
  onDownloadPress: () => void;
}

export const CameraButton = ({
  title,
  description,
  details,
  isRequired,
  photo,
  onPhotoChange,
  isPhoto,
  onDownloadPress,
}: Props): JSX.Element => {
  const { navigate } = useNavigation();

  const openCameraScreen = () => {
    navigate('CameraScreen', { onPhotoChange });
  };

  return (
    <MainContainer>
      {isPhoto && (
        <TouchableOpacity onPress={onDownloadPress}>
          <PreviousPhotoText>
            Vous avez déjà enregistré une photo que vous pouvez retrouver{' '}
            <PhotoLink>ici</PhotoLink>
          </PreviousPhotoText>
        </TouchableOpacity>
      )}
      <Text>
        <Title>{title}</Title> <Detail>{details}</Detail>{' '}
        {isRequired && <Required>(obligatoire)</Required>}
      </Text>
      {photo ? (
        <Container>
          <ImageContainer>
            <StyledImage
              source={{ uri: `data:image/jpg;base64,${photo.base64}` }}
              resizeMode="contain"
            />
          </ImageContainer>
          <Link onPress={openCameraScreen}>
            <EvilIcons name="refresh" size={20} color={colorUsage.link} />
            <LinkText>Reprendre en photo</LinkText>
          </Link>
        </Container>
      ) : (
        <>
          <Description>{description}</Description>
          <ButtonContainer testID="cameraButton" onPress={openCameraScreen}>
            <MaterialIcons
              name="add-a-photo"
              size={24}
              color={colorUsage.cameraButtonPhoto}
            />
            <CameraTextContainer>
              <CameraText>Prendre en photo</CameraText>
            </CameraTextContainer>
          </ButtonContainer>
        </>
      )}
    </MainContainer>
  );
};
