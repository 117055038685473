import { Entypo } from '@expo/vector-icons';
import { format, isSameYear, isToday, isTomorrow, isYesterday } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import React, { useEffect, useState } from 'react';
import { useFetchAppointments } from '../../redux/appointment/hooks';
import { colorUsage } from '../../stylesheet';
import { DateContainer, StyledDate } from './Headers.style';
import ModalWithHeaderAndFooter from '../../components/molecules/ModalWithHeaderAndFooter/ModalWithHeaderAndFooter';
import CalendarPickerModalContent from '../../components/organism/CalendarPickerModalContent/CalendarPickerModalContent.native';

export const formatDate = (date: Date): string => {
  if (isToday(date)) return "Aujourd'hui";
  if (isTomorrow(date)) return 'Demain';
  if (isYesterday(date)) return 'Hier';

  if (isSameYear(date, new Date()))
    return format(date, 'iiii d MMMM', {
      locale: frenchLocale,
    });

  return format(date, 'iiii d MMMM yyyy', {
    locale: frenchLocale,
  });
};

const MyAppointmentsScreenHeader = (): JSX.Element => {
  const [date, setDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [{}, doFetchAppointments] = useFetchAppointments();

  useEffect(() => {
    doFetchAppointments({ date: new Date() });
  }, [doFetchAppointments]);

  const onValidateDate = (selectedDate: Date) => {
    setIsDatePickerOpen(false);
    setSelectedDate(selectedDate);
    doFetchAppointments({ date: selectedDate });
  };
  return (
    <>
      <DateContainer onPress={() => setIsDatePickerOpen(true)}>
        <StyledDate testID="HomeScreenHeader_StyledDate">
          {formatDate(selectedDate)}
        </StyledDate>
        <Entypo
          name="chevron-down"
          size={20}
          color={colorUsage.headerTouchableTitle}
        />
      </DateContainer>
      <ModalWithHeaderAndFooter
        headerTitle="Afficher les rdv du..."
        onClose={() => {
          setIsDatePickerOpen(false);
          setDate(selectedDate);
        }}
        visible={isDatePickerOpen}
        content={
          <CalendarPickerModalContent
            date={date}
            setDate={setDate}
            initialDate={date}
            onPressValidate={() => onValidateDate(date)}
          />
        }
      />
    </>
  );
};

export default MyAppointmentsScreenHeader;
