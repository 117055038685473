import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';

export const createValidationSchema = (
  scheduleIds: string[],
): OptionalObjectSchema<Record<
  string,
  OptionalObjectSchema<{
    startHour: RequiredStringSchema<string | undefined>;
    endHour: RequiredStringSchema<string | undefined>;
  }>
>> =>
  Yup.object(
    scheduleIds.reduce(
      (previousSchema, newId) => ({
        ...previousSchema,
        [newId]: Yup.object().shape({
          startHour: Yup.string()
            .required()
            .test({ message: 'valid-date', test: isTimeValid }),
          endHour: Yup.string()
            .required()
            .test({ message: 'valid-date', test: isTimeValid }),
        }),
      }),
      {},
    ),
  );

export const isTimeValid = (time: string | undefined): boolean => {
  if (time?.length !== 4) return false;
  const hour = Number(time.substring(0, 2));
  const minutes = Number(time.substring(2));
  return hour < 24 && minutes < 60;
};
