import styled from '@emotion/native';
import { TouchableOpacity } from 'react-native';
import Underline from '../../atoms/Underline/Underline';
import {
  BorderRadiusNumbers,
  BoxShadow,
  colorUsage,
  interactiveLink,
  SpacingNumbers,
  textP1Bold,
  textP2Regular,
} from '../../../stylesheet';

export const InfoBlockContainer = styled.View({
  padding: SpacingNumbers.m,
  marginTop: SpacingNumbers.l,
  backgroundColor: colorUsage.paymentsInfoBlockBackground,
  ...BoxShadow.shadowLight,
  shadowOpacity: BoxShadow.shadowLightOpacity,
  elevation: BoxShadow.shadowLightElevation,
  borderRadius: BorderRadiusNumbers.s,
});

export const TitleContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: '100%',
});

export const Title = styled(textP1Bold)({
  textAlign: 'left',
  color: colorUsage.paymentsInfoBlockTitleMain,
  flex: 1,
  marginRight: SpacingNumbers.m,
});

export const TitleEmphasis = styled(textP1Bold)({
  textAlign: 'left',
  color: colorUsage.paymentsInfoBlockTitleSecondary,
});

export const ResizedImage = styled.Image({
  height: 36,
  width: 49.5,
});

export const BlockText = styled(textP2Regular)({
  color: colorUsage.paymentsInfoBlockText,
  marginVertical: SpacingNumbers.s,
});

export const UpdateInfosTouchable = styled(TouchableOpacity)({
  flexDirection: 'row',
  alignItems: 'center',
});

export const MarginUnderline = styled(Underline)({
  marginRight: SpacingNumbers.s,
});

export const UpdateInfosText = styled(interactiveLink)({
  textDecorationLine: 'none',
});
