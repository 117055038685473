import { AxiosResponse } from 'axios';
import { apiClient } from '../../services/networking/apiClient';
import { authenticatedApiClient } from '../../services/networking/authenticatedApiClient';

import { CareGiver, CareGiverCharacteristics } from './types';

export async function updateCareGiver(
  careGiverCharacteristics: CareGiverCharacteristics,
): Promise<CareGiverCharacteristics> {
  const url = `/care-giver`;
  const { data } = await authenticatedApiClient.patch<CareGiverCharacteristics>(
    url,
    careGiverCharacteristics,
  );
  return data;
}

export async function fetchEmailVerification(
  email: string,
): Promise<AxiosResponse<void>> {
  const url = `/email-verification`;
  return apiClient.post(url, { email });
}

export async function fetchCodeCheck(
  email: string,
  code: string,
): Promise<void> {
  const url = `/email-verification/check`;
  await apiClient.post<void>(url, { email, code });
}

export async function fetchMeAsCareGiver(): Promise<CareGiver> {
  const url = `/care-giver/me`;
  const { data } = await authenticatedApiClient.get<CareGiver>(url);
  return data;
}
