import React from 'react';
import Header from '../../components/organism/Header/Header';
import { IconContainer } from './DrawerNavigator.style';

type OptionsHeader = {
  headerShown: boolean;
  header: () => JSX.Element;
};

export const setOptionsHeader = (content: JSX.Element): OptionsHeader => {
  return {
    headerShown: true,
    header: (): JSX.Element => {
      const CustomHeader = <Header content={content} />;
      return CustomHeader;
    },
  };
};

export type ColorProps = {
  color: string;
};
export type OptionsIcon = {
  drawerIcon: (props: { color: string }) => JSX.Element;
};

export const setOptionsIcon = (
  Icon: ({ color }: ColorProps) => JSX.Element,
): OptionsIcon => {
  return {
    drawerIcon: (props: { color: string }) => {
      const DisplayedIcon = (
        <IconContainer>
          <Icon color={props.color} />
        </IconContainer>
      );
      return DisplayedIcon;
    },
  };
};
