/* eslint-disable max-lines */
import React, { useState } from 'react';
import StepperScreen from '../../components/organism/StepperScreen/StepperScreen';
import {
  Container,
  StyledInput,
  FormContainer,
  StyledSingleChoiceInput,
} from './FindCareGiverBeneficiaryInfo.style';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { AntDesign, Feather, Ionicons } from '@expo/vector-icons';
import { colorUsage } from '../../stylesheet';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { Pressable, View } from 'react-native';
import ModalWithHeaderAndFooter from '../../components/molecules/ModalWithHeaderAndFooter/ModalWithHeaderAndFooter';
import { format } from 'date-fns';
import CalendarPickerModalContent from '../../components/organism/CalendarPickerModalContent/CalendarPickerModalContent.native';
import { Gender } from './types';

interface Values {
  gender: Gender | undefined;
  firstName: string;
  lastName: string;
  birthday: Date | undefined;
  address: string;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  birthday: Yup.date().required(),
  gender: Yup.string()
    .matches(/(woman|man)/)
    .required(),
  address: Yup.string().required(),
});

export const FindCareGiverBeneficiaryInfo = (): JSX.Element => {
  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState(false);
  const [preselectedDate, setPreSelectedDate] = useState<Date | null>(null);
  let isButtonDisabled: boolean;
  let onPressValidate: () => void;
  let onPressChoice: (value: string) => void;

  const submitForm = (values: {
    firstName: string;
    lastName: string;
    birthday: Date | undefined;
    gender: string | undefined;
    address: string;
  }): void => {
    return;
  };

  const initialValues: Values = {
    firstName: '',
    lastName: '',
    birthday: undefined,
    gender: undefined,
    address: '',
  };

  return (
    <Container>
      <StepperScreen
        title={`1/4 - \nParlez-nous du bénéficiaire`}
        chapeau="Ces informations nous permettront de vous mettre en relation avec les profils les plus proches de votre besoin."
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            isButtonDisabled = !(isValid && dirty);
            onPressValidate = () => {
              setFieldValue('birthday', preselectedDate);
              setIsCalendarPickerOpen(false);
            };
            onPressChoice = (value: string) => setFieldValue('gender', value);
            return (
              <FormContainer>
                <StyledSingleChoiceInput
                  label="Civilité"
                  choices={Gender}
                  isRequired
                  onPressChoice={onPressChoice}
                />
                <StyledInput
                  label="Nom du bénéficiaire"
                  onChangeText={handleChange('firstName')}
                  value={values.firstName}
                  placeholder="Nom"
                  isRequired
                  Icon={
                    <AntDesign
                      name="user"
                      size={17}
                      color={colorUsage.findCareGiverFieldIconColor}
                    />
                  }
                />
                <StyledInput
                  label="Prénom du bénéficiaire"
                  onChangeText={handleChange('lastName')}
                  value={values.lastName}
                  placeholder="Prénom"
                  isRequired
                  Icon={
                    <AntDesign
                      name="user"
                      size={17}
                      color={colorUsage.findCareGiverFieldIconColor}
                    />
                  }
                />
                <Pressable
                  onPress={() => setIsCalendarPickerOpen(true)}
                  testID="pressable_birthday_input"
                >
                  <View pointerEvents="none">
                    <StyledInput
                      label="Sa date de naissance"
                      onChangeText={handleChange('birthday')}
                      value={
                        values.birthday === undefined
                          ? ''
                          : format(values.birthday, 'dd/MM/yyyy')
                      }
                      placeholder="jj/mm/aaaa"
                      isRequired
                      Icon={
                        <Feather
                          name="calendar"
                          size={20}
                          color={colorUsage.findCareGiverFieldIconColor}
                        />
                      }
                    />
                  </View>
                </Pressable>
                <ModalWithHeaderAndFooter
                  headerTitle="Date de naissance"
                  content={
                    <CalendarPickerModalContent
                      date={preselectedDate}
                      setDate={setPreSelectedDate}
                      onPressValidate={onPressValidate}
                      minDate={new Date(1900)}
                      initialDate={new Date()}
                    />
                  }
                  onClose={() => setIsCalendarPickerOpen(false)}
                  visible={isCalendarPickerOpen}
                />

                <StyledInput
                  label="Lieu de l'intervention"
                  onChangeText={handleChange('address')}
                  value={values.address}
                  placeholder="Adresse"
                  isRequired
                  Icon={
                    <Ionicons
                      name="md-location-outline"
                      size={20}
                      color={colorUsage.findCareGiverFieldIconColor}
                    />
                  }
                />

                <PrimaryButton
                  title="Prochaine étape"
                  onPress={handleSubmit}
                  light
                  RightIcon={
                    <AntDesign
                      name="arrowright"
                      size={13}
                      color={
                        isButtonDisabled
                          ? colorUsage.buttonDisabledTextLight
                          : colorUsage.buttonTextLight
                      }
                    />
                  }
                  disabled={isButtonDisabled}
                />
              </FormContainer>
            );
          }}
        </Formik>
      </StepperScreen>
    </Container>
  );
};

export default FindCareGiverBeneficiaryInfo;
