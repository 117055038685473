import styled from '@emotion/native';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage, SpacingNumbers, textP2Bold } from '../../../../stylesheet';

export const CesuContentContainer = styled.View({
  marginBottom: SpacingNumbers.l,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const BoldText = styled(textP2Bold)({
  color: colorUsage.paymentModalText,
});

export const TopMarginPrimaryButton = styled(PrimaryButton)({
  marginTop: SpacingNumbers.l,
});
