import { RouteProp } from '@react-navigation/native';
import { capitalize, Dictionary, groupBy } from 'lodash';
import React from 'react';
import { SectionList } from 'react-native';
import {
  AuthenticatedStackParamList,
  useNavigation,
} from '../../navigation/types';
import { HourDeclaration } from '../../redux/hourDeclaration/types';
import { SpacingNumbers } from '../../stylesheet';
import HourDeclarationCard from '../../components/organism/hourDeclarationCard/hourDeclarationCard';
import {
  formatDateRange,
  getHonorificFromGender,
} from '../__Utils__/textFormatting';
import {
  MonthlyHourDeclarationScreenContainer,
  SectionTitle,
} from './MonthlyFamilyHourDeclarationScreen.style';
import { subDays } from 'date-fns';
import { Gender } from '../../redux/users/types';

type Props = {
  route: RouteProp<
    AuthenticatedStackParamList,
    'MonthlyFamilyHourDeclarationScreen'
  >;
};

const MonthlyFamilyHourDeclarationScreen = ({ route }: Props): JSX.Element => {
  const hourDeclarations = route.params.hourDeclarations;
  const hourDeclarationByCareGiver = groupBy(
    hourDeclarations,
    declaration => declaration.mission.careGiver.id,
  ) as Dictionary<HourDeclaration[]>;
  const sections = Object.values(hourDeclarationByCareGiver).map(
    declarations => ({
      careGiver: declarations[0].mission.careGiver,
      data: declarations,
    }),
  );

  const { navigate } = useNavigation();

  return (
    <MonthlyHourDeclarationScreenContainer>
      <SectionList
        contentContainerStyle={{ paddingTop: SpacingNumbers.s }}
        sections={sections}
        keyExtractor={(item, index) => item.id + index}
        renderItem={({ item }) => (
          <HourDeclarationCard
            hourDeclaration={item}
            isReadOnly
            onPress={
              // istanbul ignore next
              () =>
                navigate('HourDeclarationDetailScreen', {
                  dateRange: formatDateRange(
                    new Date(item.startDate),
                    subDays(new Date(item.endDate), 1),
                  ),
                  hourDeclaration: item,
                })
            }
            type="FAMILY"
          />
        )}
        renderSectionHeader={({ section: { careGiver } }) => (
          <SectionTitle>
            {getHonorificFromGender(careGiver.person.gender as Gender)}{' '}
            {capitalize(careGiver.person.lastName as string)}
          </SectionTitle>
        )}
        stickySectionHeadersEnabled={false}
      />
    </MonthlyHourDeclarationScreenContainer>
  );
};

export default MonthlyFamilyHourDeclarationScreen;
