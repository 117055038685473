import styled from '@emotion/native';
import PrimaryButton from '../../molecules/PrimaryButton/PrimaryButton';
import { SpacingNumbers } from '../../../stylesheet';

export const Container = styled.View({
  marginTop: SpacingNumbers.s,
});

export const StyledButton = styled(PrimaryButton)({
  marginTop: SpacingNumbers.xxl,
});
