import styled from '@emotion/native';
import {
  SpacingNumbers,
  colorUsage,
  titleH3,
  textP1Regular,
} from '../../../stylesheet';

export const HourDeclarationScreenContainer = styled.View({
  paddingHorizontal: SpacingNumbers.m,
  backgroundColor: colorUsage.contentBackground,
  height: '100%',
});

export const HourDeclarationTitle = styled(titleH3)({
  marginBottom: SpacingNumbers.m,
});

export const NoSearchResultContainer = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const NoSearchResultText = styled(textP1Regular)({
  marginTop: SpacingNumbers.xl,
  width: '100%',
  color: colorUsage.noHourDeclarationTextColor,
});

export const StyledImage = styled.Image({
  width: 312,
  height: 161,
});
