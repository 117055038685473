import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { Linking, StatusBar } from 'react-native';
import { colorUsage } from '../../stylesheet';
import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-fr';
import {
  EmailInput,
  PasswordInput,
  StyledButton,
  MainContainer,
  AccountCreationErrorBlock,
  CGUBlock,
  Link,
  LinkText,
  CGUText,
  StyledCheckBox,
} from './AccountCreationScreen.style';
import PasswordValidation from './components/PasswordValidation/PasswordValidation';
import { FeedbackType } from '@zxcvbn-ts/core/dist/types';
import { useNavigation } from '../../navigation/types';
import StepperScreen from '../../components/organism/StepperScreen/StepperScreen';
import { useDispatch } from 'react-redux';
import { updateCredential } from '../../redux/signUp';
import { fetchEmailVerification } from '../../redux/users/api';
import { AxiosError } from 'axios';
import { CGU_LINK } from '../__Utils__/const/CGUs.link';

export interface Zxcvbn {
  score: number;
  feedback: FeedbackType;
}

export const getErrorMessage = (apiMessage: string): string => {
  switch (apiMessage) {
    case 'Wait a bit longer':
      return 'Une erreur est survenue, ressayer dans 10s';
    default:
      return "Impossible de créer un compte avec cette addresse email, veuillez vérifier l'adresse indiquée.";
  }
};

export const getZxcvbn = (password: string): Zxcvbn => {
  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  };

  ZxcvbnOptions.setOptions(options);

  const result = zxcvbn(password);
  return { score: result.score, feedback: result.feedback };
};

export default function AccountCreationScreen(): JSX.Element {
  const [email, onEmailChange] = useState('');
  const [password, onPasswordChange] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [areCGUChecked, setChecked] = useState(false);

  const isPassword = password.length > 0;
  const indicator = getZxcvbn(password);

  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  const onCGUPress = () => {
    Linking.openURL(CGU_LINK);
  };

  const onValidatePress = async () => {
    try {
      setError(null);
      await fetchEmailVerification(email);
      dispatch(updateCredential({ email, password }));
      navigate('EmailVerification', { email: email });
    } catch (error) {
      const { response } = error as AxiosError;
      const errorMessage = response ? response.data.message : '';
      setError(getErrorMessage(errorMessage));
    }
  };

  const checkBoxColor = areCGUChecked ? colorUsage.link : undefined;

  return (
    <MainContainer>
      <StatusBar barStyle="default" />
      <StepperScreen
        title={`1/3 - \nBienvenue chez Click&Care ! Commençons par définir vos identifiants !`}
      >
        {error && <AccountCreationErrorBlock error={error} />}
        <EmailInput
          label="Email"
          onChangeText={onEmailChange}
          value={email}
          placeholder="example@clickandcare.com"
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          autoCorrect={false}
          autoCompleteType="email"
          placeholderTextColor={colorUsage.placeHolderText}
          Icon={
            <MaterialCommunityIcons
              name="email-outline"
              size={16}
              color={colorUsage.placeHolderText}
            />
          }
        />
        <PasswordInput
          label="Mot de passe"
          onChangeText={onPasswordChange}
          value={password}
          secureTextEntry
          placeholder="••••••••••"
          placeholderTextColor={colorUsage.placeHolderText}
          Icon={
            <Feather name="key" size={16} color={colorUsage.placeHolderText} />
          }
        />
        <PasswordValidation indicator={indicator} isPassword={isPassword} />
      </StepperScreen>
      <CGUBlock>
        <StyledCheckBox
          value={areCGUChecked}
          onValueChange={setChecked}
          color={checkBoxColor}
          testID="CheckBoxCGU"
        />
        <Link onPress={onCGUPress}>
          <CGUText>
            Accepter les <LinkText>Conditions Générales d'Utilisation</LinkText>
          </CGUText>
        </Link>
      </CGUBlock>
      <StyledButton
        title="Valider mon email"
        onPress={onValidatePress}
        disabled={indicator.score < 3 || !areCGUChecked}
      />
    </MainContainer>
  );
}
