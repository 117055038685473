import React from 'react';
import CalendarPicker from 'react-native-calendar-picker';
import { Entypo } from '@expo/vector-icons';
import { Moment } from 'moment';
import { colorUsage } from '../../../stylesheet';
import { CalendarContainer, commonStyle } from './CalendarPicker.style';
import { isBefore } from 'date-fns';

type Props = {
  initialDate: Date;
  date: Date | null;
  setDate: (date: Date) => void;
  minDate?: Date;
};

const StyledCalendarPicker = ({
  initialDate,
  date,
  setDate,
  minDate,
}: Props): JSX.Element => {
  return (
    <>
      <CalendarContainer>
        <CalendarPicker
          minDate={minDate}
          onDateChange={(date: Moment) => setDate(new Date(date.toString()))}
          weekdays={['L', 'M', 'M', 'J', 'V', 'S', 'D']}
          months={[
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
          ]}
          startFromMonday
          selectedDisabledDatesTextStyle={commonStyle.disabledDatesTextStyle}
          selectedDayColor={colorUsage.selectedCalendarDayColor}
          selectedDayTextColor={colorUsage.selectedCalendarTextColor}
          selectedStartDate={date || undefined}
          initialDate={date || initialDate}
          showDayStragglers
          disabledDatesTextStyle={commonStyle.disabledDatesTextStyle}
          selectedDayTextStyle={commonStyle.selectedDayTextStyle}
          textStyle={commonStyle.textStyle}
          dayLabelsWrapper={{
            borderColor: colorUsage.calendarBackground,
          }}
          customDayHeaderStyles={() => ({
            textStyle: { color: colorUsage.disabledCalendarTextColor },
          })}
          monthTitleStyle={commonStyle.monthTitleStyle}
          yearTitleStyle={commonStyle.yearTitleStyle}
          headerWrapperStyle={{
            justifyContent: 'center',
          }}
          monthYearHeaderWrapperStyle={{ marginLeft: 32, marginRight: 32 }}
          selectMonthTitle="Choisissez le mois de l'année "
          selectYearTitle="Choisissez l'année"
          nextComponent={
            <Entypo
              name="chevron-right"
              size={23}
              color={colorUsage.calendarNextPreviousIcon}
            />
          }
          previousComponent={
            <Entypo
              name="chevron-left"
              size={23}
              color={colorUsage.calendarNextPreviousIcon}
            />
          }
          onMonthChange={(date: Moment) => {
            minDate && isBefore(new Date(date.toString()), minDate)
              ? setDate(minDate)
              : setDate(new Date(date.format('YYYY-MM-DD')));
          }}
        />
      </CalendarContainer>
    </>
  );
};

export default StyledCalendarPicker;
