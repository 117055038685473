import React from 'react';
import { StyledTitle } from './Headers.style';

type Props = {
  title: string;
};

const BasicTextHeader = ({ title }: Props): JSX.Element => {
  return <StyledTitle>{title}</StyledTitle>;
};

export default BasicTextHeader;
