import { differenceInMinutes } from 'date-fns';
import { groupBy } from 'lodash';
import {
  HourDeclaration,
  HourDeclarationDetails,
} from '../../redux/hourDeclaration/types';
import { getSalaryFromCentsToEuros } from './textFormatting';

export const getTotalWorkedMinutes = (
  details: { startHour: string; endHour: string }[],
): number => {
  const totalInMinutes = details.reduce((previousValue, currentValue) => {
    const startDateTime = new Date(`2020-01-01T${currentValue.startHour}`);
    const endDateTime = new Date(`2020-01-01T${currentValue.endHour}`);
    return (previousValue += differenceInMinutes(endDateTime, startDateTime));
  }, 0);
  return totalInMinutes;
};

export const getWorkedMinutesForDeclarations = (
  monthlyDeclaration: HourDeclaration[],
): number => {
  return monthlyDeclaration.reduce<number>(
    (prev, curr) => (prev += getTotalWorkedMinutes(curr.details)),
    0,
  );
};

export const getSalaryForDeclaration = (
  monthlyDeclaration: HourDeclaration[],
): string => {
  return getSalaryFromCentsToEuros(
    monthlyDeclaration.reduce<number>(
      (prev, curr) =>
        (prev += (curr.hourSalary * getTotalWorkedMinutes(curr.details)) / 60),
      0,
    ),
  );
};

export const getGroupedDetailsSortedByAppointmentDate = (
  details: HourDeclarationDetails[],
): Record<string, HourDeclarationDetails[]> => {
  const orderedDetails = [...details].sort(
    (a, b) =>
      new Date(`${a.appointmentDate}T${a.startHour}`).getTime() -
      new Date(`${b.appointmentDate}T${b.startHour}`).getTime(),
  );
  const sortedDetails = groupBy(
    orderedDetails,
    detail => detail.appointmentDate,
  );
  return sortedDetails;
};
