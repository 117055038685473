import React from 'react';
import {
  ModalFullContentContainer,
  ModalTitle,
  CloseIconContainer,
  Container,
  ContentContainer,
  Opacity,
  KeyboardAvoidingView,
} from './ModalPopin.style';
import { AntDesign } from '@expo/vector-icons';
import { colorUsage } from '../../../stylesheet';
import { Modal } from 'react-native';

type Props = {
  title: string;
  onClose: () => void;
  content: JSX.Element;
  visible: boolean;
};
const ModalPopin = ({
  title,
  onClose,
  content,
  visible,
}: Props): JSX.Element => {
  return (
    <Modal
      statusBarTranslucent
      visible={visible}
      transparent
      testID={`${title}_Modal_ModalPopin`}
      animationType="slide"
    >
      <Container>
        <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={0}>
          <Opacity />
          <ModalFullContentContainer>
            <ContentContainer>
              <ModalTitle>{title}</ModalTitle>
              {content}
            </ContentContainer>
            <CloseIconContainer
              testID="ModalPopin_CloseIconContainer"
              onPress={onClose}
            >
              <AntDesign
                name="close"
                size={24}
                color={colorUsage.modalCloseIcon}
              />
            </CloseIconContainer>
          </ModalFullContentContainer>
        </KeyboardAvoidingView>
      </Container>
    </Modal>
  );
};

export default ModalPopin;
