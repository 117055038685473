import { FontAwesome5 } from '@expo/vector-icons';
import React, { useEffect } from 'react';
import { useNavigation } from '../../../navigation/types';
import { selectMeAsCareGiver } from '../../../redux/users';
import { useSelector } from 'react-redux';
import { useFetchMeAsCareGiver } from '../../../redux/users/hook';
import { colorUsage } from '../../../stylesheet';
import {
  BlockText,
  InfoBlockContainer,
  MarginUnderline,
  ResizedImage,
  Title,
  TitleContainer,
  TitleEmphasis,
  UpdateInfosText,
  UpdateInfosTouchable,
} from './PaymentsInfoBlock.style';

type Props = {
  type: 'FAMILY' | 'CAREGIVER';
};

const PaymentsInfoBlock = ({ type }: Props): JSX.Element => {
  let title: string;
  let description: string;
  const { navigate } = useNavigation();
  const [, doFetchMeAsCareGiver] = useFetchMeAsCareGiver();
  useEffect(() => {
    if (type === 'CAREGIVER') {
      doFetchMeAsCareGiver();
    }
  }, [doFetchMeAsCareGiver, type]);

  const careGiver = useSelector(selectMeAsCareGiver);
  const isWoman = careGiver?.person?.gender === 'woman';

  switch (type) {
    case 'CAREGIVER':
      title = `Je suis rémunéré${isWoman ? 'e' : ''} en\n`;
      description =
        'Dès validation de votre déclaration horaire, votre employeur pourra déclencher le paiement par virement SEPA ou CESU.';
      break;
    case 'FAMILY':
      title = 'Je paie mes intervenants en\n';
      description =
        'Dès validation de leurs déclarations horaires, vous pourrez déclencher le paiement par virement SEPA ou CESU.';
      break;
  }

  return (
    <InfoBlockContainer>
      <TitleContainer>
        <Title>
          {title}
          <TitleEmphasis>CESU</TitleEmphasis> et/ou
          <TitleEmphasis> virement SEPA</TitleEmphasis>
        </Title>
        <ResizedImage
          source={require('../../../assets/images/PaymentsInfoBlockIllustration.png')}
        />
      </TitleContainer>
      <BlockText>{description}</BlockText>
      <UpdateInfosTouchable onPress={() => navigate('PaymentInfosScreen')}>
        <MarginUnderline>
          <UpdateInfosText>Modifier mes infos bancaires</UpdateInfosText>
        </MarginUnderline>
        <FontAwesome5 name="pen" size={12} color={colorUsage.link} />
      </UpdateInfosTouchable>
    </InfoBlockContainer>
  );
};

export default PaymentsInfoBlock;
