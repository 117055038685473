import { LinkingOptions } from '@react-navigation/native';
import MissionScreen from '../screens/MissionScreen/MissionScreen';
import { DrawerNavigator } from './DrawerNavigator/DrawerNavigator';
import { careGiverRoutes } from './DrawerNavigator/careGiverRoutes';
import { AuthenticatedStackParamList, DeepLinkPath, Route } from './types';
import { familyRoutes } from './DrawerNavigator/familyRoutes';

export const initialRouteName = 'DrawerNavigator';

export const drawerNavigatorPathConfig: LinkingOptions<
  AuthenticatedStackParamList
>['config'] = {
  initialRouteName,
  screens: {
    ...careGiverRoutes.reduce(
      (route, currentRoute) => ({
        ...route,
        [currentRoute.name]: currentRoute.pathConfig || currentRoute.path,
      }),
      {},
    ),
    ...familyRoutes.reduce(
      (route, currentRoute) => ({
        ...route,
        [currentRoute.name]: currentRoute.pathConfig || currentRoute.path,
      }),
      {},
    ),
  },
};

export const routes: Route[] = [
  {
    name: 'DrawerNavigator',
    component: DrawerNavigator,
    pathConfig: drawerNavigatorPathConfig,
  },
  {
    name: 'MissionScreen',
    component: MissionScreen,
    path: DeepLinkPath.MISSION_SCREEN,
  },
];
