import * as Yup from 'yup';
import { CareGiver, Gender as GENDER } from '../../../../redux/users/types';
import { getCompleteAddress } from '../../../__Utils__/textFormatting';

export interface IdentityFormValuesType {
  firstName: string;
  lastName: string;
  gender: string | undefined;
  addressComplete: string;
  address?: {
    address: string;
    postCode: string;
    gpsLat?: number;
    gpsLng?: number;
    city: string;
  };
  phoneNumber: string;
  socialSecurityNumber: string;
  hasCar: boolean;
}

export const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  gender: Yup.string()
    .matches(/(woman|man)/)
    .required(),
  addressComplete: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  socialSecurityNumber: Yup.string().required(),
  hasCar: Yup.boolean().required(),
});

export const initValues = (careGiver: CareGiver): IdentityFormValuesType => {
  return {
    firstName: careGiver.person.firstName ?? '',
    lastName: careGiver.person.lastName ?? '',
    gender: careGiver.person.gender as GENDER,
    address:
      careGiver.person.address === null ? undefined : careGiver.person.address,
    addressComplete: careGiver.person.address?.address
      ? getCompleteAddress(
          careGiver.person.address.address,
          careGiver.person.address.city,
          careGiver.person.address.postCode,
        )
      : '',
    phoneNumber: careGiver.person.phone ?? '',
    socialSecurityNumber: careGiver.person.numberSocialSecurity ?? '',
    hasCar: careGiver.hasCar ?? false,
  };
};
