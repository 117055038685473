import React from 'react';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { Text, TouchableOpacity } from 'react-native';
import { useAuth } from '../../../services/auth/context';
import { Container, User } from './Header.style';

const Header = (props: { content: JSX.Element }): JSX.Element => {
  const navigation = useNavigation();
  const { user } = useAuth();
  return (
    <Container>
      <TouchableOpacity
        onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
      >
        <Text>Appuyer Ici</Text>
      </TouchableOpacity>
      {props.content}
      {user ? <User>Espace de </User> : <Text>Se connecter</Text>}
    </Container>
  );
};

export default Header;
