import React from 'react';
import Input, { InputProps } from '../../atoms/Input/Input';

const fourCharactersRegex = /(.{4})/g;

// This regex gets all groups of 4 characters but the last one
// For 12345678 => Will only match 1234
const allGroupButLastRegex = /(.{4})(?=.*?(.{4}))/g;

export const formatInputValue = (text: string | undefined): string => {
  if (!text) return '';
  if (text.length % 4 === 0) {
    return text.replace(allGroupButLastRegex, '$& ').toUpperCase();
  }
  return text.replace(fourCharactersRegex, '$& ').toUpperCase();
};

export const FourCharacterSpacedInput = (props: InputProps): JSX.Element => {
  const onChangeText = (text: string) => {
    props.onChangeText?.(
      text
        .replace(/[^\da-zA-Z]/, '')
        .replace(/[\s]/g, '')
        .toUpperCase(),
    );
  };

  return (
    <Input
      {...props}
      onChangeText={onChangeText}
      value={formatInputValue(props.value?.replace(/[^\da-zA-Z]/, ''))}
    />
  );
};
