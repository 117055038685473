import styled from '@emotion/native';
import { Platform, StyleSheet, StatusBar, Dimensions } from 'react-native';
import {
  colorUsage,
  SpacingNumbers,
  styles,
  BorderRadiusNumbers,
} from '../../stylesheet';

export const StyledSafeAreaView = styled.SafeAreaView`
  flex: 1;
`;

export const StyledImage = styled.Image({
  marginTop: Dimensions.get('window').height > 600 ? '10%' : 0,
});

export const IconContainer = styled.View({
  paddingLeft: SpacingNumbers.s,
});

const Drawerstyles = StyleSheet.create({
  MenuTextLabel: {
    width: 300,
    left: -SpacingNumbers.m,
    margin: SpacingNumbers.s,
  },
  MenuItems: {
    borderRadius: BorderRadiusNumbers.m,
    margin: 0,
  },
  MenuContent: {
    alignItems: 'flex-start',
  },
  MenuDrawer: {
    backgroundColor: colorUsage.menuBackground,
    paddingLeft: SpacingNumbers.l,
    paddingRight: SpacingNumbers.l,
    width: 322,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  MenuScrollView: {
    padding: 0,
    margin: 0,
    lineHeight: 16,
  },
});

export const screenOptionsStyles = {
  drawerStyle: Drawerstyles.MenuDrawer,
  drawerLabelStyle: [styles.interractiveCTAText, Drawerstyles.MenuTextLabel],
  drawerItemStyle: Drawerstyles.MenuItems,
  drawerContentStyle: Drawerstyles.MenuContent,
  drawerContentContainerStyle: Drawerstyles.MenuScrollView,
  drawerActiveTintColor: colorUsage.menuActiveTextColor,
  drawerInactiveTintColor: colorUsage.menuDefaultTextColor,
  drawerActiveBackgroundColor: colorUsage.menuActiveBackgroundColor,
  overlayColor: '0',
};
