import React, { useState } from 'react';
import { StyleProp, TextInputProps, ViewStyle } from 'react-native';
import {
  InputContainer,
  InputView,
  Label,
  StyledInput,
  RequiredLabel,
} from './Input.style';

export type InputProps = {
  label: string;
  style?: StyleProp<ViewStyle>;
  Icon?: React.ReactNode;
  hasError?: boolean;
  isRequired?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
} & Omit<TextInputProps, 'style' | 'onFocus' | 'onEndEditing'>;

const Input = ({
  label,
  style,
  Icon,
  hasError,
  isRequired,
  onBlur,
  onFocus,
  ...props
}: InputProps): JSX.Element => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <InputView>
      <Label>
        {label}
        {isRequired && <RequiredLabel> (obligatoire)</RequiredLabel>}
      </Label>
      <InputContainer style={style} isFocus={isFocus} hasError={hasError}>
        {Icon}
        <StyledInput
          onFocus={() => {
            setIsFocus(true);
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            setIsFocus(false);
            if (onBlur) onBlur();
          }}
          {...props}
        ></StyledInput>
      </InputContainer>
    </InputView>
  );
};

export default Input;
