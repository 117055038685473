import { LinkingOptions } from '@react-navigation/native';

import { Route } from './types';

import { routes } from './routes';
import { createURL } from 'expo-linking';

export const linking: LinkingOptions<Route> = {
  prefixes: [createURL('/')],
  config: {
    screens: {
      ...routes.reduce(
        (route, currentRoute) => ({
          ...route,
          [currentRoute.name]: currentRoute.pathConfig || currentRoute.path,
        }),
        {},
      ),
    },
  },
};
