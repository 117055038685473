import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { colorUsage } from '../../../stylesheet';
import {
  InputView,
  HourInputsContainer,
  StartHourInput,
  EndHourInput,
  ResetContainer,
  TimeSeparator,
  HozizontalView,
  ScheduleInputErrorBlock,
} from './ScheduleInput.style';

export type ScheduleInputType = {
  startHour: string;
  endHour: string;
};

interface InputProps {
  style?: StyleProp<ViewStyle>;
  schedule: ScheduleInputType;
  onChangeSchedule: (schedule: ScheduleInputType) => void;
  hasError: boolean;
}

const ScheduleInput = ({
  schedule,
  onChangeSchedule,
  hasError,
}: InputProps): JSX.Element => {
  const onPressReset = () => {
    onChangeSchedule({
      startHour: '0000',
      endHour: '0000',
    });
  };

  return (
    <InputView>
      <HourInputsContainer>
        <HozizontalView>
          <StartHourInput
            time={schedule.startHour}
            onChangeTime={(time: string) =>
              onChangeSchedule({ startHour: time, endHour: schedule.endHour })
            }
          />
          <TimeSeparator>-</TimeSeparator>
          <EndHourInput
            time={schedule.endHour}
            onChangeTime={(time: string) =>
              onChangeSchedule({ startHour: schedule.startHour, endHour: time })
            }
          />
        </HozizontalView>
        <ResetContainer
          onPress={onPressReset}
          testID="ResetContainer_ScheduleInput"
        >
          <AntDesign
            name="close"
            size={24}
            color={colorUsage.hourDeclarationReset}
          />
        </ResetContainer>
      </HourInputsContainer>
      {hasError && <ScheduleInputErrorBlock error="Vérifiez votre saisie" />}
    </InputView>
  );
};

export default ScheduleInput;
