import React from 'react';
import { NumberInputBlock, NumberInputText } from './NumberBlockInput.style';

interface Props {
  value?: string;
  isFocus: boolean;
  hasError: boolean;
}

const NumberBlockInput = ({ value, isFocus, hasError }: Props): JSX.Element => {
  return (
    <NumberInputBlock isFocus={isFocus} hasError={hasError}>
      <NumberInputText isCompleted={value !== undefined} hasError={hasError}>
        {value ? value : 0}
      </NumberInputText>
    </NumberInputBlock>
  );
};

export default NumberBlockInput;
