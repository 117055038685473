import React from 'react';
import { ActivityIndicator } from 'react-native';
import { LoaderContainer } from './Loader.style';

const Loader = (): JSX.Element => {
  return (
    <LoaderContainer>
      <ActivityIndicator size="large" />
    </LoaderContainer>
  );
};

export default Loader;
