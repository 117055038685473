import React, { useEffect } from 'react';
import { useFetchMeAsCareGiver } from '../../redux/users/hook';
import { useSelector } from 'react-redux';
import { selectMeAsCareGiver } from '../../redux/users';
import CandidateIdentityForm from './components/CandidateIdentityForm/CandidateIdentityForm';
import { View } from 'react-native';

export default function CandidateIdentity(): JSX.Element {
  const [, doFetchCareGiver] = useFetchMeAsCareGiver();

  useEffect(() => {
    doFetchCareGiver();
  }, [doFetchCareGiver]);

  const careGiver = useSelector(selectMeAsCareGiver);

  return careGiver ? (
    <CandidateIdentityForm careGiver={careGiver} />
  ) : (
    <View></View>
  );
}
