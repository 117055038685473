import { authenticatedApiClient } from '../../services/networking/authenticatedApiClient';
import { HourDeclaration, HourDeclarationDetails } from './types';

export async function fetchHourDeclaration(): Promise<HourDeclaration[]> {
  const url = `/hourDeclaration`;
  const { data } = await authenticatedApiClient.get<HourDeclaration[]>(url);
  return data;
}

export async function postHourDeclarationDetails(
  id: string,
  hourDeclarationDetails: Omit<HourDeclarationDetails, 'appointmentDate'>[],
): Promise<void> {
  const url = `/hourDeclaration/${id}/confirm`;
  await authenticatedApiClient.post<HourDeclaration>(
    url,
    hourDeclarationDetails,
  );
}

export async function postValidateHourDeclaration(
  id: string,
  cesuAmount?: number,
): Promise<void> {
  const url = `/hourDeclaration/${id}/validate`;
  await authenticatedApiClient.post<HourDeclaration>(url, {
    cesuAmount,
  });
}
