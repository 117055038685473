import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentInfos } from './types';

export type PaymentInfosState = Readonly<{
  paymentInfos: PaymentInfos | undefined;
}>;

const initialState: PaymentInfosState = {
  paymentInfos: undefined,
};

const paymentInfosSlice = createSlice({
  name: 'PaymentInfos',
  initialState,
  reducers: {
    updatePaymentInfos: (
      state,
      action: PayloadAction<PaymentInfos | undefined>,
    ) => {
      state.paymentInfos = action.payload;
    },
  },
});

export const { updatePaymentInfos } = paymentInfosSlice.actions;
export default paymentInfosSlice.reducer;
