import React from 'react';
import { Linking } from 'react-native';
import { useDispatch } from 'react-redux';
import { logout as logoutAction } from '../../redux/auth/slice';

import { useAuth } from '../../services/auth/context';
import { CGU_LINK } from '../__Utils__/const/CGUs.link';
import {
  ButtonContainer,
  Container,
  ImageContainer,
  StyledButton,
  StyledImage,
} from './SettingsScreen.style';

export default function SettingsScreen(): JSX.Element {
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const onLogout = () => {
    logout();
    dispatch(logoutAction());
  };

  const onCGUPress = () => {
    Linking.openURL(CGU_LINK);
  };

  return (
    <Container>
      <ImageContainer>
        <StyledImage
          source={require('../../assets/images/logoutPicture.jpg')}
        />
      </ImageContainer>
      <ButtonContainer>
        <StyledButton title="Voir les CGUs" onPress={onCGUPress} light />
        <StyledButton title="Déconnexion" onPress={onLogout} />
      </ButtonContainer>
    </Container>
  );
}
