import styled from '@emotion/native';
import {
  colorUsage,
  SpacingNumbers,
  textP2Regular,
  titleH2,
} from '../../../stylesheet';

export const TitleChapeauContainer = styled.View({
  marginVertical: SpacingNumbers.m,
});
export const Title = styled(titleH2)({
  width: '100%',
  color: colorUsage.stepperScreenTitleColor,
});

export const Container = styled.ScrollView({
  flex: 1,
  width: '100%',
});

export const Chapeau = styled(textP2Regular)({
  marginTop: SpacingNumbers.s,
  color: colorUsage.stepperScreenChapeauTextColor,
});
