import styled from '@emotion/native';
import {
  colorUsage,
  SpacingNumbers,
  textP2Bold,
  textP2Regular,
} from '../../../../stylesheet';

export const IndicatorContainer = styled.View({
  width: '100%',
  flexDirection: 'row',
  marginBottom: SpacingNumbers.s,
});

export const Indicator = styled.View<{ color: string }>(props => ({
  backgroundColor: props.color,
  height: SpacingNumbers.xs,
  borderRadius: 4,
  width: '15%',
  marginRight: SpacingNumbers.s,
}));

export const SuggestionContainer = styled.View({
  width: '100%',
});

export const WarningContainer = styled.View({
  flexDirection: 'row',
});

export const WarningLevel = styled(textP2Bold)<{ color: string }>(props => ({
  color: props.color,
}));

export const WarningText = styled(textP2Bold)({
  color: colorUsage.bodyText,
});

export const SuggestionText = styled(textP2Regular)({
  color: colorUsage.bodyText,
});
