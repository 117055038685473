import styled from '@emotion/native';
import {
  colorUsage,
  SpacingNumbers,
  textP1Regular,
  titleH3,
} from '../../stylesheet';

export const Container = styled.View({
  paddingHorizontal: SpacingNumbers.m,
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: colorUsage.contentBackground,
});

export const AnnounceContainer = styled.View({
  height: '100%',
  width: '100%',
});

export const NoAnnounceText = styled(textP1Regular)({
  marginTop: SpacingNumbers.xl,
  marginBottom: SpacingNumbers.XXL,
  paddingBottom: SpacingNumbers.XXL,
  width: '100%',
  color: colorUsage.noCriteriaTextColor,
});

export const AnnounceText = styled(titleH3)({
  marginTop: SpacingNumbers.xl,
  marginBottom: SpacingNumbers.l,
  width: '100%',
});

export const StyledImage = styled.Image({
  marginTop: SpacingNumbers.xl,
  width: 208,
  height: 208,
});
