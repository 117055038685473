import React from 'react';
import {
  CancelButton,
  ConfirmButton,
  ContentContainer,
  ContentText,
} from './ConfirmationModalContent.style';

type Props = {
  onPressConfirm: () => void;
  onPressCancel: () => void;
};

const ConfirmationModalContent = ({
  onPressConfirm,
  onPressCancel,
}: Props): JSX.Element => {
  return (
    <ContentContainer>
      <ContentText>
        Cette déclaration donnera lieu au déclenchement de votre rémunération et
        ne pourra plus être rectifiée une fois validée.
      </ContentText>

      <ConfirmButton
        title="Je confirme ma déclaration"
        onPress={onPressConfirm}
      />
      <CancelButton title="Annuler" onPress={onPressCancel} light />
    </ContentContainer>
  );
};

export default ConfirmationModalContent;
