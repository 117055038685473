import styled from '@emotion/native';
import {
  SpacingNumbers,
  textP2Bold,
  colorUsage,
  textP2Regular,
  interactiveLink,
} from '../../stylesheet';

export const EmailText = styled(textP2Bold)({
  color: colorUsage.bodyText,
});

export const AuthentificationText = styled(textP2Regular)({
  color: colorUsage.bodyText,
});

export const TextContainer = styled.Text({
  width: '100%',
});

export const WrongEmailContainer = styled.TouchableOpacity({
  width: '100%',
  marginTop: SpacingNumbers.xs,
  marginBottom: SpacingNumbers.xl,
});

export const TextWrongEmail = styled(interactiveLink)({});

export const MainContainer = styled.View({
  flex: 1,
  alignItems: 'center',
  backgroundColor: colorUsage.contentBackground,
  paddingHorizontal: SpacingNumbers.m,
});
