import styled from '@emotion/native';
import { colorUsage, SpacingNumbers, textP2Bold } from '../../../../stylesheet';

export const AvailabilityTitle = styled(textP2Bold)({
  color: colorUsage.availabilityTextColor,
  marginTop: SpacingNumbers.m,
  marginBottom: SpacingNumbers.s,
});

export const BodyContainer = styled.View({
  paddingBottom: SpacingNumbers.s,
  borderBottomWidth: 1,
  borderBottomColor: colorUsage.separatorLineColor,
});

export const DayText = styled(textP2Bold)({
  width: SpacingNumbers.xl,
  color: colorUsage.availabilityDayColor,
});

export const ScheduleContainer = styled.View({
  flexDirection: 'row',
  marginBottom: SpacingNumbers.s,
});

export const ScheduleText = styled.Text({
  paddingHorizontal: SpacingNumbers.s,
  color: colorUsage.availabilityHoursColor,
});

export const AvailabilityContainer = styled.View({
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const DayScheduleContainer = styled.View({ width: 115 });
