import React from 'react';
import { Image, StatusBar } from 'react-native';
import { useNavigation } from '../../navigation/types';
import {
  AccountCreationButton,
  ButtonContainer,
  Container,
  IconAndTextContainer,
  ImageContainer,
  LoginButton,
  WelcomeText,
} from './HomeScreen.style';

export default function HomeScreen(): JSX.Element {
  const { navigate } = useNavigation();

  const onLoginPress = () => {
    navigate('Login');
  };

  const onCreateAccountPress = () => {
    navigate('AccountCreation');
  };

  return (
    <Container>
      <StatusBar barStyle="light-content" />
      <ImageContainer>
        <Image
          source={require('../../assets/images/login.png')}
          style={{ flex: 1, flexGrow: 1, width: '100%' }}
        />
        <IconAndTextContainer>
          <Image
            source={require('../../assets/images/Logo.png')}
            style={{ width: 256, height: 80 }}
          />
          <WelcomeText>
            Bienvenue chez Click&amp;Care, 1er réseau national de professionnels
            soignants et médico-sociaux.
          </WelcomeText>
        </IconAndTextContainer>
      </ImageContainer>
      <ButtonContainer>
        <LoginButton title="Me connecter" onPress={onLoginPress}></LoginButton>
        <AccountCreationButton
          title="Créer un compte"
          light
          onPress={onCreateAccountPress}
        ></AccountCreationButton>
      </ButtonContainer>
    </Container>
  );
}
