import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Application,
  FamilyAnnounce,
  FamilyAnnounceWithDetails,
  InstitutionAnnounce,
  InstitutionAnnounceWithDetails,
} from './types';

export type AnnounceState = Readonly<{
  announces: (FamilyAnnounce | InstitutionAnnounce)[];
  announceWithDetails:
    | FamilyAnnounceWithDetails
    | InstitutionAnnounceWithDetails
    | undefined;
  applications: Application[];
  proposedAnnounces: (FamilyAnnounce | InstitutionAnnounce)[];
}>;

const initialState: AnnounceState = {
  announces: [],
  announceWithDetails: undefined,
  applications: [],
  proposedAnnounces: [],
};

const announceSlice = createSlice({
  name: 'Announce',
  initialState,
  reducers: {
    updateAnnounces: (
      state,
      action: PayloadAction<(FamilyAnnounce | InstitutionAnnounce)[]>,
    ) => {
      state.announces = action.payload;
    },
    updateAnnounceWithDetails: (
      state,
      action: PayloadAction<
        FamilyAnnounceWithDetails | InstitutionAnnounceWithDetails
      >,
    ) => {
      state.announceWithDetails = action.payload;
    },
    updateApplications: (state, action: PayloadAction<Application[]>) => {
      state.applications = action.payload;
    },
    updateProposedAnnounces: (
      state,
      action: PayloadAction<(FamilyAnnounce | InstitutionAnnounce)[]>,
    ) => {
      state.proposedAnnounces = action.payload;
    },
  },
});

export const {
  updateAnnounces,
  updateAnnounceWithDetails,
  updateApplications,
  updateProposedAnnounces,
} = announceSlice.actions;
export default announceSlice.reducer;
