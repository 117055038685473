import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import React from 'react';

import { StyledImage, StyledSafeAreaView } from './DrawerNavigator.style';

const CustomDrawerContent = (
  props: DrawerContentComponentProps,
): JSX.Element => {
  return (
    <StyledSafeAreaView>
      <StyledImage
        source={require('../../assets/images/Logo.png')}
        style={{ width: 256, height: 80 }}
      />
      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </StyledSafeAreaView>
  );
};

export default CustomDrawerContent;
