import { RouteProp } from '@react-navigation/native';
import { isBefore } from 'date-fns';
import { AuthenticatedStackParamList } from '../../navigation/types';
import { HourDeclaration } from '../../redux/hourDeclaration/types';
import DefaultValidateHourModalContent from './components/DefaultAndLiberalValidateHourModalContent/DefaultAndLiberalValidateHourModalContent';
import PaymentValidateHourModalContent from './components/PaymentValidateHourModalContent/PaymentValidateHourModalContent';

export type Props = {
  route: RouteProp<AuthenticatedStackParamList, 'HourDeclarationDetailScreen'>;
};

export type ModalType = 'default' | 'payment' | 'liberal';

export type ModalContentProps = {
  onPressValidate: (cesuAmount?: number) => void;
};

export const getModalType = (hourDeclaration: HourDeclaration): ModalType => {
  if (hourDeclaration.mission.contractType === 'liberal') return 'liberal';
  const isMonthlyPayment =
    hourDeclaration.mission.contractType === 'cdi' ||
    hourDeclaration.mission.contractType === 'cdd';

  const isMissionEnding =
    !!hourDeclaration.mission.endDate &&
    isBefore(
      new Date(hourDeclaration.mission.endDate),
      new Date(hourDeclaration.endDate),
    );
  if (isMonthlyPayment && (hourDeclaration.isLastOfTheMonth || isMissionEnding))
    return 'payment';
  return 'default';
};

export const getModalContent = (
  modalType: ModalType,
): (({ onPressValidate }: ModalContentProps) => JSX.Element) => {
  switch (modalType) {
    case 'liberal':
      return ({ onPressValidate }: ModalContentProps) =>
        DefaultValidateHourModalContent({
          type: 'LIBERAL',
          onPressValidate: () => onPressValidate(),
        });
    case 'payment':
      return PaymentValidateHourModalContent;
    default:
      return ({ onPressValidate }: ModalContentProps) =>
        DefaultValidateHourModalContent({
          type: 'DEFAULT',
          onPressValidate: () => onPressValidate(),
        });
  }
};

export const getModalTitle = (modalType: ModalType): string => {
  switch (modalType) {
    case 'liberal':
      return 'Paiement de votre intervenant.';
    case 'payment':
      return 'En validant les heures, vous vous engagez à rémunérer votre intervenant.';
    default:
      return 'En validant les heures, vous vous engagez à rémunérer votre intervenant à la fin du mois.';
  }
};
