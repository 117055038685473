import React from 'react';
import { Container } from './CandidateProfile.style';
import { ProfileBlock } from './components/ProfilBlock/ProfileBlock';
import { useNavigation } from '../../navigation/types';

export default function CandidateProfile(): JSX.Element {
  const { navigate } = useNavigation();
  const onMyIdentityBegin = () => {
    navigate('CandidateIdentity');
  };

  const onMyStatusBegin = () => {
    navigate('CandidateStatus');
  };

  return (
    <Container>
      <ProfileBlock
        onBegin={onMyIdentityBegin}
        title="📃 Mon identité"
        description="Nom, prénom, pièce d’identité, adresse..."
      />

      <ProfileBlock
        onBegin={onMyStatusBegin}
        title="📝 Mes statuts"
        description="Numéro CESU, SIRET, numéro SAP."
        warning={{
          title: 'Attention : ',
          content:
            'Ces informations sont nécessaires pour déclencher votre paiement.',
        }}
      />
    </Container>
  );
}
