import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  colorUsage,
  SpacingNumbers,
  textP2Bold,
  textP2Regular,
} from '../../../../stylesheet';

export const InfoBlockContainer = styled.View({
  width: '100%',
  borderRadius: BorderRadiusNumbers.m,
  paddingHorizontal: SpacingNumbers.m,
  paddingVertical: SpacingNumbers.s,
  backgroundColor: colorUsage.infoBlockBackground,
  marginBottom: SpacingNumbers.m,
});

export const InfoBlockBoldText = styled(textP2Bold)({
  color: colorUsage.infoBlockText,
});

export const InfoBlockText = styled(textP2Regular)({
  color: colorUsage.infoBlockText,
});
