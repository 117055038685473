import styled from '@emotion/native';
import { TouchableOpacity } from 'react-native';
import Underline from '../../atoms/Underline/Underline';
import {
  BorderRadiusNumbers,
  BoxShadow,
  colorUsage,
  interactiveLink,
  SpacingNumbers,
  textP1Bold,
  textP2Bold,
  textP2Regular,
} from '../../../stylesheet';

export const HourDeclarationCardTitle = styled(textP1Bold)({
  textAlign: 'left',
  color: colorUsage.hourDeclarationCardTitle,
  marginBottom: SpacingNumbers.s,
});
export const HourDeclarationCardContainer = styled.View({
  padding: SpacingNumbers.m,
  paddingRight: SpacingNumbers.xl,
  marginBottom: SpacingNumbers.m,
  flexDirection: 'row',
  backgroundColor: colorUsage.hourDeclarationCardBackground,
  borderRadius: BorderRadiusNumbers.s,
  ...BoxShadow.shadowLight,
  shadowOpacity: BoxShadow.shadowLightOpacity,
  elevation: BoxShadow.shadowLightElevation,
});
export const HourDeclarationSubtitle = styled(textP2Regular)({
  color: colorUsage.hourDeclarationSubtitle,
  marginBottom: SpacingNumbers.s,
  marginLeft: SpacingNumbers.s,
});

export const HourDeclarationInfoContainer = styled.View({
  paddingLeft: SpacingNumbers.m,
  marginRight: SpacingNumbers.xl,
});
export const HourDeclarationSubtitleContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'baseline',
});
export const PhotoCircle = styled.View({
  backgroundColor: colorUsage.hourDeclarationPhotoCircle,
  width: 40,
  height: 40,
  borderRadius: 40,
  alignItems: 'center',
  justifyContent: 'center',
});

export const Initials = styled(textP2Bold)({
  color: colorUsage.hourDeclarationPhotoCircleText,
});

export const Capitalize = styled.Text({
  textTransform: 'capitalize',
});

export const SeeDetailsContainer = styled(TouchableOpacity)({
  flexDirection: 'row',
  alignItems: 'center',
});

export const SeeDetailsText = styled(interactiveLink)({
  textDecorationLine: 'none',
});

export const UnderlineDetails = styled(Underline)({
  marginRight: SpacingNumbers.s,
});
