import styled from '@emotion/native';

export const LoaderContainer = styled.View({
  position: 'absolute',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  opacity: 0.5,
  backgroundColor: '#000',
});
