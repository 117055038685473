import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage } from '../../../../stylesheet';
import {
  ButtonContainer,
  Container,
  Description,
  Title,
  Warning,
  WarningTitle,
  WarningContent,
} from './ProfileBlock.style';

interface Props {
  title: string;
  description: string;
  onBegin: () => void;
  warning?: { title?: string; content?: string };
}

export const ProfileBlock = ({
  title,
  description,
  onBegin,
  warning,
}: Props): JSX.Element => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {warning && (
        <Warning>
          <WarningContent>
            <WarningTitle>{warning?.title}</WarningTitle>
            {warning?.content}
          </WarningContent>
        </Warning>
      )}
      <ButtonContainer>
        <PrimaryButton
          title="Commencer"
          light
          onPress={onBegin}
          RightIcon={
            <AntDesign name="arrowright" size={24} color={colorUsage.link} />
          }
        />
      </ButtonContainer>
    </Container>
  );
};
