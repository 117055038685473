import { createSlice } from '@reduxjs/toolkit';

const initialState = undefined;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: /* istanbul ignore next */ () => {},
  },
});

export const { logout } = authSlice.actions;
