import { TextInput } from 'react-native';
import styled from '@emotion/native';
import {
  SpacingNumbers,
  textP2Regular,
  colorUsage,
  textP1RegularStyleSheet,
  BorderRadiusNumbers,
} from '../../../stylesheet';

export const InputView = styled.View`
  width: 100%;
`;

export const StyledInput = styled(TextInput)({
  color: colorUsage.labelText,
  ...textP1RegularStyleSheet,
  height: 43,
  width: '100%',
  paddingHorizontal: SpacingNumbers.s,
});

export const InputContainer = styled.View<{
  isFocus?: boolean;
  hasError?: boolean;
}>(({ isFocus, hasError }) => ({
  flexDirection: 'row',
  backgroundColor: colorUsage.inputBackgroundColor,
  borderRadius: BorderRadiusNumbers.s,
  alignItems: 'center',
  width: '100%',
  paddingHorizontal: SpacingNumbers.m,
  borderWidth: isFocus || hasError ? 1 : 0,
  borderColor: hasError
    ? colorUsage.hasErrorInputBorderColor
    : colorUsage.inputBorderColor,
}));

export const Label = styled(textP2Regular)({
  color: colorUsage.labelText,
  marginBottom: SpacingNumbers.xs,
});

export const RequiredLabel = styled(Label)({
  color: colorUsage.inputRequiredTextColor,
});
