import React, { useEffect, useState } from 'react';
import StepperScreen from '../../components/organism/StepperScreen/StepperScreen';
import { CameraButton } from './components/CameraButton/CameraButton';
import { Container } from './IdentityDocument.style';
import { CameraCapturedPicture } from 'expo-camera';
import {
  useDownloadCareGiverDocument,
  useFetchCareGiverDocuments,
  useUpdateCareGiverDocument,
} from '../../redux/users/hook';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { CAREGIVER_DOCUMENT_TYPE } from '../../redux/users/types';
import { useSelector } from 'react-redux';
import {
  selectCareGiverDocuments,
  selectMeAsCareGiver,
} from '../../redux/users';
import { useNavigation } from '@react-navigation/native';
import Loader from '../../components/atoms/Loader/Loader';

// istanbul ignore file
export default function IdentityDocuments(): JSX.Element {
  const [, doFetchCareGiverDocuments] = useFetchCareGiverDocuments();
  const [, doUpdateCareGiverDocument] = useUpdateCareGiverDocument();
  const [, doDownloadCareGiverDocument] = useDownloadCareGiverDocument();
  const [isLoading, setIsLoading] = useState(false);
  const { goBack } = useNavigation();

  const careGiver = useSelector(selectMeAsCareGiver);
  const existingDocuments = useSelector(selectCareGiverDocuments);

  useEffect(() => {
    doFetchCareGiverDocuments();
  }, [doFetchCareGiverDocuments]);

  const [
    photoCNIRecto,
    setPhotoCNIRecto,
  ] = useState<CameraCapturedPicture | null>(null);

  const onCNIRectoChange = (photo: CameraCapturedPicture) => {
    setPhotoCNIRecto(photo);
  };

  const [
    photoCNIVerso,
    setPhotoCNIVerso,
  ] = useState<CameraCapturedPicture | null>(null);

  const onCNIVersoChange = (photo: CameraCapturedPicture) => {
    setPhotoCNIVerso(photo);
  };

  const isEditMode =
    existingDocuments.cniRectoId && existingDocuments.cniVersoId;
  const isButtonEnabled = isEditMode
    ? photoCNIRecto || photoCNIVerso
    : photoCNIRecto && photoCNIVerso;

  const handleSubmit = async () => {
    setIsLoading(true);

    if (photoCNIRecto) {
      await doUpdateCareGiverDocument({
        document: {
          name: `CNIRecto-${careGiver?.person?.firstName}-${careGiver?.person?.lastName}`,
          contentType: 'image/jpeg',
          extension: 'jpeg',
          type: CAREGIVER_DOCUMENT_TYPE.CNI_RECTO,
        },
        photo: photoCNIRecto as CameraCapturedPicture,
      });
    }

    if (photoCNIVerso) {
      await doUpdateCareGiverDocument({
        document: {
          name: `CNIVerso-${careGiver?.person?.firstName}-${careGiver?.person?.lastName}`,
          contentType: 'image/jpeg',
          extension: 'jpeg',
          type: CAREGIVER_DOCUMENT_TYPE.CNI_VERSO,
        },
        photo: photoCNIVerso as CameraCapturedPicture,
      });
    }

    setIsLoading(false);
    goBack();
    goBack();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container>
        <StepperScreen title={`2/2 - \nAjoutez vos justificatifs`}>
          <CameraButton
            title="Pièce d’identité"
            details="recto"
            isRequired
            description="Ou carte de séjour recto, passeport première page."
            photo={photoCNIRecto}
            onPhotoChange={onCNIRectoChange}
            isPhoto={!!existingDocuments.cniRectoId}
            onDownloadPress={() => {
              doDownloadCareGiverDocument({
                documentType: CAREGIVER_DOCUMENT_TYPE.CNI_RECTO,
              });
            }}
          />
          <CameraButton
            title="Pièce d’identité"
            details="verso"
            isRequired
            description="Ou carte de séjour verso, passeport première page."
            photo={photoCNIVerso}
            onPhotoChange={onCNIVersoChange}
            isPhoto={!!existingDocuments.cniVersoId}
            onDownloadPress={() => {
              doDownloadCareGiverDocument({
                documentType: CAREGIVER_DOCUMENT_TYPE.CNI_VERSO,
              });
            }}
          />
          <PrimaryButton
            title="Enregistrer"
            onPress={handleSubmit}
            disabled={!isButtonEnabled}
          />
        </StepperScreen>
      </Container>
    </>
  );
}
