import styled from '@emotion/native';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import {
  colorUsage,
  SpacingNumbers,
  textP2Bold,
  textP2Regular,
} from '../../stylesheet';

export const DateTitle = styled(textP2Bold)({
  marginVertical: SpacingNumbers.s,
  textTransform: 'capitalize',
  color: colorUsage.hourDeclarationDetailsDay,
});

export const ScheduleText = styled(textP2Regular)({
  marginBottom: SpacingNumbers.s,
  color: colorUsage.hourDeclarationDetailsValidatedText,
});

export const ScreenContainer = styled.View({
  paddingHorizontal: SpacingNumbers.m,
  backgroundColor: colorUsage.contentBackground,
  height: '100%',
});

export const StyledButton = styled(PrimaryButton)({
  marginBottom: SpacingNumbers.l,
});

export const ModalContentContainer = styled.View({
  paddingHorizontal: SpacingNumbers.l,
  paddingBottom: SpacingNumbers.xl,
});

export const ModalTextContent = styled(textP2Regular)({
  marginBottom: SpacingNumbers.xl,
  color: colorUsage.paymentModalText,
  textAlign: 'center',
});
