import { minutesToHours } from 'date-fns';
import { ScheduleInputType } from '../../components/molecules/ScheduleInput/ScheduleInput';
import { HourDeclarationDetails } from '../../redux/hourDeclaration/types';
import { getTotalWorkedMinutes } from '../__Utils__/hourDeclarationProcessing';
import { formatDateWithDayInWeek } from '../__Utils__/textFormatting';

export const getAppointmentDateText = (
  appointmentDate: string,
  details: HourDeclarationDetails[],
): string => {
  const totalWorkedTime = minutesToHours(getTotalWorkedMinutes(details));
  const timeText = totalWorkedTime ? `(${totalWorkedTime}h)` : '';

  return `${formatDateWithDayInWeek(appointmentDate)} ${timeText}`;
};

export const getHourFromNumbers = (hours: string): string => {
  return `${hours.substring(0, 2)}:${hours.substring(2, 4)}:00`;
};

export const getDetailFromFilledHourDetailsState = (
  filledHourDetails: Record<
    string,
    ScheduleInputType & { appointmentDate: string }
  >,
): HourDeclarationDetails[] =>
  Object.keys(filledHourDetails).map(id => ({
    id: id,
    appointmentDate: filledHourDetails[id].appointmentDate,
    startHour: getHourFromNumbers(filledHourDetails[id].startHour),
    endHour: getHourFromNumbers(filledHourDetails[id].endHour),
  }));
