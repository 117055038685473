import React, { FC } from 'react';
import {
  AddressSuggestionContainer,
  AddressSuggestionText,
} from './AddressSuggestion.style';

export interface AddressSuggestionProps {
  address: string;
  city: string;
  postCode: string;
  onPress: () => void;
}

const AddressSuggestion: FC<AddressSuggestionProps> = ({
  address,
  city,
  postCode,
  onPress,
}) => (
  <AddressSuggestionContainer
    onPress={onPress}
    testID="AddressSuggestionContainer"
  >
    <AddressSuggestionText>
      {address}, {city} - {postCode}
    </AddressSuggestionText>
  </AddressSuggestionContainer>
);

export default AddressSuggestion;
