import styled from '@emotion/native';
import { SpacingNumbers, textP2Regular, colorUsage } from '../../../stylesheet';
import ChoiceButton from '../../atoms/ChoiceButton/ChoiceButton';

export const InputView = styled.View({
  width: '100%',
});

export const InputContainer = styled.View({
  flexDirection: 'row',
  flexBasis: '20%',
  marginHorizontal: -SpacingNumbers.s,
});

export const Label = styled(textP2Regular)({
  color: colorUsage.labelText,
  marginBottom: SpacingNumbers.xs,
});

export const RequiredLabel = styled(Label)({
  color: colorUsage.inputRequiredTextColor,
});

export const StyledChoiceButton = styled(ChoiceButton)({
  flexShrink: 1,
  paddingHorizontal: SpacingNumbers.s,
});
