import styled from '@emotion/native';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { SpacingNumbers } from '../../stylesheet';

export const Container = styled.View({ flex: 1 });

export const ImageContainer = styled.View({
  height: '75%',
});

export const ButtonContainer = styled.View({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  paddingHorizontal: SpacingNumbers.xxl,
  flex: 1,
});

export const StyledImage = styled.Image({
  flex: 1,
  flexGrow: 1,
  width: '100%',
});

export const StyledButton = styled(PrimaryButton)({
  marginBottom: SpacingNumbers.m,
});
