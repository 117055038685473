import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { gradientColors } from '../../../stylesheet';

export type Color =
  | 'blue'
  | 'red'
  | 'grey'
  | 'greyDark'
  | 'white'
  | 'blueFlat'
  | 'pink'
  | 'lightBlueFlat'
  | 'greyPale';

type Props = {
  color: Color;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

const LinearGradient = ({ color, children, style }: Props): JSX.Element => {
  return (
    <ExpoLinearGradient
      style={style}
      colors={gradientColors[color]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
    >
      {children}
    </ExpoLinearGradient>
  );
};

export default LinearGradient;
