import styled from '@emotion/native';
import {
  colorUsage,
  SpacingNumbers,
  textP2Italic,
} from '../../../../stylesheet';

export const InputContainer = styled.TouchableOpacity({
  flexDirection: 'row',
  marginBottom: SpacingNumbers.xs,
  justifyContent: 'center',
});

export const TextError = styled(textP2Italic)({
  color: colorUsage.numberBlockError,
  marginLeft: SpacingNumbers.xs,
});

export const ErrorContainer = styled.View({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
});
