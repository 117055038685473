import React from 'react';
import { ListRenderItemInfo, SectionList } from 'react-native';
import { HourDeclaration } from '../../../redux/hourDeclaration/types';
import { SpacingNumbers } from '../../../stylesheet';
import {
  HourDeclarationScreenContainer,
  HourDeclarationTitle,
  NoSearchResultContainer,
  NoSearchResultText,
  StyledImage,
} from './HourDeclarationTemplate.style';

interface Props {
  toValidateDeclarations: HourDeclaration[];
  validatedAndSortedDeclarations: HourDeclaration[][];
  renderValidatedDeclaration: (
    item: ListRenderItemInfo<HourDeclaration | HourDeclaration[]>,
  ) => JSX.Element;
  renderToValidateDeclaration: (
    item: ListRenderItemInfo<HourDeclaration | HourDeclaration[]>,
  ) => JSX.Element;
}

export default function HourDeclarationTemplate({
  toValidateDeclarations,
  validatedAndSortedDeclarations,
  renderToValidateDeclaration,
  renderValidatedDeclaration,
}: Props): JSX.Element {
  // Typescript error if item is not well declared in renderItem and keyExtractor
  const sections = [];

  if (toValidateDeclarations.length > 0) {
    sections.push({
      title: 'En attente de validation',
      data: toValidateDeclarations,
      renderItem: renderToValidateDeclaration,
      keyExtractor: (
        item: HourDeclaration | HourDeclaration[],
        index: number,
      ) => (item as HourDeclaration).id + index,
    });
  }

  if (validatedAndSortedDeclarations.length > 0) {
    sections.push({
      title: 'Historique',
      data: validatedAndSortedDeclarations,
      renderItem: renderValidatedDeclaration,
      keyExtractor: (
        item: HourDeclaration | HourDeclaration[],
        index: number,
      ) => (item as HourDeclaration[])[0].id + index,
    });
  }

  return sections.length > 0 ? (
    <HourDeclarationScreenContainer>
      <SectionList<HourDeclaration | HourDeclaration[]>
        sections={sections}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingTop: SpacingNumbers.l }}
        renderSectionHeader={({ section: { title } }) => (
          <HourDeclarationTitle>{title}</HourDeclarationTitle>
        )}
        stickySectionHeadersEnabled={false}
      />
    </HourDeclarationScreenContainer>
  ) : (
    <NoSearchResultContainer>
      <StyledImage
        source={require('../../../assets/images/NoHourDeclaration.png')}
      />
      <NoSearchResultText>
        Vous n'avez pas encore d'heure à valider.
      </NoSearchResultText>
    </NoSearchResultContainer>
  );
}
