import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import FindCareGiver from '../../screens/FindCareGiver/FindCareGiver';
import PaymentsScreen from '../../screens/PaymentsScreen/PaymentsScreen';
import SettingsScreen from '../../screens/SettingsScreen/SettingsScreen';
import ValidateHoursScreen from '../../screens/ValidateHoursScreen/ValidateHoursScreen';
import { isFeatureTogglingActivated } from '../../screens/__Utils__/featureToggling';
import BasicTextHeader from '../headers/BasicTextHeader';
import { ColorProps, setOptionsHeader, setOptionsIcon } from './shared';
import {
  CareGiverDeepLinkPath,
  DrawerRoutes,
  FamilyDeepLinkPath,
  FamilyDrawerScreenTitles,
} from './types';

export const familyRoutes: DrawerRoutes[] = [
  {
    name: 'FindACaregiver',
    component: FindCareGiver,
    path: FamilyDeepLinkPath.FIND_A_CAREGIVER,
    options: {
      ...setOptionsHeader(
        <BasicTextHeader title={FamilyDrawerScreenTitles.FIND_A_CAREGIVER} />,
      ),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <MaterialIcons name="work-outline" size={24} color={color} />
      )),
      title: FamilyDrawerScreenTitles.FIND_A_CAREGIVER,
    },
  },
  ...(isFeatureTogglingActivated()
    ? [
        {
          name: 'ValidateHours',
          component: ValidateHoursScreen,
          path: FamilyDeepLinkPath.VALIDATE_HOURS,
          options: {
            ...setOptionsHeader(
              <BasicTextHeader
                title={FamilyDrawerScreenTitles.VALIDATE_HOURS}
              />,
            ),
            ...setOptionsIcon(({ color }: ColorProps) => (
              <AntDesign name="clockcircleo" size={24} color={color} />
            )),
            title: FamilyDrawerScreenTitles.VALIDATE_HOURS,
          },
        } as const,
      ]
    : []),
  ...(isFeatureTogglingActivated()
    ? [
        {
          name: 'Payments',
          component: PaymentsScreen,
          path: FamilyDeepLinkPath.PAYMENTS,
          initialParams: { type: 'FAMILY' },
          options: {
            ...setOptionsHeader(
              <BasicTextHeader title={FamilyDrawerScreenTitles.PAYMENTS} />,
            ),
            ...setOptionsIcon(({ color }: ColorProps) => (
              <MaterialIcons name="euro" size={24} color={color} />
            )),
            title: FamilyDrawerScreenTitles.PAYMENTS,
          },
        } as const,
      ]
    : []),
  {
    name: 'Settings',
    component: SettingsScreen,
    path: CareGiverDeepLinkPath.SETTINGS,
    options: {
      ...setOptionsHeader(
        <BasicTextHeader title={FamilyDrawerScreenTitles.SETTINGS} />,
      ),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <AntDesign name="setting" size={24} color={color} />
      )),
      title: FamilyDrawerScreenTitles.SETTINGS,
    },
  },
];
