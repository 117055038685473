import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AsyncFnReturn } from 'react-use/lib/useAsyncFn';
import { useTypedAsyncFn } from '../useTypedAsyncFn';
import {
  fetchHourDeclaration,
  postHourDeclarationDetails,
  postValidateHourDeclaration,
} from './api';
import { updateHourDeclarations } from './slice';
import { HourDeclaration, HourDeclarationDetails } from './types';

export const useFetchHourDeclaration = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<Dispatch<PayloadAction<HourDeclaration[]>>>();

  return useTypedAsyncFn(async () => {
    const hourDeclaration = await fetchHourDeclaration();
    dispatch(updateHourDeclarations(hourDeclaration));
  }, [dispatch]);
};

export const usePostHourDeclarationDetails = (): AsyncFnReturn<(
  ...args: {
    id: string;
    hourDeclarationDetails: Omit<HourDeclarationDetails, 'appointmentDate'>[];
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn(async ({ id, hourDeclarationDetails }) => {
    await postHourDeclarationDetails(id, hourDeclarationDetails);
  }, []);
};

export const usePostValidateHourDeclaration = (): AsyncFnReturn<(
  ...args: {
    id: string;
    cesuAmount?: number;
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn(async ({ id, cesuAmount }) => {
    await postValidateHourDeclaration(id, cesuAmount);
  }, []);
};
