import styled from '@emotion/native';
import Input from '../../components/atoms/Input/Input';
import SingleChoiceInput from '../../components/molecules/SingleChoiceInput/SingleChoiceInput';
import { colorUsage, SpacingNumbers } from '../../stylesheet';

export const Container = styled.View({
  backgroundColor: colorUsage.contentBackground,
  height: '100%',
  paddingHorizontal: SpacingNumbers.m,
  paddingTop: SpacingNumbers.s,
  paddingBottom: SpacingNumbers.xl,
});

export const FormContainer = styled.View({
  marginTop: SpacingNumbers.s,
  height: '100%',
});

export const StyledInput = styled(Input)({ marginBottom: SpacingNumbers.l });

export const StyledSingleChoiceInput = styled(SingleChoiceInput)({
  marginBottom: SpacingNumbers.l,
});
