import { AntDesign, Feather, MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import CandidateProfile from '../../screens/CandidateProfile/CandidateProfile';
import DeclareHoursScreen from '../../screens/DeclareHoursScreen/DeclareHoursScreen';
import FindMission from '../../screens/FindMission/FindMission';
import MyAppointmentsScreen from '../../screens/MyAppointmentsScreen/MyAppointmentsScreen';
import PaymentsScreen from '../../screens/PaymentsScreen/PaymentsScreen';
import SettingsScreen from '../../screens/SettingsScreen/SettingsScreen';
import { isFeatureTogglingActivated } from '../../screens/__Utils__/featureToggling';
import BasicTextHeader from '../headers/BasicTextHeader';
import MyAppointmentsScreenHeader from '../headers/MyAppointmentsScreenHeader';
import { ColorProps, setOptionsHeader, setOptionsIcon } from './shared';
import {
  CareGiverDeepLinkPath,
  CareGiverDrawerScreenTitles,
  DrawerRoutes,
} from './types';

export const initialRouteName = 'DrawerNavigator';

export const careGiverRoutes: DrawerRoutes[] = [
  {
    name: 'Home',
    component: MyAppointmentsScreen,
    path: CareGiverDeepLinkPath.MY_RDV,
    options: {
      ...setOptionsHeader(<MyAppointmentsScreenHeader />),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <AntDesign name="calendar" size={24} color={color} />
      )),
      title: CareGiverDrawerScreenTitles.MY_RDV,
    },
  },
  {
    name: 'DeclareMyHours',
    component: DeclareHoursScreen,
    path: CareGiverDeepLinkPath.DECLARE_MY_HOURS,
    options: {
      ...setOptionsHeader(
        <BasicTextHeader
          title={CareGiverDrawerScreenTitles.DECLARE_MY_HOURS}
        />,
      ),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <Feather name="clock" size={24} color={color} />
      )),
      title: CareGiverDrawerScreenTitles.DECLARE_MY_HOURS,
    },
  },
  ...(isFeatureTogglingActivated()
    ? [
        {
          name: 'MySalary',
          component: PaymentsScreen,
          path: CareGiverDeepLinkPath.MY_SALARY,
          initialParams: { type: 'CAREGIVER' },
          options: {
            ...setOptionsHeader(
              <BasicTextHeader title={CareGiverDrawerScreenTitles.MY_SALARY} />,
            ),
            ...setOptionsIcon(({ color }: ColorProps) => (
              <MaterialIcons name="euro" size={24} color={color} />
            )),
            title: CareGiverDrawerScreenTitles.MY_SALARY,
          },
        } as const,
      ]
    : []),
  /*{
    name: 'MyContracts',
    component: MyAppointmentsScreen,
    path: DeepLinkPath.MY_CONTRACTS,
    options: {
      ...setOptionsHeader(
        <StyledTitle>{DrawerScreenTitles.MY_CONTRACTS}</StyledTitle>,
      ),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <Ionicons name="documents-outline" size={24} color={color} />
      )),
      title: DrawerScreenTitles.MY_CONTRACTS,
    },
  },*/
  {
    name: 'FindAMission',
    component: FindMission,
    path: CareGiverDeepLinkPath.FIND_A_MISSION,
    options: {
      ...setOptionsHeader(
        <BasicTextHeader title={CareGiverDrawerScreenTitles.FIND_A_MISSION} />,
      ),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <MaterialIcons name="work-outline" size={24} color={color} />
      )),
      title: CareGiverDrawerScreenTitles.FIND_A_MISSION,
    },
  },
  {
    name: 'CandidateProfile',
    component: CandidateProfile,
    path: CareGiverDeepLinkPath.CANDIDATE_PROFILE,
    options: {
      ...setOptionsHeader(
        <BasicTextHeader
          title={CareGiverDrawerScreenTitles.CANDIDATE_PROFILE}
        />,
      ),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <AntDesign name="user" size={24} color={color} />
      )),
      title: CareGiverDrawerScreenTitles.CANDIDATE_PROFILE,
    },
  },
  {
    name: 'Settings',
    component: SettingsScreen,
    path: CareGiverDeepLinkPath.SETTINGS,
    options: {
      ...setOptionsHeader(
        <BasicTextHeader title={CareGiverDrawerScreenTitles.SETTINGS} />,
      ),
      ...setOptionsIcon(({ color }: ColorProps) => (
        <AntDesign name="setting" size={24} color={color} />
      )),
      title: CareGiverDrawerScreenTitles.SETTINGS,
    },
  },
];
