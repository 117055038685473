import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  AuthenticatedStackParamList,
  useNavigation as useRootNavigation,
} from '../../navigation/types';
import {
  useCreateApplication,
  useGetAnnounceById,
} from '../../redux/announce/hooks';
import useSelector from '../../redux/useSelector';
import { selectAnnounceWithDetails } from '../../redux/announce';
import { RouteProp } from '@react-navigation/native';
import {
  IconTextContainer,
  MissionScreenContainer,
  MissionScreenTitle,
  StyledText,
  GeneralInfoContainer,
  ServiceContainer,
  ServiceTitle,
  StyledChip,
  ChipsContainer,
  StyledButton,
} from './MissionScreen.style';
import { AntDesign, Feather, MaterialIcons } from '@expo/vector-icons';
import { colorUsage } from '../../stylesheet';
import HelpButton from '../../components/atoms/HelpButton/HelpButton';
import ModalPopin from '../../components/molecules/ModalPopin/ModalPopin';
import ApplicationScheduleModalContent from './components/ApplicationScheduleModalContent/ApplicationScheduleModalContent';
import { selectMeAsCareGiver } from '../../redux/users';
import {
  ANNOUNCE_TYPES,
  FamilyAnnounceWithDetails,
  SERVICES,
} from '../../redux/announce/types';
import {
  isIdentityCompleted,
  isStatusCompleted,
} from '../__Utils__/completionCheck';
import {
  formatDate,
  formatAnnounceTitle,
  formatHourSalary,
  formatTotalWorkedHours,
  getSalaryFromCentsToEuros,
} from '../__Utils__/textFormatting';
import { minutesToHours } from 'date-fns';
import { getTotalWorkedMinutes } from '../__Utils__/hourDeclarationProcessing';
import { useNavigation as useDrawerNavigation } from '../../navigation/DrawerNavigator/types';
import { getAnnounceStartDate } from '../__Utils__/scheduleProcessing';

type Props = {
  route: RouteProp<AuthenticatedStackParamList, 'MissionScreen'>;
};

const MissionScreen = ({ route }: Props): JSX.Element => {
  const { navigate: rootNavigate } = useRootNavigation();
  const { navigate: drawerNavigate } = useDrawerNavigation();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [{}, doUseFetchAnnounceById] = useGetAnnounceById();
  const [{}, doUseCreateApplication] = useCreateApplication();

  const careGiver = useSelector(selectMeAsCareGiver);

  useEffect(() => {
    doUseFetchAnnounceById({ id: route.params.announceId });
  }, [doUseFetchAnnounceById, route.params.announceId]);

  const announceWithDetails = useSelector(selectAnnounceWithDetails);
  const me = useSelector(selectMeAsCareGiver);

  if (announceWithDetails === undefined || me === undefined)
    return <View></View>;

  const onPressModalApply = async (scheduleIds: string[]) => {
    await doUseCreateApplication({
      id: announceWithDetails.id,
      scheduleIds: scheduleIds,
    });
    setIsScheduleModalOpen(false);
    if (!isIdentityCompleted(careGiver)) {
      rootNavigate('CandidateIdentity');
    } else if (!isStatusCompleted(careGiver)) {
      rootNavigate('CandidateStatus');
    } else {
      drawerNavigate('FindAMission');
    }
  };

  const services = [
    ...announceWithDetails.services,
    ...(announceWithDetails.announceType === ANNOUNCE_TYPES.FAMILY
      ? (announceWithDetails as FamilyAnnounceWithDetails).additionalNeeds
      : []),
  ];

  const totalWorkedHours = minutesToHours(
    getTotalWorkedMinutes(announceWithDetails.schedule),
  );

  return (
    <MissionScreenContainer showsVerticalScrollIndicator={false}>
      <MissionScreenTitle>
        {formatAnnounceTitle(announceWithDetails, me)}
      </MissionScreenTitle>
      <GeneralInfoContainer>
        <IconTextContainer>
          <Feather
            name="clock"
            size={16}
            color={colorUsage.missionScreenIconColor}
          />
          <StyledText>
            {formatTotalWorkedHours(totalWorkedHours)} heures / semaine
          </StyledText>
        </IconTextContainer>
        <IconTextContainer>
          <MaterialIcons
            name="euro"
            size={16}
            color={colorUsage.missionScreenIconColor}
          />
          <StyledText>
            {formatHourSalary(announceWithDetails.hourSalary, totalWorkedHours)}
            € brut / mois
          </StyledText>
          <HelpButton
            description={`Salaire horaire ${getSalaryFromCentsToEuros(
              announceWithDetails.hourSalary,
            )}€ brut / heure en calculant sur un mois de 4 semaines. En fonction du mois, il est possible que vous receviez plus ou moins.`}
          />
        </IconTextContainer>
        <IconTextContainer>
          <AntDesign
            name="calendar"
            size={16}
            color={colorUsage.missionScreenIconColor}
          />
          <StyledText>
            {formatDate(getAnnounceStartDate(announceWithDetails))}
          </StyledText>
        </IconTextContainer>
      </GeneralInfoContainer>
      <ServiceContainer>
        <ServiceTitle>Services ({services.length})</ServiceTitle>
        <ChipsContainer>
          {services.map(service => (
            <StyledChip text={SERVICES[service]} key={service} />
          ))}
        </ChipsContainer>
      </ServiceContainer>
      <StyledButton
        title="Postuler"
        onPress={() => setIsScheduleModalOpen(true)}
        LeftIcon={
          <AntDesign
            name="checkcircle"
            size={15}
            color={colorUsage.buttonText}
          />
        }
      />
      <ModalPopin
        title="Décochez les créneaux où vous n'êtes pas disponible"
        content={
          <ApplicationScheduleModalContent
            schedules={announceWithDetails.schedule}
            onPressApply={onPressModalApply}
          />
        }
        visible={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
      />
    </MissionScreenContainer>
  );
};

export default MissionScreen;
