import { CareGiver } from '../../redux/users/types';

export const isIdentityCompleted = (careGiver?: CareGiver): boolean => {
  if (
    careGiver?.person?.gender &&
    careGiver?.person?.lastName &&
    careGiver?.person?.firstName &&
    careGiver?.person?.address &&
    careGiver?.person?.phone &&
    careGiver?.person?.numberSocialSecurity &&
    careGiver?.hasCar !== null
  ) {
    return true;
  }
  return false;
};

export const isStatusCompleted = (careGiver?: CareGiver): boolean => {
  if (careGiver?.nanCesu && careGiver?.siret && careGiver?.sapNova) {
    return true;
  }
  return false;
};
