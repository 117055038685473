import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { Animated, TextStyle } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { SpacingNumbers, titleH2StyleSheet } from '../stylesheet';

export const noHeaderScreenOptions = { headerShown: false };

const HEADER_HEIGHT = 60;

const renderBackImage = (): React.ReactNode => (
  <AntDesign
    name="left"
    size={20}
    color="black"
    style={{ marginLeft: SpacingNumbers.m }}
  />
);

export const defaultHeaderScreenOptions: StackNavigationOptions = {
  headerBackTitleVisible: false,
  headerBackImage: renderBackImage,
  headerTitleStyle: titleH2StyleSheet as Animated.WithAnimatedObject<TextStyle>,
  headerTitleAlign: 'center',
};

export const headerWithCustomTitleComponentScreenOptions = (
  headerTitle: string,
  topInset: number,
): StackNavigationOptions => ({
  ...defaultHeaderScreenOptions,
  headerTitle,
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
    height: HEADER_HEIGHT + topInset,
  },
});
