import { AntDesign } from '@expo/vector-icons';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import CustomKeyboardAvoidingView from '../../components/molecules/KeyBoardAvoidingView/KeyboardAvoidingView';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage } from '../../stylesheet';
import AddressFormikInput from '../../components/organism/AddressFormikInput/AddressFormikInput/AddressFormikInput';
import {
  InputAndErrorContainer,
  ScreenContainer,
  FormContainer,
  SavePaymentInfosError,
} from './PaymentInfosScreen.style';
import {
  initValues,
  PaymentInfosFormValuesType,
  validationSchema,
} from './service';
import Input from '../../components/atoms/Input/Input';
import { ScrollView } from 'react-native';
import IbanInput from './components/IbanInput/IbanInput';
import BicInput from './components/BicInput/BicInput';
import useSelector from '../../redux/useSelector';
import { selectPaymentInfos } from '../../redux/paymentInfos';
import { useFetchPaymentInfos } from '../../redux/paymentInfos/hooks';
import { PaymentInfos } from '../../redux/paymentInfos/types';
import { useNavigation } from '../../navigation/types';
import { omit } from 'lodash';
import { postPaymentInfos } from '../../redux/paymentInfos/api';
import Loader from '../../components/atoms/Loader/Loader';

const PaymentInfosScreen = (): JSX.Element => {
  const { goBack } = useNavigation();
  const [
    { loading: getPaymentInfosLoading },
    doFetchPaymentInfos,
  ] = useFetchPaymentInfos();
  useEffect(() => {
    doFetchPaymentInfos();
  }, [doFetchPaymentInfos]);

  const [savePaymentInfosLoading, setSavePaymentInfosLoading] = useState(false);
  const [savePaymentInfosError, setSavePaymentInfosError] = useState(false);

  const paymentInfos = useSelector(selectPaymentInfos);

  let isButtonDisabled: boolean;

  const submitForm = async (
    values: PaymentInfosFormValuesType,
  ): Promise<void> => {
    try {
      setSavePaymentInfosLoading(true);
      await postPaymentInfos(omit(values, ['addressComplete']) as PaymentInfos);
      setSavePaymentInfosLoading(false);
      setSavePaymentInfosError(false);
      goBack();
    } catch (error) {
      setSavePaymentInfosError(true);
      setSavePaymentInfosLoading(false);
    }
  };

  if (getPaymentInfosLoading) return <Loader />;

  return (
    <>
      {savePaymentInfosLoading && <Loader />}
      <CustomKeyboardAvoidingView>
        <ScreenContainer>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Formik
              initialValues={initValues(paymentInfos)}
              validationSchema={validationSchema}
              onSubmit={submitForm}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                isValid,
                dirty,
                errors,
              }) => {
                isButtonDisabled = !isValid || !dirty;
                return (
                  <FormContainer>
                    <IbanInput
                      onChangeText={handleChange('iban')}
                      value={values.iban}
                      error={errors.iban}
                    />
                    <BicInput
                      onChangeText={handleChange('bic')}
                      value={values.bic}
                      error={errors.bic}
                    />

                    <InputAndErrorContainer>
                      <AddressFormikInput />
                    </InputAndErrorContainer>

                    <InputAndErrorContainer>
                      <Input
                        label="Titulaire"
                        onChangeText={handleChange('ownerName')}
                        value={values.ownerName}
                        isRequired
                        testID="owner"
                      />
                    </InputAndErrorContainer>

                    {savePaymentInfosError && (
                      <SavePaymentInfosError testID="PaymentInfosScreen_SavePaymentInfosError">
                        Une erreur est survenue lors de l'enregistrement de vos
                        informations de paiement. Veuillez vérifier l'exactitude
                        de vos informations. Si elles sont correctes, veuillez
                        contacter le service client.
                      </SavePaymentInfosError>
                    )}
                    <PrimaryButton
                      title="Enregistrer"
                      onPress={handleSubmit}
                      light
                      RightIcon={
                        <AntDesign
                          name="check"
                          size={18}
                          color={
                            isButtonDisabled
                              ? colorUsage.buttonDisabledTextLight
                              : colorUsage.buttonTextLight
                          }
                        />
                      }
                      disabled={isButtonDisabled}
                    />
                  </FormContainer>
                );
              }}
            </Formik>
          </ScrollView>
        </ScreenContainer>
      </CustomKeyboardAvoidingView>
    </>
  );
};

export default PaymentInfosScreen;
