import { RouteProp } from '@react-navigation/native';
import React from 'react';
import PaymentsInfoBlock from '../../components/organism/PaymentInfosBlock/PaymentsInfoBlock';
import { FamilyDrawerParamList } from '../../navigation/DrawerNavigator/types';
import { ScreenContainer } from './PaymentsScreen.style';

type Props = {
  route: RouteProp<FamilyDrawerParamList, 'Payments'>;
};

const PaymentsScreen = ({ route }: Props): JSX.Element => {
  return (
    <ScreenContainer>
      <PaymentsInfoBlock type={route.params.type} />
    </ScreenContainer>
  );
};

export default PaymentsScreen;
