import styled from '@emotion/native';
import Input from '../../components/atoms/Input/Input';
import { colorUsage, interactiveLink, SpacingNumbers } from '../../stylesheet';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { ErrorBlock } from '../../components/atoms/ErrorBlock/ErrorBlock';
import Checkbox from 'expo-checkbox';

export const StyledButton = styled(PrimaryButton)({
  marginBottom: SpacingNumbers.xl,
});

export const MainContainer = styled.View({
  flex: 1,
  alignItems: 'center',
  paddingHorizontal: SpacingNumbers.m,
});

export const EmailInput = styled(Input)({
  marginBottom: SpacingNumbers.l,
});

export const PasswordInput = styled(Input)({
  marginBottom: SpacingNumbers.m,
});

export const AccountCreationErrorBlock = styled(ErrorBlock)({
  marginBottom: SpacingNumbers.m,
});

export const CGUBlock = styled.View({
  marginBottom: SpacingNumbers.m,
  flexDirection: 'row',
});

export const Link = styled.TouchableOpacity({
  flexDirection: 'row',
  marginLeft: SpacingNumbers.m,
  alignItems: 'center',
});

export const LinkText = styled(interactiveLink)({
  color: colorUsage.link,
});

export const CGUText = styled(interactiveLink)({
  color: colorUsage.bodyText,
  textDecorationLine: 'none',
});

export const StyledCheckBox = styled(Checkbox)({
  transform: [{ scaleX: 1.4 }, { scaleY: 1.4 }],
});
