import styled from '@emotion/native';
import { Camera } from 'expo-camera';
import {
  SpacingNumbers,
  colorUsage,
  BorderRadiusNumbers,
} from '../../../../stylesheet';

export const Container = styled.View({
  flex: 1,
});

export const StyledCamera = styled(Camera)({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'center',
});

export const TakePictureButton = styled.TouchableOpacity({
  position: 'absolute',
  bottom: SpacingNumbers.m,
  width: SpacingNumbers.L,
  height: SpacingNumbers.L,
  padding: SpacingNumbers.m,
  borderRadius: BorderRadiusNumbers.XL,
  backgroundColor: colorUsage.cameraRoundButton,
});
