import React, { useState, useEffect } from 'react';
import { Ionicons } from '@expo/vector-icons';
import { useFormikContext } from 'formik';
import addressesClient from '../../../../services/address/api';
import { colorUsage } from '../../../../stylesheet';
import { AddressSuggestion } from '../../../../services/address/types';
import {
  AddressesSuggestionsContainer,
  StyledAutoCompleteContainer,
} from './AddressFormikInput.style';
import AutoCompleteAddressItem from '../AddressSuggestion/AddressSuggestion';
import Input from '../../../atoms/Input/Input';
import { useDebounce } from '../../../../hooks/useDebounce';

export interface ValuesType {
  address: string;
  city: string;
  postCode: string;
  addressComplete: string;
}

type Props = {
  hasError?: boolean;
};

const AddressFormikInput = <T extends ValuesType>({
  hasError,
}: Props): JSX.Element => {
  const { values, handleChange, setFieldValue } = useFormikContext<T>();
  const [addressesSuggestions, setAddressesSuggestions] = useState<
    AddressSuggestion[]
  >([]);
  const [isAddressesSuggestionsShow, setIsAddressesSuggestionsShow] = useState(
    false,
  );
  const debouncedAddressComplete = useDebounce(values.addressComplete, 200);

  useEffect(() => {
    if (debouncedAddressComplete && debouncedAddressComplete !== '') {
      addressesClient.search(debouncedAddressComplete).then(suggestions => {
        setAddressesSuggestions(suggestions);
      });
    }
  }, [debouncedAddressComplete]);

  const onAddressSuggestionSelect = async ({
    properties,
    geometry,
  }: AddressSuggestion) => {
    setFieldValue('address', {
      address: properties.name,
      postCode: properties.postcode,
      city: properties.city,
      gpsLng: geometry.coordinates[0],
      gpsLat: geometry.coordinates[1],
    });
    setFieldValue(
      'addressComplete',
      `${properties.name} - ${properties.postcode}, ${properties.city}`,
    );
  };

  return (
    <StyledAutoCompleteContainer pointerEvents="box-none">
      <Input
        label="Adresse"
        onChangeText={handleChange('addressComplete')}
        value={values.addressComplete}
        placeholder="Adresse"
        isRequired
        placeholderTextColor={colorUsage.placeHolderText}
        onFocus={() => setIsAddressesSuggestionsShow(true)}
        testID="AutoCompleteInput"
        Icon={
          <Ionicons
            name="md-location-outline"
            size={20}
            color={colorUsage.candidateIdentityFieldIconColor}
          />
        }
        hasError={hasError}
      />
      {addressesSuggestions.length > 0 && isAddressesSuggestionsShow && (
        <AddressesSuggestionsContainer testID="AdressesSuggestionsList">
          {addressesSuggestions.map(addressSuggestion => (
            <AutoCompleteAddressItem
              key={addressSuggestion.properties.id}
              address={addressSuggestion.properties.name}
              city={addressSuggestion.properties.city}
              postCode={addressSuggestion.properties.postcode}
              onPress={() => {
                onAddressSuggestionSelect(addressSuggestion);
                setIsAddressesSuggestionsShow(false);
              }}
            />
          ))}
        </AddressesSuggestionsContainer>
      )}
    </StyledAutoCompleteContainer>
  );
};

export default AddressFormikInput;
