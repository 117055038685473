/* eslint-disable max-lines */
import { StyleSheet } from 'react-native';
import styled from '@emotion/native';

/**
 * Do not use directly the colorPalette in your components
 * Create an entry in the colorUsage below instead
 */
const colorPalette = {
  white: '#FFFFFF',
  palewhite: '#FFFFFF33',

  grey: '#BEC5CA',
  greyLight: '#e5e5e5',
  greyMedium: '#4F5B64',
  greyPale: '#F1F5F8',
  greyDark: '#252323',
  greyNeutral: '#3E3E3E',

  primaryBlueLight: '#D1E6ED',
  blueMain: '#387F95',
  blueLeftGradient: '#71C2C0',
  blueRightGradient: '#088098',
  primaryDarkBlue: '#04263F',

  greenPale: '#CCF0D680',
  mainShadowColor: '#52616429',
  lightShadowColor: '#526164',

  redPale: '#ea9999',
  redMedium: '#D44949',
  redDark: '#9B2C2C',
  orangeMedium: '#DCA02D',
  greenMedium: '#48AE65',

  black: '#000000',
};

export const gradientColors = {
  blue: ['#71C2C0', '#088098'],
  red: ['#D44949', '#D44949'],
  grey: ['#BEC5CA50', '#BEC5CA50'],
  greyDark: ['#BEC5CA', '#BEC5CA'],
  greyPale: ['#F1F5F8', '#F1F5F8'],
  white: ['#FFFFFF', '#FFFFFF'],
  blueFlat: ['#387F95', '#387F95'],
  pink: ['#F1A594', '#F1A594'],
  lightBlueFlat: ['#D1E6ED', '#D1E6ED'],
};

/**
 * Use this dictionnary in your components
 * Define a new key each time you use a colour if it's for a different use
 * Ex: fill, border-color, background-color, color ...
 */
export const colorUsage = {
  contentBackground: colorPalette.greyPale,
  popinBackground: colorPalette.greyMedium,
  buttonText: colorPalette.white,
  buttonTextLight: colorPalette.blueMain,
  buttonDisabled: colorPalette.grey,
  buttonDisabledText: colorPalette.white,

  choiceButtonCheckIconColor: colorPalette.blueMain,
  choiceButtonTextColor: colorPalette.primaryDarkBlue,

  link: colorPalette.blueMain,
  numberBlockText: colorPalette.greyDark,
  numberBlockBackground: colorPalette.white,
  numberBlockError: colorPalette.redMedium,

  tooltip: colorPalette.blueMain,
  tooltipBackground: colorPalette.primaryDarkBlue,
  tooltipText: colorPalette.white,

  buttonDisabledTextLight: colorPalette.grey,

  infoBlockBackground: colorPalette.greenPale,
  infoBlockText: colorPalette.greyMedium,

  errorBlockBackground: colorPalette.redPale,
  errorBlockText: colorPalette.greyMedium,

  userTypeCardBackground: colorPalette.white,

  menuBurger: colorPalette.primaryDarkBlue,

  noHourDeclarationTextColor: colorPalette.primaryDarkBlue,

  // Header
  headerBackground: colorPalette.white,
  headerTitle: colorPalette.primaryDarkBlue,
  headerTouchableTitle: colorPalette.blueMain,

  // Menu
  menuBackground: colorPalette.primaryDarkBlue,
  menuActiveBackgroundColor: colorPalette.palewhite,
  menuActiveTextColor: colorPalette.white,
  menuDefaultTextColor: colorPalette.grey,

  // Calendar
  calendarBackground: colorPalette.greyPale,
  calendarDayColor: colorPalette.greyMedium,
  selectedCalendarDayColor: colorPalette.blueMain,
  selectedCalendarTextColor: colorPalette.white,
  disabledCalendarTextColor: colorPalette.grey,
  calendarHeaderTitle: colorPalette.primaryDarkBlue,
  calendarBorderBottom: colorPalette.grey,
  calendarCloseIcon: colorPalette.blueMain,
  calendarNextPreviousIcon: colorPalette.blueMain,

  // Gradient
  blueLeftGradient: colorPalette.blueLeftGradient,
  blueRightGradient: colorPalette.blueRightGradient,

  //Appointments
  technicalInfoContainerBackgroundColor: colorPalette.greyPale,
  technicalInfoTextColor: colorPalette.greyMedium,
  technicalInfoTitleColor: colorPalette.greyDark,
  appointmentsDarkBackgroundTextColor: colorPalette.white,
  appointmentsLightBackgroundTextColor: colorPalette.greyDark,
  appointmentCardBackgroundColor: colorPalette.white,
  technicalInfoIconColor: colorPalette.greyMedium,

  //FindMissions
  searchIconColor: colorPalette.blueMain,
  ellipseSearchIconColor: colorPalette.primaryBlueLight,
  noCriteriaTextColor: colorPalette.primaryDarkBlue,
  //Select Modal
  modalCloseIcon: colorPalette.blueMain,
  selectModalCheckIcon: colorPalette.blueMain,
  selectModalSelectedText: colorPalette.greyMedium,
  selectModalNotSelectedText: colorPalette.greyMedium,
  //DropDown
  placeholderDropDownText: colorPalette.grey,
  regularDropDownText: colorPalette.primaryDarkBlue,
  dropDownElements: colorPalette.blueMain,
  dropDownOnPressColor: colorPalette.greyPale,
  //SearchResults
  myCriteriaLinkText: colorPalette.blueMain,
  newMissionTextTitle: colorPalette.greyDark,
  //MissionCard
  missionCardTitle: colorPalette.greyDark,
  missionCardTextContent: colorPalette.greyMedium,
  missionCardIconColor: colorPalette.grey,
  missionCardBackgroundColor: colorPalette.white,

  //Chip
  chipDarkerBackgroundColor: colorPalette.greyPale,
  chipLighterBackgroundColor: colorPalette.white,
  chipTextColor: colorPalette.primaryDarkBlue,

  //MissionScreen
  missionScreenIconColor: colorPalette.grey,
  missionScreenTitle: colorPalette.greyDark,
  missionScreenTextContent: colorPalette.greyMedium,
  missionScreenLineColor: colorPalette.grey,

  //Input
  labelText: colorPalette.greyMedium,
  placeHolderText: colorPalette.grey,
  inputBorderColor: colorPalette.blueMain,
  inputBackgroundColor: colorPalette.white,
  inputDarkerBackgroundColor: colorPalette.greyPale,
  hasErrorInputBorderColor: colorPalette.redMedium,
  inputRequiredTextColor: colorPalette.redMedium,
  inputIconColor: colorPalette.grey,

  bodyText: colorPalette.greyMedium,

  //Switch
  thumbColor: colorPalette.white,
  switchOn: colorPalette.blueMain,
  switchOff: colorPalette.grey,
  switchIosBackground: colorPalette.greyNeutral,

  //PasswordValidation
  passwordNone: colorPalette.greyLight,
  passwordVeryWeak: colorPalette.redDark,
  passwordWeak: colorPalette.redMedium,
  passwordMedium: colorPalette.orangeMedium,
  passwordStrong: colorPalette.blueMain,
  passwordVeryStrong: colorPalette.greenMedium,

  //Login
  loginErrorText: colorPalette.redMedium,

  //StepperScreen
  stepperScreenTitleColor: colorPalette.primaryDarkBlue,
  stepperScreenChapeauTextColor: colorPalette.greyMedium,

  //CareGiverProfil
  profilBlockTitle: colorPalette.greyDark,
  profilBlockBackground: colorPalette.white,
  profilWarningText: colorPalette.redMedium,

  //FindACareGiver
  findCareGiverFieldIconColor: colorPalette.grey,
  //CandidateIdentity
  candidateIdentityFieldIconColor: colorPalette.grey,
  autoCompleteBackgroundColor: colorPalette.white,

  //CameraButton
  cameraButtonBackground: colorPalette.white,
  cameraRoundButton: colorPalette.white,
  cameraButtonRequired: colorPalette.redMedium,
  cameraButtonDetails: colorPalette.black,
  cameraButtonDescription: colorPalette.greyMedium,
  cameraButtonBorder: colorPalette.blueMain,
  cameraButtonPhoto: colorPalette.blueMain,
  //AplicationCard
  applicationCardBackgroundColor: colorPalette.white,
  greetingsTextColor: colorPalette.greyDark,
  firstNameTextColor: colorPalette.greyDark,
  certificationTextColor: colorPalette.greyMedium,
  separatorLineColor: colorPalette.greyPale,
  phoneNumberTextColor: colorPalette.greyMedium,
  availabilityTextColor: colorPalette.black,
  availabilityDayColor: colorPalette.greyMedium,
  availabilityHoursColor: colorPalette.greyMedium,

  //HourDeclarationCard
  hourDeclarationCardTitle: colorPalette.primaryDarkBlue,
  hourDeclarationIcon: colorPalette.grey,
  hourDeclarationSubtitle: colorPalette.greyMedium,
  hourDeclarationCardBackground: colorPalette.white,
  hourDeclarationPhotoCircle: colorPalette.greenMedium,
  hourDeclarationPhotoCircleText: colorPalette.white,
  //HourDeclarationDetails
  hourDeclarationDetailsFAQ: colorPalette.greyDark,
  hourDeclarationDetailsDay: colorPalette.greyDark,
  hourDeclarationDetailsReset: colorPalette.redMedium,
  hourDeclarationDetailsText: colorPalette.greyMedium,
  hourDeclarationReset: colorPalette.redMedium,
  hourDeclarationDetailsValidatedText: colorPalette.greyDark,

  //MonthlyDeclarationCard
  monthlyDeclarationCardBackground: colorPalette.white,
  monthlyDeclarationCardDetails: colorPalette.greyMedium,
  monthlyDeclarationIcon: colorPalette.grey,
  monthlyDeclarationMonthTitle: colorPalette.primaryDarkBlue,
  monthlyDeclarationCarret: colorPalette.grey,

  //HourDeclarationConfirmationModal
  confirmationModalText: colorPalette.greyMedium,

  //HourDeclarationPaymentModal
  paymentModalText: colorPalette.greyMedium,

  //HourInput
  hourInputIcon: colorPalette.grey,

  //HelpSection
  helpSectionBackground: colorPalette.primaryBlueLight,
  helpSectionText: colorPalette.primaryDarkBlue,
  helpSectionIcon: colorPalette.blueMain,

  //Payments and Salary
  //PaymentsInfoBlock
  paymentsInfoBlockBackground: colorPalette.white,
  paymentsInfoBlockTitleMain: colorPalette.greyDark,
  paymentsInfoBlockTitleSecondary: colorPalette.blueMain,
  paymentsInfoBlockText: colorPalette.greyMedium,
  paymentsInfoSaveError: colorPalette.redDark,
};

export const BoxShadow = {
  shadowMain: {
    shadowColor: colorPalette.mainShadowColor,
    shadowRadius: 10,
    shadowOffset: { width: 2, height: 2 },
  },
  shadowMainOpacity: 0.16,
  shadowMainElevation: 4,

  shadowLight: { shadowColor: colorPalette.lightShadowColor, shadowRadius: 8 },
  shadowLightOpacity: 0.08,
  shadowLightElevation: 0.5,
};

export const SpacingNumbers = {
  xs: 4,
  s: 8,
  m: 16,
  l: 24,
  xl: 32,
  xxl: 40,
  xxxl: 48,
  L: 64,
  XL: 72,
  XXL: 80,
} as const;

export const BorderRadiusNumbers = {
  xs: 4,
  s: 8,
  m: 16,
  xxl: 40,
  XL: 100,
} as const;

export const titleH1StyleSheet = {
  fontFamily: 'montserrat-bold',
  fontSize: 24,
  lineHeight: 29,
  letterSpacing: 0,
  textAlign: 'center',
} as const;

export const titleH1 = styled.Text({
  ...titleH1StyleSheet,
});

export const titleH2StyleSheet = {
  fontFamily: 'montserrat-bold',
  fontSize: 18,
};

export const titleH2 = styled.Text({
  ...titleH2StyleSheet,
  lineHeight: 23,
});

export const titleH3 = styled.Text({
  fontFamily: 'montserrat-semibold',
  fontSize: 16,
  lineHeight: 21,
});

export const textP1RegularStyleSheet = {
  fontFamily: 'lato-regular',
  fontSize: 18,
  lineHeight: 23,
};

export const textP1Regular = styled.Text({
  ...textP1RegularStyleSheet,
  textAlign: 'center',
});

export const textP1Bold = styled.Text({
  fontFamily: 'lato-bold',
  fontSize: 18,
  lineHeight: 23,
  textAlign: 'center',
});

export const textP1BoldStyle = {
  fontFamily: 'lato-bold',
  fontSize: 18,
  lineHeight: 23,
};
export const textP2Regular = styled.Text({
  fontFamily: 'lato-regular',
  fontSize: 14,
});

export const textP2Italic = styled.Text({
  fontFamily: 'lato-italic',
  fontSize: 14,
});

export const textP2Bold = styled.Text({
  fontFamily: 'lato-bold',
  fontSize: 14,
});

export const styles = StyleSheet.create({
  interractiveCTAText: {
    fontFamily: 'montserrat-bold',
    fontSize: 14,
    lineHeight: 17,
  },
});

export const textP2RegularStyle = {
  fontFamily: 'lato-regular',
  fontSize: 14,
  lineHeight: 18,
};

export const textP2BoldStyle = {
  ...textP2RegularStyle,
  fontFamily: 'lato-bold',
};

export const interractiveCTAText = styled.Text({
  fontFamily: 'montserrat-bold',
  fontSize: 14,
  lineHeight: 17,
});

export const interactivePlaceholder = styled.Text({
  fontFamily: 'lato-italic',
  fontSize: 18,
  lineHeight: 23,
  textAlign: 'center',
});

export const interactiveLink = styled.Text({
  fontFamily: 'lato-bold',
  fontSize: 14,
  textDecorationLine: 'underline',
  color: colorUsage.link,
});

export const interactiveLinkM = styled.Text({
  fontFamily: 'lato-bold',
  fontSize: 18,
  color: colorUsage.link,
});
