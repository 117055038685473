import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credential } from './types';

export type SignUpState = Readonly<{
  credential: Credential;
}>;

const initialState: SignUpState = {
  credential: {},
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState: initialState,
  reducers: {
    updateCredential: (state, action: PayloadAction<Credential>) => {
      state.credential = action.payload;
    },
  },
});

export const { updateCredential } = signUpSlice.actions;
export default signUpSlice.reducer;
