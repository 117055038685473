import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from 'react-query';

import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation';
import { AuthProvider } from './services/auth/context';
import { buildStore } from './redux/store';

import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn:
    'https://320d190bdbca47deaef674515dcb16fe@o960083.ingest.sentry.io/5908394',
  enableInExpoDevelopment: false,
  environment: process.env.NODE_ENV,
  debug: process.env.NODE_ENV !== 'production', // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

const queryClient = new QueryClient();
const { store, persistor } = buildStore();

const App = (): JSX.Element => {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return <></>;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <SafeAreaProvider>
              <AuthProvider>
                <Navigation />
                <StatusBar style="dark" translucent />
              </AuthProvider>
            </SafeAreaProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    );
  }
};

export default App;
