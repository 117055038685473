import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Appointment, FilteredAppointments } from './types';

export type AppointmentState = Readonly<{
  appointments: Appointment[];
  dateFilter: string;
}>;

const initialState: AppointmentState = {
  appointments: [],
  dateFilter: new Date().toISOString(),
};

const appointmentSlice = createSlice({
  name: 'Appointment',
  initialState,
  reducers: {
    updateAppointments: (
      state,
      action: PayloadAction<FilteredAppointments>,
    ) => {
      state.appointments = action.payload.appointments;
      state.dateFilter = action.payload.dateFilter;
    },
  },
});

export const { updateAppointments } = appointmentSlice.actions;
export default appointmentSlice.reducer;
