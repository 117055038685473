import React, { useEffect } from 'react';
import { FlatList } from 'react-native';
import {
  selectApplications,
  selectAnnounceWithDetails,
} from '../../redux/announce';
import {
  useFetchApplications,
  useGetAnnounceByFamily,
} from '../../redux/announce/hooks';
import {
  Application,
  FamilyAnnounceWithDetails,
  STATUS,
} from '../../redux/announce/types';
import useSelector from '../../redux/useSelector';
import { FamilyAnnounceCard } from './components/FamilyAnnounceCard/FamilyAnnounceCard';
import { ApplicationCard } from './components/ApplicationCard/ApplicationCard';
import {
  AnnounceContainer,
  AnnounceText,
  Container,
  NoAnnounceText,
  StyledImage,
} from './FindCareGiver.style';

const renderAnnounce = (
  familyAnnounceWithDetails: FamilyAnnounceWithDetails,
  applications: Application[],
) => {
  return (
    <>
      <AnnounceText>Mon annonce</AnnounceText>
      <FamilyAnnounceCard
        applications={applications}
        announce={familyAnnounceWithDetails as FamilyAnnounceWithDetails}
      />
    </>
  );
};

const renderHeaderComponent = (
  familyAnnounceWithDetails: FamilyAnnounceWithDetails,
  applications: Application[],
) => {
  return familyAnnounceWithDetails.status === STATUS.SEARCH ? (
    <AnnounceText>Mes candidats ({applications.length})</AnnounceText>
  ) : (
    undefined
  );
};

const FindCareGiver = (): JSX.Element => {
  const [
    { loading: isAnnounceLoading },
    doUseFetchAnnounceByFamily,
  ] = useGetAnnounceByFamily();

  useEffect(() => {
    doUseFetchAnnounceByFamily();
  }, [doUseFetchAnnounceByFamily]);

  const [
    { loading: isApplicationLoading },
    doUseFetchApplications,
  ] = useFetchApplications();

  useEffect(() => {
    doUseFetchApplications();
  }, [doUseFetchApplications]);

  const familyAnnounceWithDetails = useSelector(selectAnnounceWithDetails);
  const applications = useSelector(selectApplications);

  return (
    <Container>
      {!isAnnounceLoading && !familyAnnounceWithDetails && (
        <>
          <StyledImage
            source={require('../../assets/images/NoAppointment-NoAnnounce.png')}
          />
          <NoAnnounceText>
            Il apparait que vous n'avez pas encore défini vos besoins.
          </NoAnnounceText>
        </>
      )}

      {!isAnnounceLoading &&
        !isApplicationLoading &&
        familyAnnounceWithDetails && (
          <AnnounceContainer>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={applications}
              renderItem={application => (
                <ApplicationCard application={application.item} />
              )}
              keyExtractor={application => application.id}
              ListHeaderComponent={renderHeaderComponent(
                familyAnnounceWithDetails as FamilyAnnounceWithDetails,
                applications,
              )}
              ListFooterComponent={renderAnnounce(
                familyAnnounceWithDetails as FamilyAnnounceWithDetails,
                applications,
              )}
            />
          </AnnounceContainer>
        )}

      {/* <PrimaryButton
        title="Définir mes besoins"
        onPress={() => {
          navigate('FindCareGiverBeneficiaryInfo');
        }}
        LeftIcon={
          <MaterialCommunityIcons
            name="briefcase-search-outline"
            size={15}
            color="white"
          />
        }
      /> */}
    </Container>
  );
};

export default FindCareGiver;
