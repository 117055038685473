import { authenticatedApiClient } from '../../services/networking/authenticatedApiClient';
import { CareGiverDocuments, CAREGIVER_DOCUMENT_TYPE } from '../users/types';
import { Document } from './types';

// istanbul ignore file
export async function preUploadDocument(
  document: Document,
): Promise<{ presignedUrl: string; documentId: string }> {
  const url = `care-giver/document/preupload`;
  const { data } = await authenticatedApiClient.post<{
    presignedUrl: string;
    documentId: string;
  }>(url, document);
  return data;
}

export async function preDownloadDocument(
  documentType: CAREGIVER_DOCUMENT_TYPE,
): Promise<{ presignedUrl: string }> {
  const url = `/care-giver/document/predownload`;
  const { data } = await authenticatedApiClient.get<{
    presignedUrl: string;
  }>(url, {
    params: {
      documentType,
    },
  });
  return data;
}

export async function fetchCareGiverDocuments(): Promise<CareGiverDocuments> {
  const url = `/care-giver/documents`;
  const { data } = await authenticatedApiClient.get<CareGiverDocuments>(url);
  return data;
}
