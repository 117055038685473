import {
  NavigationProp,
  PathConfig,
  RouteProp,
  useNavigation as useNavigationBase,
  useRoute as useRouteBase,
} from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { ComponentType, FunctionComponent } from 'react';
import {
  CareGiverDrawerParamList,
  CareGiverDrawerRouteName,
  FamilyDrawerParamList,
  FamilyDrawerRouteName,
} from './DrawerNavigator/types';
import { CameraCapturedPicture } from 'expo-camera';
import { HourDeclaration } from '../redux/hourDeclaration/types';

type RootStackParamList = UnauthenticatedStackParamList &
  AuthenticatedStackParamList;

export const useNavigation = (): NavigationProp<RootStackParamList> =>
  useNavigationBase<NavigationProp<RootStackParamList>>();

export const useRoute = <
  RouteName extends keyof RootStackParamList
>(): RouteProp<RootStackParamList, RouteName> =>
  useRouteBase<RouteProp<RootStackParamList, RouteName>>();

export interface IdCheckRoute<T, Y> {
  name: keyof Y;
  component: ComponentType<any> | FunctionComponent<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  options?: T;
  path?: string;
  exact?: boolean;
  strict?: boolean;
  to?: string;
  push?: boolean;
  sensitive?: boolean;
  from?: string;
  initialParams?: Record<string, unknown>;
}

export type AuthenticatedStackParamList = {
  DrawerNavigator: {
    screen: [CareGiverDrawerRouteName, FamilyDrawerRouteName];
    params: [
      CareGiverDrawerParamList[CareGiverDrawerRouteName],
      FamilyDrawerParamList[FamilyDrawerRouteName],
    ];
  };
  MissionScreen: { announceId: string };
  FindCareGiverBeneficiaryInfo: undefined;
  CandidateIdentity: undefined;
  CandidateStatus: undefined;
  CameraScreen: { onPhotoChange: (photo: CameraCapturedPicture) => void };
  IdentityDocuments: undefined;
  EditableDeclarationHourDetailScreen: {
    dateRange: string;
    declarationId: string;
  };
  MonthlyCareGiverHourDeclarationScreen: {
    month: string;
    hourDeclarations: HourDeclaration[];
  };
  MonthlyFamilyHourDeclarationScreen: {
    month: string;
    hourDeclarations: HourDeclaration[];
  };
  HourDeclarationDetailScreen: {
    dateRange: string;
    hourDeclaration: HourDeclaration;
    hourDeclarationCanBeValidated?: boolean;
  };
  PaymentInfosScreen: undefined;
};

export interface Route
  extends IdCheckRoute<StackNavigationOptions, AuthenticatedStackParamList> {
  pathConfig?: PathConfig<AuthenticatedStackParamList>;
}

export type UnauthenticatedStackParamList = {
  Home: undefined;
  Login: undefined;
  AccountCreation: undefined;
  EmailVerification: { email: string };
  AccountCreationProfileChoice: undefined;
};

export enum DeepLinkPath {
  MISSION_SCREEN = 'mission/:id',
}
