import styled from '@emotion/native';
import {
  SpacingNumbers,
  colorUsage,
  textP1Bold,
  textP2Regular,
  BorderRadiusNumbers,
  textP2Bold,
} from '../../../../stylesheet';

export const Container = styled.View({
  marginTop: SpacingNumbers.l,
  borderRadius: BorderRadiusNumbers.s,
  backgroundColor: colorUsage.profilBlockBackground,
  width: '100%',
  padding: SpacingNumbers.m,
});

export const Title = styled(textP1Bold)({
  color: colorUsage.profilBlockTitle,
  textAlign: 'left',
});

export const Description = styled(textP2Regular)({
  color: colorUsage.bodyText,
  paddingVertical: SpacingNumbers.s,
});

export const ButtonContainer = styled.View({
  width: '55%',
});

export const Warning = styled.View({
  flexDirection: 'row',
  marginBottom: SpacingNumbers.s,
});

export const WarningTitle = styled(textP2Bold)({
  color: colorUsage.profilWarningText,
});

export const WarningContent = styled(textP2Regular)({
  color: colorUsage.profilWarningText,
});
