import styled from '@emotion/native';
import { colorUsage, SpacingNumbers, titleH3 } from '../../stylesheet';

export const MonthlyHourDeclarationScreenContainer = styled.View({
  paddingHorizontal: SpacingNumbers.m,
  backgroundColor: colorUsage.contentBackground,
  height: '100%',
});

export const SectionTitle = styled(titleH3)({
  marginBottom: SpacingNumbers.m,
  marginTop: SpacingNumbers.m,
});
