import styled from '@emotion/native';
import { NumberInput } from '../../components/molecules/NumberInput/NumberInput';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage, SpacingNumbers } from '../../stylesheet';

export const CandidateStatusContainer = styled.View({
  paddingHorizontal: SpacingNumbers.m,
  backgroundColor: colorUsage.contentBackground,
  height: '100%',
  paddingTop: SpacingNumbers.l,
  justifyContent: 'space-between',
});

export const BottomMarginNumberInput = styled(NumberInput)({
  marginBottom: SpacingNumbers.l,
});

export const EndPrimaryButton = styled(PrimaryButton)({
  marginBottom: SpacingNumbers.l,
});
