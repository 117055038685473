import { authenticatedApiClient } from '../../services/networking/authenticatedApiClient';
import { PaymentInfos } from './types';

export async function fetchPaymentInfos(): Promise<PaymentInfos | undefined> {
  const url = `/payment-infos`;
  try {
    const { data } = await authenticatedApiClient.get<PaymentInfos | undefined>(
      url,
    );
    return data;
  } catch {
    return undefined;
  }
}

export async function postPaymentInfos(
  paymentInfos: PaymentInfos,
): Promise<void> {
  const url = `/payment-infos`;
  await authenticatedApiClient.post<PaymentInfos>(url, paymentInfos);
}
