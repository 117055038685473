import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { Modal } from 'react-native';
import { colorUsage } from '../../../stylesheet';
import {
  Container,
  Opacity,
  ContentContainer,
  HeaderContainer,
  CloseIconContainer,
  HeaderTitle,
} from './ModalWithHeaderAndFooter.style';

type Props = {
  headerTitle: string;
  content: JSX.Element;
  onClose: () => void;
  visible: boolean;
};

const ModalWithHeaderAndFooter = ({
  headerTitle,
  content,
  onClose,
  visible,
}: Props): JSX.Element => {
  return (
    <Modal
      visible={visible}
      animationType="slide"
      statusBarTranslucent
      transparent
      testID={`${headerTitle}_Modal_ModalHF`}
    >
      <Container>
        <Opacity />
        <HeaderContainer>
          <HeaderTitle>{headerTitle}</HeaderTitle>
          <CloseIconContainer
            onPress={onClose}
            testID={`${headerTitle}_ModalHeaderFooter_CloseIconContainer`}
          >
            <AntDesign
              name="close"
              size={24}
              color={colorUsage.calendarCloseIcon}
            />
          </CloseIconContainer>
        </HeaderContainer>
        <ContentContainer>{content}</ContentContainer>
      </Container>
    </Modal>
  );
};

export default ModalWithHeaderAndFooter;
