import { StyleSheet } from 'react-native';
import styled from '@emotion/native';
import {
  colorUsage,
  SpacingNumbers,
  textP1BoldStyle,
  textP2BoldStyle,
  textP2RegularStyle,
} from '../../../stylesheet';

export const CalendarContainer = styled.View({
  marginHorizontal: SpacingNumbers.s,
});

export const commonStyle = StyleSheet.create({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore using imported variable create a false ts error
  disabledDatesTextStyle: {
    ...textP2RegularStyle,
    color: colorUsage.disabledCalendarTextColor,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore using imported variable create a false ts error
  selectedDayTextStyle: {
    ...textP2BoldStyle,
    color: colorUsage.selectedCalendarTextColor,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore using imported variable create a false ts error
  textStyle: {
    ...textP2RegularStyle,
    color: colorUsage.calendarDayColor,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore using imported variable create a false ts error
  monthTitleStyle: textP1BoldStyle,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore using imported variable create a false ts error
  yearTitleStyle: textP1BoldStyle,

  customStyles: {
    backgroundColor: colorUsage.calendarBackground,
  },

  todayStyles: {
    opacity: 0.3,
  },

  selectedDayStyle: {
    opacity: 1,
    backgroundColor: colorUsage.selectedCalendarDayColor,
  },
});
