import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { colorUsage } from '../../../stylesheet';
import Input, { InputProps } from '../../atoms/Input/Input';

export const formatInputValue = (value: string): string => {
  const removedUnwantedCharacters = value
    .replace(/[\./]/g, ',')
    .replace(/[^\d,/]/g, '');
  const commaSplit = removedUnwantedCharacters.split(',');
  return removedUnwantedCharacters.substring(
    0,
    commaSplit[0].length + 1 + (commaSplit[1]?.length ?? 0),
  );
};

export const removeLeadingOrAddEndingZero = (str: string): string => {
  const value = parseFloat(str.replace(/[,/]/g, '.')).toFixed(2);
  return value;
};

export const MoneyInput = (props: InputProps): JSX.Element => {
  const unitDecimalSplit = props.value?.split(',') as string[];
  const maxLength = unitDecimalSplit[0].length + 3;

  const onChangeText = (text: string) => {
    props.onChangeText?.(formatInputValue(text));
  };

  const endEditingFormat = (): void => {
    props.onChangeText?.(removeLeadingOrAddEndingZero(props.value || '0'));
  };

  return (
    <Input
      {...props}
      Icon={
        <MaterialIcons
          name="euro"
          size={16}
          color={colorUsage.inputIconColor}
        />
      }
      value={formatInputValue(props.value as string)}
      keyboardType="numeric"
      onChangeText={onChangeText}
      style={{ backgroundColor: colorUsage.inputDarkerBackgroundColor }}
      onBlur={() => endEditingFormat()}
      maxLength={maxLength}
    />
  );
};
