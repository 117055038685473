import React from 'react';
import {
  InfoBlockBoldText,
  InfoBlockContainer,
  InfoBlockText,
} from './InfoBlock.style';

interface Props {
  boldText: string;
  text: string;
}

export const InfoBlock = ({ boldText, text }: Props): JSX.Element => {
  return (
    <InfoBlockContainer>
      <InfoBlockBoldText>{boldText}</InfoBlockBoldText>
      <InfoBlockText>{text}</InfoBlockText>
    </InfoBlockContainer>
  );
};
