import { createDrawerNavigator } from '@react-navigation/drawer';
import React from 'react';

import { careGiverRoutes } from './careGiverRoutes';
import { screenOptionsStyles } from './DrawerNavigator.style';
import CustomDrawerContent from './DrawerContent';
import { useAuth } from '../../services/auth/context';
import { familyRoutes } from './familyRoutes';

const Drawer = createDrawerNavigator();

export const DrawerNavigator = (): JSX.Element => {
  const { user } = useAuth();
  const routes = user?.type === 'care_giver' ? careGiverRoutes : familyRoutes;
  return (
    <Drawer.Navigator
      screenOptions={screenOptionsStyles}
      drawerContent={CustomDrawerContent}
    >
      {routes.map(route => (
        <Drawer.Screen
          name={route.name}
          component={route.component}
          options={route.options}
          key={route.name}
          initialParams={route.initialParams}
        />
      ))}
    </Drawer.Navigator>
  );
};
