import { TouchableOpacity } from 'react-native';
import styled from '@emotion/native';
import { SpacingNumbers, textP1Regular } from '../../../stylesheet';
import { TimeInput } from '../../atoms/TimeInput/TimeInput';
import { ErrorBlock } from '../../atoms/ErrorBlock/ErrorBlock';

export const InputView = styled.View({
  width: '100%',
});
export const HourInputsContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const EndHourInput = styled(TimeInput)({
  marginLeft: SpacingNumbers.s,
});

export const StartHourInput = styled(TimeInput)({
  marginRight: SpacingNumbers.s,
});
export const TimeSeparator = styled(textP1Regular)({});

export const ResetContainer = styled(TouchableOpacity)({
  padding: SpacingNumbers.xs,
});

export const HozizontalView = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
});

export const ScheduleInputErrorBlock = styled(ErrorBlock)({
  marginTop: SpacingNumbers.s,
  justifyContent: 'flex-start',
  marginLeft: 80,
});
