import React from 'react';
import {
  Indicator,
  IndicatorContainer,
  SuggestionContainer,
  SuggestionText,
  WarningContainer,
  WarningLevel,
  WarningText,
} from './PasswordValidation.style';
import { colorUsage } from '../../../../stylesheet';
import { Zxcvbn } from '../../AccountCreationScreen';

const colors: { [key: number]: string } = {
  0: colorUsage.passwordNone,
  1: colorUsage.passwordVeryWeak,
  2: colorUsage.passwordWeak,
  3: colorUsage.passwordMedium,
  4: colorUsage.passwordStrong,
  5: colorUsage.passwordVeryStrong,
};

const strengthLabels: { [key: number]: string } = {
  1: 'Très faible',
  2: 'Faible',
  3: 'Moyen',
  4: 'Fort',
  5: 'Très fort',
};

const indicatorIndexes = [0, 1, 2, 3, 4];

const getColor = (power: number, index: number): string => {
  if (power > index) {
    return colors[power];
  }
  return colors[0];
};

type Props = {
  indicator: Zxcvbn;
  isPassword: boolean;
};

const PasswordValidation = ({ indicator, isPassword }: Props): JSX.Element => {
  const indicatorPower = isPassword ? indicator.score + 1 : 0;

  return (
    <>
      <IndicatorContainer>
        {indicatorIndexes.map(indicatorIndex => (
          <Indicator
            key={indicatorIndex}
            color={getColor(indicatorPower, indicatorIndex)}
          />
        ))}
      </IndicatorContainer>
      {isPassword && (
        <SuggestionContainer>
          <WarningContainer>
            <WarningText>Niveau de sécurité : </WarningText>
            <WarningLevel color={colors[indicatorPower]}>
              {strengthLabels[indicatorPower]}
            </WarningLevel>
          </WarningContainer>
          <SuggestionText>
            {indicator.feedback.warning.length > 0 &&
              `${indicator.feedback.warning}\n`}
            {indicator.feedback.suggestions.map(
              suggestion => `   •   ${suggestion} \n`,
            )}
          </SuggestionText>
        </SuggestionContainer>
      )}
    </>
  );
};

export default PasswordValidation;
