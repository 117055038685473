import { groupBy } from 'lodash';
import { Announce, DAY, Schedule } from '../../redux/announce/types';

export const sortSchedules = (schedules: Schedule[]): Schedule[] => {
  return schedules.sort(
    (a, b) =>
      parseInt(
        a.startHour.split(':').reduce((prevVal, currVal) => prevVal + currVal),
      ) -
      parseInt(
        b.startHour.split(':').reduce((prevVal, currVal) => prevVal + currVal),
      ),
  );
};

type SortedScheduleAndDays = {
  day: keyof typeof DAY;
  schedules: Schedule[];
}[];

export const getGroupedSchedulesSortedByDays = (
  schedules: Schedule[],
): SortedScheduleAndDays => {
  const sortedSchedule = groupBy(schedules, schedule => schedule.day);
  const scheduleDays = Object.keys(sortedSchedule) as (keyof typeof DAY)[];
  const sortedScheduleDays = scheduleDays.sort(
    (a, b) => Object.keys(DAY).indexOf(a) - Object.keys(DAY).indexOf(b),
  );
  const groupedSchedulesSortedByDays = sortedScheduleDays.reduce<
    SortedScheduleAndDays
  >((prevValue, currValue) => {
    prevValue.push({
      day: currValue,
      schedules: sortSchedules(sortedSchedule[currValue]),
    });
    return prevValue;
  }, []);

  return groupedSchedulesSortedByDays;
};

export const getMinDateString = (dateStrings: string[]): Date => {
  const dateArray = dateStrings.map(dateString =>
    new Date(dateString).getTime(),
  );
  return new Date(Math.min.apply(null, dateArray));
};

export const getSubstitutionMinStartDate = (schedules: Schedule[]): Date => {
  const startDates = schedules
    .filter(schedule => Boolean(schedule.substitution))
    .map(schedule => schedule.substitution?.startDate as string);
  return getMinDateString(startDates);
};

export const getAnnounceStartDate = (announce: Announce): Date => {
  if (announce.schedule.length === 0) return new Date(announce.startDate);
  const hasSchedulesWithoutSubstitution = announce.schedule.some(
    schedule => !Boolean(schedule.substitution),
  );
  if (hasSchedulesWithoutSubstitution) return new Date(announce.startDate);
  return getSubstitutionMinStartDate(announce.schedule);
};
