import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CareGiverDocuments, CareGiver } from './types';

export type UsersState = Readonly<{
  careGiver: { me?: CareGiver; documents: CareGiverDocuments };
}>;

const initialState: UsersState = {
  careGiver: { me: undefined, documents: {} },
};

const usersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    updateMeAsCareGiver: (state, action: PayloadAction<CareGiver>) => {
      state.careGiver.me = action.payload;
    },
    updateCareGiverDocuments: (
      state,
      action: PayloadAction<CareGiverDocuments>,
    ) => {
      state.careGiver.documents = action.payload;
    },
  },
});

export const {
  updateMeAsCareGiver,
  updateCareGiverDocuments,
} = usersSlice.actions;
export default usersSlice.reducer;
