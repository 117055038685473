import styled from '@emotion/native';
import { View } from 'react-native';
import {
  BorderRadiusNumbers,
  colorUsage,
  SpacingNumbers,
} from '../../../../stylesheet';

export const StyledAutoCompleteContainer = styled(View)({
  marginBottom: SpacingNumbers.s,
});

export const InputContainer = styled(View)({
  flexDirection: 'row',
});

export const AutoCompleteAddressSeparator = styled.View({
  width: SpacingNumbers.l,
});

export const AddressesSuggestionsContainer = styled.ScrollView({
  backgroundColor: colorUsage.autoCompleteBackgroundColor,
  borderBottomRightRadius: BorderRadiusNumbers.s,
  borderBottomLeftRadius: BorderRadiusNumbers.s,
  paddingHorizontal: SpacingNumbers.l,
  paddingBottom: SpacingNumbers.m,
  paddingTop: SpacingNumbers.s,
});
