import styled from '@emotion/native';
import ChoiceButton from '../../../../components/atoms/ChoiceButton/ChoiceButton';
import { SpacingNumbers, textP2Bold } from '../../../../stylesheet';

export const ListContainer = styled.View({
  paddingHorizontal: SpacingNumbers.m,
  paddingBottom: SpacingNumbers.xl,
});
export const DayListContainer = styled.View({
  paddingBottom: SpacingNumbers.l,
});
export const DayTitle = styled(textP2Bold)({
  marginBottom: SpacingNumbers.s,
});
export const FlatListContainer = styled.View({
  marginHorizontal: -SpacingNumbers.s,
});

export const StyledChoiceButton = styled(ChoiceButton)({
  flexShrink: 1,
  paddingHorizontal: SpacingNumbers.s,
  marginBottom: SpacingNumbers.m,
  width: '48%',
});
