import styled from '@emotion/native';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import {
  colorUsage,
  SpacingNumbers,
  textP2Regular,
} from '../../../../stylesheet';

export const ContentContainer = styled.View({
  paddingHorizontal: SpacingNumbers.l,
});

export const ContentText = styled(textP2Regular)({
  textAlign: 'center',
  color: colorUsage.confirmationModalText,
});

export const ConfirmButton = styled(PrimaryButton)({
  marginTop: SpacingNumbers.xl,
  marginBottom: SpacingNumbers.s,
});

export const CancelButton = styled(PrimaryButton)({
  marginTop: SpacingNumbers.s,
  marginBottom: SpacingNumbers.xl,
});
