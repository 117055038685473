import styled from '@emotion/native';
import Input from '../../components/atoms/Input/Input';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage, SpacingNumbers, textP2Regular } from '../../stylesheet';

export const Container = styled.View({
  flex: 1,
  alignItems: 'center',
  backgroundColor: colorUsage.contentBackground,
  paddingHorizontal: SpacingNumbers.m,
});

export const EmailInput = styled(Input)({
  marginBottom: SpacingNumbers.l,
});

export const PasswordInput = styled(Input)({
  marginBottom: SpacingNumbers.s,
});

export const ErrorMessage = styled(textP2Regular)({
  color: colorUsage.loginErrorText,
  marginBottom: SpacingNumbers.xxl,
});

export const StyledButton = styled(PrimaryButton)({
  marginBottom: SpacingNumbers.xl,
});
