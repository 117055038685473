import React from 'react';
import { DAY, Schedule } from '../../../../redux/announce/types';
import { formatHour } from '../../../__Utils__/textFormatting';
import {
  AvailabilityContainer,
  AvailabilityTitle,
  DayScheduleContainer,
  DayText,
  ScheduleContainer,
  ScheduleText,
} from './Availability.style.native';

type Props = {
  firstName: string;
  groupedSchedulesSortedByDays: {
    day: keyof typeof DAY;
    schedules: Schedule[];
  }[];
};

const formatDay = (day: DAY) => {
  return day.substring(0, 3).toLowerCase();
};

const Availability = ({
  firstName,
  groupedSchedulesSortedByDays,
}: Props): JSX.Element => {
  return (
    <>
      <AvailabilityTitle>{firstName} est disponible :</AvailabilityTitle>
      <AvailabilityContainer>
        {groupedSchedulesSortedByDays.map(daySchedule => (
          <ScheduleContainer key={daySchedule.day}>
            <DayText>{formatDay(DAY[daySchedule.day])}.</DayText>
            <DayScheduleContainer>
              {daySchedule.schedules.map(schedule => (
                <ScheduleText key={schedule.id}>
                  {formatHour(schedule.startHour)}-
                  {formatHour(schedule.endHour)}
                </ScheduleText>
              ))}
            </DayScheduleContainer>
          </ScheduleContainer>
        ))}
      </AvailabilityContainer>
    </>
  );
};

export default Availability;
