import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { CameraCapturedPicture } from 'expo-camera';
import { Linking } from 'react-native';
import { useDispatch } from 'react-redux';
import { AsyncFnReturn } from 'react-use/lib/useAsyncFn';
import { updateMeAsCareGiver, updateCareGiverDocuments } from './slice';
import {
  fetchCareGiverDocuments,
  preDownloadDocument,
  preUploadDocument,
} from '../document/api';
import { Document } from '../document/types';
import { useTypedAsyncFn } from '../useTypedAsyncFn';
import { fetchCodeCheck, fetchMeAsCareGiver, updateCareGiver } from './api';
import {
  CareGiverCharacteristics,
  CareGiverDocuments,
  CAREGIVER_DOCUMENT_TYPE,
  CareGiver,
} from './types';

export const useUpdateCareGiver = (): AsyncFnReturn<(
  ...args: {
    careGiverCharacteristics: CareGiverCharacteristics;
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn(async ({ careGiverCharacteristics }) => {
    await updateCareGiver(careGiverCharacteristics);
  }, []);
};

export const useFetchCodeCheck = (): AsyncFnReturn<(
  ...args: {
    email: string;
    code: string;
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn<{ email: string; code: string }>(
    async ({ email, code }) => {
      await fetchCodeCheck(email, code);
    },
    [],
  );
};

export const useFetchMeAsCareGiver = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<Dispatch<PayloadAction<CareGiver>>>();

  return useTypedAsyncFn<Record<string, never>>(async () => {
    const me = await fetchMeAsCareGiver();
    dispatch(updateMeAsCareGiver(me));
  }, [dispatch]);
};

//istanbul ignore next
export const getBlob = async (fileUri: string): Promise<Blob> => {
  const resp = await fetch(fileUri);

  const imageBody = await resp.blob();
  return imageBody;
};

//istanbul ignore next
export const uploadImage = async (
  uploadUrl: string,
  data: string,
): Promise<Response> => {
  const imageBody = await getBlob(data);

  return fetch(uploadUrl, {
    method: 'PUT',
    body: imageBody,
  });
};

//istanbul ignore next
export const useUpdateCareGiverDocument = (): AsyncFnReturn<(
  ...args: {
    document: Document;
    photo: CameraCapturedPicture;
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn(async ({ document, photo }) => {
    const data = await preUploadDocument(document);

    await uploadImage(data.presignedUrl, photo.uri);
  }, []);
};

//istanbul ignore next
export const useDownloadCareGiverDocument = (): AsyncFnReturn<(
  ...args: {
    documentType: CAREGIVER_DOCUMENT_TYPE;
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn(async ({ documentType }) => {
    const data = await preDownloadDocument(documentType);

    Linking.openURL(data.presignedUrl);
  }, []);
};

export const useFetchCareGiverDocuments = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<Dispatch<PayloadAction<CareGiverDocuments>>>();
  return useTypedAsyncFn(async () => {
    const documentIds = await fetchCareGiverDocuments();
    dispatch(updateCareGiverDocuments(documentIds));
  }, [dispatch]);
};
