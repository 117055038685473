import React, { useState, useEffect, useMemo } from 'react';
import StepperScreen from '../../components/organism/StepperScreen/StepperScreen';
import { useRoute, useNavigation } from '../../navigation/types';
import { useFetchCodeCheck } from '../../redux/users/hook';
import CodeVerification from './components/CodeVerification/CodeVerification';
import {
  AuthentificationText,
  EmailText,
  TextContainer,
  TextWrongEmail,
  WrongEmailContainer,
  MainContainer,
} from './EmailVerificationScreen.style';

export default function EmailVerificationScreen(): JSX.Element {
  const {
    params: { email },
  } = useRoute<'EmailVerification'>();
  const { navigate, goBack } = useNavigation();
  const [emailCode, setEmailCode] = useState('');
  const [{ loading, error }, doFetchCodeCheck] = useFetchCodeCheck();

  const onEmailCodeChange = (code: string) => {
    setEmailCode(code);
  };

  useEffect(() => {
    if (emailCode.length === 6) {
      doFetchCodeCheck({ email, code: emailCode });
    }
  }, [doFetchCodeCheck, email, emailCode]);

  useEffect(() => {
    if (emailCode.length === 6 && loading === false && !error) {
      navigate('AccountCreationProfileChoice');
    }
  }, [error, loading, navigate]);

  const hasError = useMemo(() => {
    return emailCode.length === 6 && error !== undefined;
  }, [emailCode, error]);

  return (
    <MainContainer>
      <StepperScreen title={`2/3 - \nVérifions votre adresse mail.`}>
        <TextContainer>
          <AuthentificationText>
            Veuillez rentrer le code d’authentification à 6 chiffres que vous
            venez de recevoir au
          </AuthentificationText>
          <EmailText> {email}</EmailText>
        </TextContainer>
        <WrongEmailContainer onPress={goBack} testID="goBack">
          <TextWrongEmail>Ce n’est pas la bonne adresse mail ?</TextWrongEmail>
        </WrongEmailContainer>
        <CodeVerification
          hasError={hasError}
          emailCode={emailCode}
          onEmailCodeChange={onEmailCodeChange}
        ></CodeVerification>
      </StepperScreen>
    </MainContainer>
  );
}
