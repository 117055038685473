import styled from '@emotion/native';
import { TouchableOpacity } from 'react-native';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import {
  colorUsage,
  SpacingNumbers,
  textP2Bold,
  textP2Regular,
} from '../../stylesheet';

export const HourDeclarationDetailsContainer = styled.ScrollView({
  paddingHorizontal: SpacingNumbers.m,
  paddingTop: SpacingNumbers.xl,
  backgroundColor: colorUsage.contentBackground,
  height: '100%',
});

export const FAQText = styled(textP2Regular)({
  color: colorUsage.hourDeclarationDetailsFAQ,
  marginBottom: SpacingNumbers.s,
});
export const FAQLink = styled(textP2Bold)({
  color: colorUsage.link,
  textDecorationLine: 'underline',
  marginRight: SpacingNumbers.s,
});
export const FAQLinkContainer = styled(TouchableOpacity)({
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: SpacingNumbers.l,
});

export const AppointmentDateText = styled(textP2Bold)({
  marginBottom: SpacingNumbers.s,
  textTransform: 'capitalize',
});

export const DayDeclarationContainer = styled.View({
  marginBottom: SpacingNumbers.m,
});
export const ScheduleInputContainer = styled.View({
  marginBottom: SpacingNumbers.s,
});
export const ContentContainer = styled.View({ flexGrow: 1 });
export const StyledButton = styled(PrimaryButton)({
  marginVertical: SpacingNumbers.xl,
});
