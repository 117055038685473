/* istanbul ignore file */

import AsyncStorage from '@react-native-async-storage/async-storage';

const ACCESS_TOKEN_KEY_STORE = 'ACCESS_TOKEN';
const REFRESH_TOKEN_KEY_STORE = 'REFRESH_TOKEN';

export async function getAccessToken(): Promise<string | null> {
  return await AsyncStorage.getItem(ACCESS_TOKEN_KEY_STORE);
}

export async function getRefreshTokenCookie(): Promise<string | null> {
  return await AsyncStorage.getItem(REFRESH_TOKEN_KEY_STORE);
}

export async function storeAccessToken(accessToken: string): Promise<void> {
  return await AsyncStorage.setItem(ACCESS_TOKEN_KEY_STORE, accessToken);
}

export async function storeRefreshTokenCookie(
  refreshTokenCookie: string,
): Promise<void> {
  return await AsyncStorage.setItem(
    REFRESH_TOKEN_KEY_STORE,
    refreshTokenCookie,
  );
}

export async function clearStore(): Promise<void> {
  await Promise.all([
    AsyncStorage.removeItem(ACCESS_TOKEN_KEY_STORE),
    AsyncStorage.removeItem(REFRESH_TOKEN_KEY_STORE),
  ]);
}
