import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AsyncFnReturn } from 'react-use/lib/useAsyncFn';
import { useTypedAsyncFn } from '../useTypedAsyncFn';
import { fetchAppointments } from './api';
import { updateAppointments } from './slice';
import { FilteredAppointments } from './types';

export const APPOINTMENT_QUERY_KEY = 'appointment';

export const useFetchAppointments = (): AsyncFnReturn<(
  ...args: {
    date: Date;
  }[]
) => Promise<void>> => {
  const dispatch = useDispatch<Dispatch<PayloadAction<FilteredAppointments>>>();

  return useTypedAsyncFn(
    async ({ date }) => {
      const appointments = await fetchAppointments(date);
      const updatedAppointment = {
        appointments: appointments,
        dateFilter: date.toISOString(),
      };
      dispatch(updateAppointments(updatedAppointment));
    },
    [dispatch],
  );
};
