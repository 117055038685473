import { AntDesign } from '@expo/vector-icons';
import React, { useState } from 'react';
import HelpSection from '../../../../components/molecules/HelpSection/HelpSection';
import { MoneyInput } from '../../../../components/molecules/MoneyInput/MoneyInput';
import Switch from '../../../../components/molecules/Switch/Switch';
import { colorUsage } from '../../../../stylesheet';

import {
  ModalContentContainer,
  ModalTextContent,
} from '../../HourDeclarationDetailScreen.style';
import { ModalContentProps } from '../../utils';
import {
  BoldText,
  CesuContentContainer,
  TopMarginPrimaryButton,
} from './PaymentValidateHourModalContent.style';

const atLeastOneNonZeroCharacterRegex = /[1-9]/g;
const allPointsRegex = /[./]/g;

const PaymentValidateHourModalContent = ({
  onPressValidate,
}: ModalContentProps): JSX.Element => {
  const [isCesuSwitchOn, setIsCesuSwitchOn] = useState(false);
  const [cesuAmount, setCesuAmount] = useState('');
  return (
    <ModalContentContainer>
      <ModalTextContent>
        Une fois votre intervenant rémunéré, vous devrez déclarer le montant du
        salaire sur le site du CESU URSSAF...
      </ModalTextContent>
      <CesuContentContainer>
        <BoldText>Voulez-vous payer en tickets CESU ?</BoldText>
        <Switch
          onValueChange={setIsCesuSwitchOn}
          value={isCesuSwitchOn}
          testID="cesuAmountSwitch"
        />
      </CesuContentContainer>
      {isCesuSwitchOn && (
        <>
          <MoneyInput
            label="Montant souhaité en ticket CESU"
            value={cesuAmount}
            onChangeText={setCesuAmount}
            isRequired
            testID="MoneyInput"
          />
          <HelpSection
            link="https://aide.clickandcare.fr/fr/article/comment-serais-je-preleve-1exz94c/"
            title="Si vos tickets CESU ne couvrent pas la totalité du salaire, vous serez prélevé du reste à payer. Comment serez-vous prélevé ?"
          />
        </>
      )}
      <TopMarginPrimaryButton
        title="Valider le paiement"
        LeftIcon={
          <AntDesign name="check" size={18} color={colorUsage.buttonText} />
        }
        disabled={
          isCesuSwitchOn &&
          cesuAmount.search(atLeastOneNonZeroCharacterRegex) === -1
        }
        onPress={() =>
          onPressValidate(parseInt(cesuAmount.replace(allPointsRegex, '')))
        }
      />
    </ModalContentContainer>
  );
};

export default PaymentValidateHourModalContent;
