import styled from '@emotion/native';
import {
  SpacingNumbers,
  colorUsage,
  textP2Italic,
  textP2Bold,
  textP2Regular,
  BorderRadiusNumbers,
  interractiveCTAText,
  interactiveLink,
} from '../../../../stylesheet';

export const PHOTO_WIDTH = 128;
export const PHOTO_HEIGHT = 82;

export const MainContainer = styled.View({ marginBottom: SpacingNumbers.l });

export const ButtonContainer = styled.TouchableOpacity({
  backgroundColor: colorUsage.cameraButtonBackground,
  paddingVertical: SpacingNumbers.s,
  alignItems: 'center',
  borderWidth: 1,
  borderRadius: BorderRadiusNumbers.xs,
  marginTop: SpacingNumbers.s,
  borderColor: colorUsage.cameraButtonBorder,
});

export const Title = styled(textP2Regular)({
  color: colorUsage.cameraButtonDetails,
});

export const LinkContainer = styled.View({
  flexDirection: 'row',
});

export const PreviousPhotoText = styled(textP2Regular)({
  color: colorUsage.cameraButtonDetails,
  marginBottom: SpacingNumbers.s,
});

export const Required = styled(textP2Bold)({
  color: colorUsage.cameraButtonRequired,
});

export const Detail = styled(textP2Bold)({
  color: colorUsage.cameraButtonDetails,
});

export const Description = styled(textP2Italic)({
  color: colorUsage.cameraButtonDescription,
  marginTop: SpacingNumbers.s,
});

export const PhotoLink = styled(interactiveLink)({});

export const CameraText = styled(interractiveCTAText)({
  color: colorUsage.cameraButtonBorder,
  marginTop: SpacingNumbers.xs,
  textAlign: 'center',
});

export const CameraTextContainer = styled.View({
  width: 120,
});

export const ImageContainer = styled.View({
  width: PHOTO_WIDTH,
  height: PHOTO_HEIGHT,
  marginRight: SpacingNumbers.s,
});

export const Container = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: SpacingNumbers.s,
});

export const Link = styled.TouchableOpacity({
  flexDirection: 'row',
  marginLeft: SpacingNumbers.s,
  alignItems: 'center',
});
export const LinkText = styled(interactiveLink)({
  color: colorUsage.link,
});

export const StyledImage = styled.Image({
  flex: 1,
  flexGrow: 1,
});
