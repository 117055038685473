import { AntDesign } from '@expo/vector-icons';
import { RouteProp } from '@react-navigation/native';
import { Formik } from 'formik';
import { groupBy } from 'lodash';
import React, { useState } from 'react';
import { Linking } from 'react-native';
import CustomKeyboardAvoidingView from '../../components/molecules/KeyBoardAvoidingView/KeyboardAvoidingView';
import ModalPopin from '../../components/molecules/ModalPopin/ModalPopin';
import ScheduleInput, {
  ScheduleInputType,
} from '../../components/molecules/ScheduleInput/ScheduleInput';
import {
  AuthenticatedStackParamList,
  useNavigation,
} from '../../navigation/types';
import { selectHourDeclarations } from '../../redux/hourDeclaration';
import { usePostHourDeclarationDetails } from '../../redux/hourDeclaration/hooks';
import { HourDeclaration } from '../../redux/hourDeclaration/types';
import useSelector from '../../redux/useSelector';
import { colorUsage } from '../../stylesheet';
import { getGroupedDetailsSortedByAppointmentDate } from '../__Utils__/hourDeclarationProcessing';
import ConfirmationModalContent from './components/ConfirmationModalContent/ConfirmationModalContent';
import {
  AppointmentDateText,
  FAQLink,
  FAQLinkContainer,
  FAQText,
  HourDeclarationDetailsContainer,
  DayDeclarationContainer,
  ScheduleInputContainer,
  ContentContainer,
  StyledButton,
} from './EditableDeclarationHourDetailScreen.style';
import {
  getAppointmentDateText,
  getDetailFromFilledHourDetailsState,
} from './utils';
import { createValidationSchema } from './validationSchema';

type Props = {
  route: RouteProp<
    AuthenticatedStackParamList,
    'EditableDeclarationHourDetailScreen'
  >;
};

const EditableDeclarationHourDetailScreen = ({ route }: Props): JSX.Element => {
  const [, doPostHourDeclarationDetails] = usePostHourDeclarationDetails();
  const { goBack } = useNavigation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const hourDeclaration = useSelector(selectHourDeclarations).find(
    hourDeclaration => hourDeclaration.id === route.params.declarationId,
  ) as HourDeclaration;
  const hourDeclarationDetails = hourDeclaration.details;
  const groupedSortedHourDetails = getGroupedDetailsSortedByAppointmentDate(
    hourDeclarationDetails,
  );

  const initialHourDetails = hourDeclarationDetails.reduce<
    Record<string, ScheduleInputType & { appointmentDate: string }>
  >((previousValue, currentValue) => {
    previousValue[currentValue.id] = {
      appointmentDate: currentValue.appointmentDate,
      startHour: currentValue.startHour
        .substring(0, 2)
        .concat(currentValue.startHour.substring(3, 5)),

      endHour: currentValue.endHour
        .substring(0, 2)
        .concat(currentValue.endHour.substring(3, 5)),
    };
    return previousValue;
  }, {});

  const [filledHourDetails, setFilledHourDetails] = useState(
    initialHourDetails,
  );

  const onChangeSchedule = (schedule: ScheduleInputType, id: string): void => {
    setFilledHourDetails({
      ...filledHourDetails,
      [id]: {
        ...schedule,
        appointmentDate: filledHourDetails[id].appointmentDate,
      },
    });
  };

  const onPressValidate = async () => {
    const newHourDeclarationDetails = getDetailFromFilledHourDetailsState(
      filledHourDetails,
    );
    await doPostHourDeclarationDetails({
      id: route.params.declarationId,
      hourDeclarationDetails: newHourDeclarationDetails,
    });
    setIsConfirmationModalOpen(false);
    goBack();
  };

  return (
    <CustomKeyboardAvoidingView>
      <Formik
        initialValues={initialHourDetails}
        validationSchema={createValidationSchema(
          Object.keys(initialHourDetails),
        )}
        onSubmit={() => setIsConfirmationModalOpen(true)}
      >
        {({ setFieldValue, handleSubmit, values, errors, isValid }) => {
          return (
            <HourDeclarationDetailsContainer
              contentContainerStyle={{ flexGrow: 1 }}
            >
              <ContentContainer>
                <FAQText>
                  Vous travaillez 12h d’affilée (8h - 20h ou 20h - 8h) ? Votre
                  salaire sera calculé sur une base forfaitaire.
                </FAQText>
                <FAQLinkContainer
                  onPress={() =>
                    Linking.openURL(
                      'https://aide.clickandcare.fr/fr/article/comment-mon-salaire-est-calcule-1cvqh0a/?bust=1636035000394',
                    )
                  }
                >
                  <FAQLink>Comment c’est calculé ?</FAQLink>
                  <AntDesign
                    name="arrowright"
                    size={14}
                    color={colorUsage.link}
                  />
                </FAQLinkContainer>
                {Object.keys(groupedSortedHourDetails).map(appointmentDate => (
                  <DayDeclarationContainer key={appointmentDate}>
                    <AppointmentDateText>
                      {getAppointmentDateText(
                        appointmentDate,
                        groupBy(
                          getDetailFromFilledHourDetailsState(
                            filledHourDetails,
                          ),
                          detail => detail.appointmentDate,
                        )[appointmentDate],
                      )}
                    </AppointmentDateText>
                    {groupedSortedHourDetails[appointmentDate].map(detail => (
                      <ScheduleInputContainer key={detail.id}>
                        <ScheduleInput
                          schedule={values[detail.id]}
                          onChangeSchedule={(schedule: ScheduleInputType) => {
                            onChangeSchedule(schedule, detail.id);
                            setFieldValue(detail.id, schedule);
                          }}
                          hasError={!!errors[detail.id]}
                        />
                      </ScheduleInputContainer>
                    ))}
                  </DayDeclarationContainer>
                ))}
              </ContentContainer>
              <StyledButton
                title="Je valide les heures"
                onPress={handleSubmit}
                disabled={!isValid}
              />
              <ModalPopin
                title="Vous êtes sur le point de valider votre déclaration."
                onClose={() => setIsConfirmationModalOpen(false)}
                visible={isConfirmationModalOpen}
                content={
                  <ConfirmationModalContent
                    onPressConfirm={onPressValidate}
                    onPressCancel={() => setIsConfirmationModalOpen(false)}
                  />
                }
              />
            </HourDeclarationDetailsContainer>
          );
        }}
      </Formik>
    </CustomKeyboardAvoidingView>
  );
};
export default EditableDeclarationHourDetailScreen;
