import {
  Chapeau,
  Container,
  Title,
  TitleChapeauContainer,
} from './StepperScreen.style';
import React from 'react';

interface Props {
  title: string;
  chapeau?: string;
  children: React.ReactNode;
}

export default function StepperScreen({
  title,
  chapeau,
  children,
}: Props): JSX.Element {
  return (
    <Container showsVerticalScrollIndicator={false}>
      <TitleChapeauContainer>
        <Title>{title}</Title>
        {chapeau && <Chapeau>{chapeau}</Chapeau>}
      </TitleChapeauContainer>
      {children}
    </Container>
  );
}
