import { AntDesign } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import CustomKeyboardAvoidingView from '../../components/molecules/KeyBoardAvoidingView/KeyboardAvoidingView';
import { useNavigation } from '../../navigation/types';
import { selectMeAsCareGiver } from '../../redux/users';
import {
  useFetchMeAsCareGiver,
  useUpdateCareGiver,
} from '../../redux/users/hook';
import { colorUsage } from '../../stylesheet';
import {
  CandidateStatusContainer,
  BottomMarginNumberInput,
  EndPrimaryButton,
} from './CandidateStatus.style';

const CandidateStatus = (): JSX.Element => {
  const { goBack } = useNavigation();
  const [{}, doUpdateCareGiver] = useUpdateCareGiver();
  const [{}, doFetchCareGiver] = useFetchMeAsCareGiver();

  useEffect(() => {
    doFetchCareGiver();
  }, [doFetchCareGiver]);

  const careGiver = useSelector(selectMeAsCareGiver);

  const [nanCesu, setNanCesu] = useState(careGiver?.nanCesu);
  const [sapNova, setSapNova] = useState(careGiver?.sapNova);
  const [siret, setSiret] = useState(careGiver?.siret);

  useEffect(() => {
    if (careGiver !== undefined) {
      setNanCesu(careGiver.nanCesu);
      setSapNova(careGiver.sapNova);
      setSiret(careGiver.siret);
    }
  }, [careGiver]);

  const onPressSave = async () => {
    await doUpdateCareGiver({
      careGiverCharacteristics: {
        nanCesu: nanCesu,
        siret: siret,
        sapNova: sapNova,
      },
    });
    goBack();
  };

  return nanCesu !== undefined ? (
    <CustomKeyboardAvoidingView>
      <CandidateStatusContainer>
        <View>
          <BottomMarginNumberInput
            label="Code NAN CESU"
            onChangeText={setNanCesu}
            value={nanCesu}
          />
          <BottomMarginNumberInput
            label="Numéro de SIRET"
            onChangeText={setSiret}
            value={siret}
          />
          <BottomMarginNumberInput
            label="Numéro SAP NOVA"
            onChangeText={setSapNova}
            value={sapNova}
          />
        </View>

        <EndPrimaryButton
          title="Enregistrer"
          LeftIcon={
            <AntDesign name="check" size={18} color={colorUsage.buttonText} />
          }
          onPress={onPressSave}
        />
      </CandidateStatusContainer>
    </CustomKeyboardAvoidingView>
  ) : (
    <View></View>
  );
};

export default CandidateStatus;
