import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AsyncFnReturn } from 'react-use/lib/useAsyncFn';
import { useTypedAsyncFn } from '../useTypedAsyncFn';
import { fetchPaymentInfos } from './api';
import { updatePaymentInfos } from './slice';
import { PaymentInfos } from './types';

export const useFetchPaymentInfos = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<
    Dispatch<PayloadAction<PaymentInfos | undefined>>
  >();

  return useTypedAsyncFn(async () => {
    const paymentInfos = await fetchPaymentInfos();

    dispatch(updatePaymentInfos(paymentInfos));
  }, [dispatch]);
};
