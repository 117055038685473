import { Feather } from '@expo/vector-icons';
import React, { useState } from 'react';
import { StyleProp, TextInputProps, ViewStyle } from 'react-native';
import { colorUsage } from '../../../stylesheet';
import {
  InputContainer,
  StyledTimeInput,
  TextInputContainer,
} from './TimeInput.style';

export type Time = {
  hour: string;
  minute: string;
};

type HourInputProps = {
  time: string;
  onChangeTime: (time: string) => void;
  style?: StyleProp<ViewStyle>;
} & Omit<TextInputProps, 'style' | 'onFocus' | 'onEndEditing'>;

export const formatHour = (time: string): string => {
  if (time.length < 2) return time;
  return `${time.substring(0, 2)}h${time.substring(2)}`;
};

export const TimeInput = ({
  style,
  time,
  onChangeTime,
  ...props
}: HourInputProps): JSX.Element => {
  const [isFocus, setIsFocus] = useState(false);
  const endEditingFormat = (time: string): void => {
    onChangeTime(`${time}${'0'.repeat(4 - time.length)}`);
  };

  const onChangeText = (text: string) => {
    if (time.length === 2 && text.length === 2) {
      onChangeTime(time[0]);
    } else {
      onChangeTime(text.replace('h', ''));
    }
  };

  return (
    <InputContainer isFocus={isFocus} style={style}>
      <Feather name="clock" size={13} color={colorUsage.hourDeclarationIcon} />
      <TextInputContainer>
        <StyledTimeInput
          testID="TimeInput"
          onFocus={() => {
            setIsFocus(true);
          }}
          value={formatHour(time)}
          onBlur={() => {
            setIsFocus(false);
            endEditingFormat(time);
          }}
          {...props}
          keyboardType="number-pad"
          maxLength={5}
          onChangeText={onChangeText}
        />
      </TextInputContainer>
    </InputContainer>
  );
};
