import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AsyncFnReturn } from 'react-use/lib/useAsyncFn';
import { useTypedAsyncFn } from '../useTypedAsyncFn';
import {
  createApplication,
  fetchApplications,
  fetchAnnounceById,
  getAnnounceByFamily,
  searchAnnouncesByCriteria,
  fetchProposedAnnounces,
  validateApplicationById,
} from './api';
import {
  updateAnnounces,
  updateAnnounceWithDetails,
  updateApplications,
  updateProposedAnnounces,
} from './slice';
import {
  Announce,
  Application,
  FamilyAnnounce,
  FamilyAnnounceWithDetails,
  InstitutionAnnounce,
  InstitutionAnnounceWithDetails,
} from './types';

export const MISSION_QUERY_KEY = 'announce';

export type CurrentAnnounceQuery = {
  currentAnnounce: Announce[];
};

export const useSearchAnnounceByCriteria = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<
    Dispatch<PayloadAction<(InstitutionAnnounce | FamilyAnnounce)[]>>
  >();

  return useTypedAsyncFn(async () => {
    const announces = await searchAnnouncesByCriteria();
    dispatch(updateAnnounces(announces));
  }, [dispatch]);
};

export const useGetAnnounceByFamily = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<
    Dispatch<
      PayloadAction<InstitutionAnnounceWithDetails | FamilyAnnounceWithDetails>
    >
  >();

  return useTypedAsyncFn(async () => {
    const announce = await getAnnounceByFamily();
    if (!announce) return;
    dispatch(updateAnnounceWithDetails(announce));
  }, [dispatch]);
};

export const useGetAnnounceById = (): AsyncFnReturn<(
  ...args: {
    id: string;
  }[]
) => Promise<void>> => {
  const dispatch = useDispatch<
    Dispatch<
      PayloadAction<FamilyAnnounceWithDetails | InstitutionAnnounceWithDetails>
    >
  >();

  return useTypedAsyncFn(
    async ({ id }) => {
      const announceWithDetails = await fetchAnnounceById(id);
      dispatch(updateAnnounceWithDetails(announceWithDetails));
    },
    [dispatch],
  );
};

export const useCreateApplication = (): AsyncFnReturn<(
  ...args: {
    id: string;
    scheduleIds: string[];
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn<{ id: string; scheduleIds: string[] }>(
    async ({ id, scheduleIds }) => {
      await createApplication(id, scheduleIds);
    },
    [],
  );
};

export const useFetchApplications = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<Dispatch<PayloadAction<Application[]>>>();

  return useTypedAsyncFn(async () => {
    const applications = await fetchApplications();
    dispatch(updateApplications(applications));
  }, [dispatch]);
};

export const useFetchProposedAnnounces = (): AsyncFnReturn<(
  ...args: Record<string, never>[]
) => Promise<void>> => {
  const dispatch = useDispatch<
    Dispatch<PayloadAction<(InstitutionAnnounce | FamilyAnnounce)[]>>
  >();

  return useTypedAsyncFn(async () => {
    const proposedAnnounces = await fetchProposedAnnounces();

    dispatch(updateProposedAnnounces(proposedAnnounces));
  }, [dispatch]);
};

export const useValidateApplicationById = (): AsyncFnReturn<(
  ...args: {
    id: string;
  }[]
) => Promise<void>> => {
  return useTypedAsyncFn(async ({ id }) => {
    await validateApplicationById(id);
  }, []);
};
