import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage } from '../../../../stylesheet';

import {
  ModalContentContainer,
  ModalTextContent,
} from '../../HourDeclarationDetailScreen.style';
import { ModalContentProps } from '../../utils';

type Props = { type: 'DEFAULT' | 'LIBERAL' };

const DefaultValidateHourModalContent = ({
  onPressValidate,
  type,
}: ModalContentProps & Props): JSX.Element => {
  const textContent =
    type === 'DEFAULT'
      ? 'Chaque fin de mois, vous devrez rémunérer votre intervenant et déclarer le montant du salaire sur le site du CESU URSSAF.'
      : 'Vous avez opté pour le prélèvement bancaire. En validant les heures, vous autorisez le paiement automatique de cette semaine à votre intervenant.';
  const buttonTitle =
    type === 'DEFAULT' ? 'Valider les heures' : 'Valider le paiement';
  return (
    <ModalContentContainer>
      <ModalTextContent>{textContent}</ModalTextContent>
      <PrimaryButton
        title={buttonTitle}
        LeftIcon={
          <AntDesign name="check" size={18} color={colorUsage.buttonText} />
        }
        onPress={onPressValidate}
      />
    </ModalContentContainer>
  );
};

export default DefaultValidateHourModalContent;
