import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  BoxShadow,
  colorUsage,
  interractiveCTAText,
  SpacingNumbers,
  titleH3,
} from '../../../stylesheet';
import LinearGradient from '../LinearGradient/LinearGradient';

export const Container = styled.TouchableOpacity<{ fullWidth?: boolean }>(
  props => ({
    ...(props.fullWidth
      ? { height: 64 }
      : {
          height: 45,
          borderRadius: BorderRadiusNumbers.s,
          ...BoxShadow.shadowMain,
          shadowOpacity: BoxShadow.shadowMainOpacity,
        }),
    width: '100%',
  }),
);

export const BorderRadiusCut = styled.View<{
  fullWidth?: boolean;
  borderColor?: string;
}>(props => ({
  ...(!props.fullWidth && {
    borderRadius: BorderRadiusNumbers.s,
    overflow: 'hidden',
  }),
  ...(props.borderColor && {
    borderWidth: 1,
    borderColor: props.borderColor,
  }),
}));

export const StyledLinearGradient = styled(LinearGradient)({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: SpacingNumbers.m,
});

export const InterractiveContent = styled(interractiveCTAText)({
  color: colorUsage.buttonText,
  marginLeft: SpacingNumbers.s,
});

export const TitleContent = styled(titleH3)<{
  color: string;
}>(props => ({
  color: props.color,
  marginLeft: SpacingNumbers.s,
  marginRight: SpacingNumbers.s,
}));
