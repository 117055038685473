import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { colorUsage } from '../../../stylesheet';
import Button, { ButtonProps } from '../Button/Button';
import { Color } from '../LinearGradient/LinearGradient';

type ChoiceAdditionalProps = {
  isPressed?: boolean;
  greyUnpressedBackground?: boolean;
};

type ChoiceProps = Omit<
  ChoiceAdditionalProps & ButtonProps,
  'textColor' | 'backgroundColor'
>;

export const getBackgroundColor = (
  isPressed?: boolean,
  greyUnpressedBackground?: boolean,
): Color => {
  if (isPressed) return 'lightBlueFlat';
  if (greyUnpressedBackground) return 'greyPale';
  return 'white';
};

const ChoiceButton = ({
  isPressed,
  greyUnpressedBackground,
  ...props
}: ChoiceProps): JSX.Element => {
  return (
    <Button
      {...props}
      backgroundColor={getBackgroundColor(isPressed, greyUnpressedBackground)}
      RightIcon={
        isPressed ? (
          <AntDesign
            name="checkcircle"
            size={14}
            color={colorUsage.choiceButtonCheckIconColor}
          />
        ) : (
          undefined
        )
      }
      textColor={colorUsage.choiceButtonTextColor}
    />
  );
};

export default ChoiceButton;
