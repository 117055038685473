import styled from '@emotion/native';
import {
  SpacingNumbers,
  colorUsage,
  textP2RegularStyle,
} from '../../../../stylesheet';

export const AddressSuggestionContainer = styled.TouchableOpacity({
  marginTop: SpacingNumbers.m,
});

export const AddressSuggestionText = styled.Text({
  color: colorUsage.labelText,
  ...textP2RegularStyle,
});
