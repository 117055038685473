import { TouchableOpacity } from 'react-native';
import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  colorUsage,
  interactiveLink,
  SpacingNumbers,
  textP2Bold,
} from '../../../stylesheet';
import BaseUnderline from '../../atoms/Underline/Underline';

export const HelpSectionContainer = styled.View({
  backgroundColor: colorUsage.helpSectionBackground,
  borderRadius: BorderRadiusNumbers.s,
  paddingVertical: SpacingNumbers.m,
  paddingHorizontal: SpacingNumbers.s,
  marginVertical: SpacingNumbers.s,
  flexDirection: 'row',
});

export const HelpSectionTextContainer = styled.View({
  paddingHorizontal: SpacingNumbers.s,
  flexShrink: 1,
});

export const HelpSectionTitle = styled(textP2Bold)({
  color: colorUsage.helpSectionText,
});

export const FAQLinkContainer = styled(TouchableOpacity)({
  marginTop: SpacingNumbers.xs,
  flexDirection: 'row',
});

export const Underline = styled(BaseUnderline)({
  marginRight: SpacingNumbers.xs,
});

export const FAQLinkText = styled(interactiveLink)({
  textDecorationLine: 'none',
});
