/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer } from '@react-navigation/native';
import React from 'react';
import { Text } from 'react-native';
import { useAuth } from '../services/auth/context';
import { AuthenticatedNavigator } from './AuthenticatedNavigator';
import { linking } from './linking';
import { UnauthenticatedNavigator } from './UnauthenticatedNavigator';

const Navigation = (): JSX.Element => {
  const { isAuthenticated } = useAuth();

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading…</Text>}>
      {isAuthenticated ? (
        <AuthenticatedNavigator />
      ) : (
        <UnauthenticatedNavigator />
      )}
    </NavigationContainer>
  );
};

export default Navigation;
