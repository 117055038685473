/* istanbul ignore file */

import jwt_decode from 'jwt-decode';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import client, { UserTokenInfo } from './api';
import { getAccessToken } from './store';

export const applyAuthTokenInterceptor = (axios: AxiosInstance): void => {
  axios.interceptors.request.use(authTokenInterceptor());
};

function tokenHasExpired(token: UserTokenInfo): boolean {
  if (!token.exp) return true;

  // Less than 10 seconds remaining => token has expired
  const now = new Date().getTime() / 1000;
  return token.exp - now < 10;
}

export const authTokenInterceptor = () => async (
  requestConfig: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  // We need access token to do any authenticated requests
  const accessToken = await getAccessToken();

  if (!accessToken || tokenHasExpired(jwt_decode<UserTokenInfo>(accessToken))) {
    try {
      await client.refreshToken();
    } catch (e) {
      // Token was invalid, logging out the user.
      client.logout();
    }
  }

  // add token to headers
  if (accessToken)
    requestConfig.headers['Authorization'] = `Bearer ${accessToken}`;

  return requestConfig;
};
