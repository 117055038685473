import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  BoxShadow,
  colorUsage,
  SpacingNumbers,
  textP1Bold,
  textP2Regular,
} from '../../../../stylesheet';

export const CardContainer = styled.View({
  alignItems: 'flex-start',
  backgroundColor: colorUsage.missionCardBackgroundColor,
  borderRadius: BorderRadiusNumbers.s,
  paddingTop: SpacingNumbers.l,
  paddingBottom: SpacingNumbers.s,
  paddingHorizontal: SpacingNumbers.m,
  marginBottom: SpacingNumbers.m,
  ...BoxShadow.shadowMain,
  shadowOpacity: BoxShadow.shadowMainOpacity,
});

export const MissionTitle = styled(textP1Bold)({
  color: colorUsage.missionCardTitle,
  marginBottom: SpacingNumbers.m,
  textAlign: 'left',
});

export const SubTitleContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: SpacingNumbers.m,
});

export const StyledText = styled(textP2Regular)({
  color: colorUsage.missionCardTextContent,
  marginLeft: SpacingNumbers.s,
  marginRight: SpacingNumbers.m,
});

export const IconTextContainer = styled.View({
  marginBottom: SpacingNumbers.m,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
});
