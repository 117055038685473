import styled from '@emotion/native';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import Underline from '../../../../components/atoms/Underline/Underline';
import {
  BorderRadiusNumbers,
  BoxShadow,
  colorUsage,
  SpacingNumbers,
  textP1Bold,
  textP2Regular,
} from '../../../../stylesheet';

export const ApplicationCardContainer = styled.View({
  backgroundColor: colorUsage.applicationCardBackgroundColor,
  paddingHorizontal: SpacingNumbers.m,
  paddingVertical: SpacingNumbers.l,
  borderRadius: BorderRadiusNumbers.s,
  ...BoxShadow.shadowMain,
  elevation: BoxShadow.shadowMainElevation,
  shadowOpacity: BoxShadow.shadowMainOpacity,
  marginBottom: SpacingNumbers.m,
});

export const HeaderContainer = styled.View({
  flexDirection: 'row',
  paddingBottom: SpacingNumbers.m,
  borderBottomWidth: 1,
  borderBottomColor: colorUsage.separatorLineColor,
});

export const HeaderInfosContainer = styled.View({
  marginLeft: SpacingNumbers.m,
});

export const GreetingsText = styled(textP2Regular)({
  color: colorUsage.greetingsTextColor,
});
export const FirstName = styled(textP1Bold)({
  textAlign: 'left',
  color: colorUsage.firstNameTextColor,
});
export const Certification = styled(textP2Regular)({
  color: colorUsage.certificationTextColor,
});

export const PhoneContainer = styled.View({
  flexDirection: 'row',
  paddingTop: SpacingNumbers.m,
  alignItems: 'center',
});
export const Phone = styled(textP2Regular)({
  color: colorUsage.phoneNumberTextColor,
});
export const UnderLinedText = styled(Underline)({
  marginLeft: SpacingNumbers.s,
  borderBottomColor: colorUsage.phoneNumberTextColor,
});

export const BodyContainer = styled.View({
  paddingBottom: SpacingNumbers.s,
  borderBottomWidth: 1,
  borderBottomColor: colorUsage.separatorLineColor,
});

export const StyledPrimaryButton = styled(PrimaryButton)({
  marginTop: SpacingNumbers.m,
});
