import { TouchableOpacity } from 'react-native';
import styled from '@emotion/native';
import { colorUsage, SpacingNumbers, titleH2, titleH3 } from '../../stylesheet';

export const DateContainer = styled(TouchableOpacity)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const StyledTitle = styled(titleH2)({
  marginLeft: SpacingNumbers.m,
  color: colorUsage.headerTitle,
});

export const StyledDate = styled(StyledTitle)({
  marginRight: SpacingNumbers.s,
  color: colorUsage.headerTouchableTitle,
});

export const CalendarHeader = styled(titleH3)`
  color: ${colorUsage.calendarHeaderTitle};
`;
