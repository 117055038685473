import styled from '@emotion/native';
import { colorUsage, SpacingNumbers, textP1Bold } from '../../../stylesheet';

export const Container = styled.View({
  width: '100%',
  height: '100px',
  paddingHorizontal: SpacingNumbers.xxl,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const User = styled(textP1Bold)`
  color: ${colorUsage.headerTitle};
`;
