import { isFeatureTogglingActivated } from './../../screens/__Utils__/featureToggling';
import { authenticatedApiClient } from '../../services/networking/authenticatedApiClient';

import {
  FamilyAnnounce,
  InstitutionAnnounce,
  Application,
  FamilyAnnounceWithDetails,
  InstitutionAnnounceWithDetails,
  ANNOUNCE_TYPES,
} from './types';

export async function searchAnnouncesByCriteria(): Promise<
  (FamilyAnnounce | InstitutionAnnounce)[]
> {
  const url = `/announces/search-with-criteria`;
  const { data: announces } = await authenticatedApiClient.get<
    (FamilyAnnounce | InstitutionAnnounce)[]
  >(url);

  if (isFeatureTogglingActivated()) return announces;

  return announces.filter(
    announce => announce.announceType === ANNOUNCE_TYPES.FAMILY,
  );
}

export async function getAnnounceByFamily(): Promise<
  FamilyAnnounceWithDetails | InstitutionAnnounceWithDetails | undefined
> {
  const url = `/announces`;
  const { data } = await authenticatedApiClient.get<
    FamilyAnnounceWithDetails | InstitutionAnnounceWithDetails | undefined
  >(url);
  return data;
}

export async function fetchAnnounceById(
  id: string,
): Promise<FamilyAnnounceWithDetails | InstitutionAnnounceWithDetails> {
  const url = `/announces/${id}`;
  const { data } = await authenticatedApiClient.get<
    FamilyAnnounceWithDetails | InstitutionAnnounceWithDetails
  >(url);
  return data;
}

export async function createApplication(
  id: string,
  scheduleIds: string[],
): Promise<Application> {
  const url = `/applications`;
  const { data } = await authenticatedApiClient.post<Application>(url, {
    scheduleIds: scheduleIds,
    announceId: id,
  });
  return data;
}

export async function fetchApplications(): Promise<Application[]> {
  const url = `/applications`;
  const { data: applications } = await authenticatedApiClient.get<
    Application[]
  >(url);

  if (isFeatureTogglingActivated()) return applications;

  return applications.filter(
    application => application.announce.announceType === ANNOUNCE_TYPES.FAMILY,
  );
}

export async function fetchProposedAnnounces(): Promise<
  (FamilyAnnounce | InstitutionAnnounce)[]
> {
  const url = `/announces/proposed`;
  const { data: proposedAnnounces } = await authenticatedApiClient.get<
    (FamilyAnnounce | InstitutionAnnounce)[]
  >(url);

  if (isFeatureTogglingActivated()) return proposedAnnounces;

  return proposedAnnounces.filter(
    proposedAnnounce => proposedAnnounce.announceType === ANNOUNCE_TYPES.FAMILY,
  );
}

export async function validateApplicationById(id: string): Promise<void> {
  const url = `/applications/${id}/validate`;
  await authenticatedApiClient.post<void>(url);
}
