import StepperScreen from '../../components/organism/StepperScreen/StepperScreen';
import React from 'react';
import UserTypeCard from '../../components/molecules/UserTypeCard/UserTypeCard';
import { MainContainer } from './AccountCreationProfileChoice.style.native';
import useSelector from '../../redux/useSelector';
import { useAuth } from '../../services/auth/context';
import { USER_TYPES } from '../../redux/users/types';
import { selectCredential } from '../../redux/signUp';

const careGiverTitle = '💼 Un emploi à domicile ou en établissement';
const careGiverDescriptions =
  'Click&Care vous aide à trouver des missions bien rémunérées, proches de chez vous et adaptées à votre emploi du temps.';
const careGiverButtonTitle = 'Démarrer';

const famillyTitle = '🏠 Une aide à domicile';
const famillyDescriptions =
  'Créé par une équipe médicale, Click&Care propose un service d’aide à domicile rapide, sur mesure et au tarif le plus juste sur toute la France.';
const familyButtonTitle = 'Trouver un intervenant';

export default function AccountCreationProfileChoiceScreen(): JSX.Element {
  const { email, password } = useSelector(selectCredential);
  const { signUp } = useAuth();
  return (
    <MainContainer>
      <StepperScreen title={`3/3 - \nQue recherchez-vous ?`}>
        <UserTypeCard
          title={careGiverTitle}
          description={careGiverDescriptions}
          buttonTitle={careGiverButtonTitle}
          color="blueFlat"
          onPress={() => {
            signUp(email as string, password as string, USER_TYPES.CARE_GIVER);
          }}
        />

        <UserTypeCard
          title={famillyTitle}
          description={famillyDescriptions}
          buttonTitle={familyButtonTitle}
          color="pink"
          onPress={() => {
            signUp(email as string, password as string, USER_TYPES.FAMILY);
          }}
        />
      </StepperScreen>
    </MainContainer>
  );
}
