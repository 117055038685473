import React, { useCallback } from 'react';
import { Dictionary, groupBy } from 'lodash';
import { selectHourDeclarations } from '../../redux/hourDeclaration';
import { useFetchHourDeclaration } from '../../redux/hourDeclaration/hooks';
import useSelector from '../../redux/useSelector';
import { format, subDays } from 'date-fns';
import { useFocusEffect } from '@react-navigation/native';
import { HourDeclaration } from '../../redux/hourDeclaration/types';
import HourDeclarationTemplate from '../../components/template/HourDeclarationTemplate/HourDeclarationTemplate';
import { ListRenderItemInfo } from 'react-native';
import HourDeclarationCard from '../../components/organism/hourDeclarationCard/hourDeclarationCard';
import { formatDateRange } from '../__Utils__/textFormatting';
import MonthlyDeclarationCard from '../../components/organism/MonthlyDeclarationCard/MonthlyDeclarationCard';
import { useNavigation } from '../../navigation/types';

export default function ValidateHoursScreen(): JSX.Element {
  const { navigate } = useNavigation();
  const renderValidatedDeclaration = ({
    item,
  }: ListRenderItemInfo<HourDeclaration[] | HourDeclaration>) => (
    <MonthlyDeclarationCard
      monthlyDeclaration={item as HourDeclaration[]}
      type="FAMILY"
    />
  );

  const renderToValidateDeclaration = ({
    item,
  }: ListRenderItemInfo<HourDeclaration | HourDeclaration[]>) => {
    const hourDeclaration = item as HourDeclaration;
    return (
      <HourDeclarationCard
        hourDeclaration={hourDeclaration}
        type="FAMILY"
        onPress={() => {
          navigate('HourDeclarationDetailScreen', {
            dateRange: formatDateRange(
              new Date(hourDeclaration.startDate),
              subDays(new Date(hourDeclaration.endDate), 1),
            ),
            hourDeclaration: hourDeclaration,
            hourDeclarationCanBeValidated: true,
          });
        }}
      />
    );
  };

  const [, doFetchHourDeclarations] = useFetchHourDeclaration();

  useFocusEffect(
    useCallback(() => {
      doFetchHourDeclarations();
    }, [doFetchHourDeclarations]),
  );

  const hourDeclarations = useSelector(selectHourDeclarations);
  const sortedHourDeclarations = [...hourDeclarations].sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
  );
  const toValidateDeclarations = sortedHourDeclarations.filter(
    declaration => declaration.status === 'to-validate-by-family',
  );
  const validatedDeclarations = sortedHourDeclarations.filter(
    declaration =>
      !['to-validate-by-family', 'to-validate-by-caregiver'].includes(
        declaration.status,
      ),
  );

  const validatedAndSortedDeclarations = Object.values(
    groupBy(validatedDeclarations, declaration => [
      format(new Date(declaration.startDate), 'yyyy-MM'),
    ]) as Dictionary<HourDeclaration[]>,
  );

  return (
    <HourDeclarationTemplate
      validatedAndSortedDeclarations={validatedAndSortedDeclarations}
      toValidateDeclarations={toValidateDeclarations}
      renderToValidateDeclaration={renderToValidateDeclaration}
      renderValidatedDeclaration={renderValidatedDeclaration}
    />
  );
}
