import React from 'react';
import {
  Application,
  FamilyAnnounce,
  LEAD_STATUS,
} from '../../../../redux/announce/types';
import AnnounceCard from '../../../FindMission/components/AnnounceCard/AnnounceCard';
import { STATUS } from '../../../../redux/announce/types';
import { CareGiver } from '../../../../redux/users/types';
import { CardContainer } from '../../../FindMission/components/AnnounceCard/AnnounceCard.style';
import { InfoBlock } from '../../../FindMission/components/InfoBlock/InfoBlock';

interface Props {
  applications: Application[];
  announce: FamilyAnnounce;
  careGiver?: CareGiver;
}

const stepText: { [status: number]: string } = {
  1: "Nous avons bien reçu votre besoin d'aide à domicile. Vous serez recontacté dans les plus brefs délais.",
  2: "Suite à l'envoi du devis, nous sommes en attente de votre bon pour accord pour le démarrage des prestations.",
  3: 'Nous mettons tout en œuvre pour trouver le candidat au plus proche de votre besoin',
  4: 'Rencontrez le candidat proposé par Click&Care. Une fois confirmé, les prestations pourront démarrer.',
  5: 'Le candidat rencontré a bien été confirmé. Les prestations peuvent démarrer !',
};

const getStepNumber = (
  announce: FamilyAnnounce,
  applications: Application[],
) => {
  if (announce.status === STATUS.DRAFT) {
    if (
      announce.lead?.status === LEAD_STATUS.NOT_CONTACTED ||
      announce.lead?.status === LEAD_STATUS.CONTACTED
    ) {
      return 1;
    }
    return 2;
  }
  if (announce.status === STATUS.SEARCH) {
    if (applications.length === 0) {
      return 3;
    }
    return 4;
  }
  return 5;
};

export const FamilyAnnounceCard = ({
  applications,
  announce,
  careGiver,
}: Props): JSX.Element => {
  const stepNumber = getStepNumber(announce, applications);
  const boldText = `Étape ${stepNumber}/5 :`;
  return (
    <CardContainer>
      <AnnounceCard announce={announce} careGiver={careGiver} hideTooltip />
      <InfoBlock boldText={boldText} text={stepText[stepNumber]} />
    </CardContainer>
  );
};
