import React, { ReactElement } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Color } from '../LinearGradient/LinearGradient';
import {
  BorderRadiusCut,
  Container,
  InterractiveContent,
  StyledLinearGradient,
  TitleContent,
} from './Button.style';

export type ButtonProps = {
  title: string;
  onPress: () => void;
  fullWidth?: boolean;
  /* fullWidth props differentiates buttons with width: 100%
  and buttons with finite width and border radius but same structure */
  style?: StyleProp<ViewStyle>;
  backgroundColor: Color;
  textColor: string;
  borderColor?: string;
  LeftIcon?: ReactElement;
  RightIcon?: ReactElement;
  disabled?: boolean;
};

const Button = ({
  title,
  onPress,
  fullWidth,
  style,
  backgroundColor,
  textColor,
  borderColor,
  LeftIcon,
  RightIcon,
  disabled,
}: ButtonProps): JSX.Element => {
  return (
    <Container
      disabled={disabled}
      onPress={onPress}
      fullWidth={fullWidth}
      style={style}
    >
      <BorderRadiusCut fullWidth={fullWidth} borderColor={borderColor}>
        <StyledLinearGradient color={backgroundColor}>
          {LeftIcon}
          {fullWidth ? (
            <InterractiveContent testID="Button_InterractiveContent">
              {title}
            </InterractiveContent>
          ) : (
            <TitleContent color={textColor} testID="Button_TitleContent">
              {title}
            </TitleContent>
          )}
          {RightIcon}
        </StyledLinearGradient>
      </BorderRadiusCut>
    </Container>
  );
};

export default Button;
