import { RootState } from '../types';
import {
  Application,
  FamilyAnnounce,
  InstitutionAnnounce,
  FamilyAnnounceWithDetails,
  InstitutionAnnounceWithDetails,
} from './types';

export const selectAnnounces = (
  store: RootState,
): (FamilyAnnounce | InstitutionAnnounce)[] => store.announce.announces;

export const selectAnnounceWithDetails = (
  store: RootState,
): FamilyAnnounceWithDetails | InstitutionAnnounceWithDetails | undefined =>
  store.announce.announceWithDetails;

export const selectApplications = (store: RootState): Application[] =>
  store.announce.applications;

export const selectProposedAnnounces = (
  store: RootState,
): (FamilyAnnounce | InstitutionAnnounce)[] => store.announce.proposedAnnounces;
