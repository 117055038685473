/* istanbul ignore file */

import { apiAddress } from '../networking/apiAddress';
import { AddressesSuggestionsCollection, AddressSuggestion } from './types';

export class AddressesClient {
  async search(query: string): Promise<AddressSuggestion[]> {
    const {
      data: addressesSuggestionsCollection,
    }: { data: AddressesSuggestionsCollection } = await apiAddress.get(
      `search/?q=${query}`,
    );
    return addressesSuggestionsCollection.features;
  }
}

const addressesClient = new AddressesClient();
export default addressesClient;
