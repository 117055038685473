/* istanbul ignore file */

/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';

import { reducer as appointment } from './appointment';
import { reducer as announce } from './announce';
import { reducer as users } from './users';
import { reducer as signUp } from './signUp';
import { reducer as hourDeclaration } from './hourDeclaration';
import { reducer as paymentInfos } from './paymentInfos';
import { RootState } from './types';

export default combineReducers<RootState>({
  appointment,
  announce,
  users,
  signUp,
  hourDeclaration,
  paymentInfos,
});
