import { RootState } from '../types';
import { CareGiver, CareGiverDocuments } from './types';

export const selectMeAsCareGiver = (store: RootState): CareGiver | undefined =>
  store.users.careGiver.me;

export const selectCareGiverDocuments = (
  store: RootState,
): CareGiverDocuments => {
  return store.users.careGiver.documents;
};
