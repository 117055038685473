import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  BoxShadow,
  colorUsage,
  SpacingNumbers,
  textP2Regular,
  titleH3,
} from '../../../stylesheet';

export const Container = styled.View({
  backgroundColor: colorUsage.userTypeCardBackground,
  width: '100%',
  borderRadius: BorderRadiusNumbers.s,
  paddingHorizontal: SpacingNumbers.m,
  paddingVertical: SpacingNumbers.l,
  ...BoxShadow.shadowMain,
  shadowOpacity: BoxShadow.shadowMainOpacity,
  marginTop: SpacingNumbers.l,
});

export const Title = styled(titleH3)({
  marginBottom: SpacingNumbers.m,
});

export const DescriptionText = styled(textP2Regular)({
  color: colorUsage.bodyText,
  flexShrink: 1,
});

export const BulletText = styled(textP2Regular)({
  color: colorUsage.bodyText,
});

export const ButtonContainer = styled.View({
  marginTop: SpacingNumbers.l,
  alignSelf: 'flex-start',
});
