import React, { useState, useEffect } from 'react';

import {
  Container,
  EmailInput,
  PasswordInput,
  ErrorMessage,
  StyledButton,
} from './LoginScreen.style';
import { useAuth } from '../../services/auth/context';
import { colorUsage } from '../../stylesheet';
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import StepperScreen from '../../components/organism/StepperScreen/StepperScreen';

export default function LoginScreen(): JSX.Element {
  const [email, onEmailChange] = useState('');
  const [password, onPasswordChange] = useState('');

  const { login, loginError, resetErrors } = useAuth();

  const onLoginPress = () => {
    login?.(email, password);
  };

  const disabled = email === '' || password === '';
  const hasError = loginError !== null;

  useEffect(() => {
    resetErrors();
  }, [resetErrors]);

  return (
    <Container>
      <StepperScreen
        title={`Ravis de vous revoir !\nConnectez-vous avec votre adresse mail enregistrée et votre mot de passe.`}
      >
        <EmailInput
          label="Email"
          onChangeText={onEmailChange}
          placeholder="example@clickandcare.com"
          value={email}
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          autoCorrect={false}
          autoCompleteType="email"
          placeholderTextColor={colorUsage.placeHolderText}
          Icon={
            <MaterialCommunityIcons
              name="email-outline"
              size={16}
              color={colorUsage.placeHolderText}
            />
          }
          hasError={hasError}
        />
        <PasswordInput
          label="Mot de passe"
          onChangeText={onPasswordChange}
          placeholder="••••••••••"
          value={password}
          secureTextEntry
          placeholderTextColor={colorUsage.placeHolderText}
          Icon={
            <Feather name="key" size={16} color={colorUsage.placeHolderText} />
          }
          hasError={hasError}
        />

        {loginError && (
          <ErrorMessage>Identifiant ou mot de passe incorrect</ErrorMessage>
        )}
      </StepperScreen>
      <StyledButton
        title="Se connecter"
        onPress={onLoginPress}
        disabled={disabled}
      />
    </Container>
  );
}
