import React from 'react';
import Input, { InputProps } from '../../atoms/Input/Input';

export const NumberInput = (props: InputProps): JSX.Element => {
  const onChangeText = (text: string) => {
    props.onChangeText?.(text.replace(/[^\d/]/g, ''));
  };
  return (
    <Input
      {...props}
      onChangeText={onChangeText}
      value={props.value?.replace(/[^\d/]/g, '')}
      keyboardType="number-pad"
    />
  );
};
