import { Dimensions, TouchableOpacity } from 'react-native';
import styled from '@emotion/native';
import { colorUsage, SpacingNumbers, titleH3 } from '../../../stylesheet';

export const Container = styled.View({
  width: Dimensions.get('window').width,
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const Opacity = styled.View({
  backgroundColor: colorUsage.popinBackground,
  opacity: 0.5,
  flex: 1,
});

export const HeaderTitle = styled(titleH3)({});

export const ContentContainer = styled.View({
  backgroundColor: colorUsage.calendarBackground,
  paddingTop: SpacingNumbers.m,
});

export const HeaderContainer = styled.View({
  backgroundColor: colorUsage.calendarBackground,
  width: '100%',
  height: 70,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  /* border-bottom property does not work */
  borderStyle: 'solid',
  borderBottomColor: colorUsage.calendarBorderBottom,
  borderBottomWidth: 1,
});

export const CloseIconContainer = styled(TouchableOpacity)({
  position: 'absolute',
  right: SpacingNumbers.l,
});
