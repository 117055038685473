import styled from '@emotion/native';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import { colorUsage, SpacingNumbers, textP2Regular } from '../../stylesheet';

export const ButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
`;

export const Container = styled.View({
  flex: 1,
});

export const ImageContainer = styled.View`
  width: 100%;
  height: 80%;
`;

export const IconAndTextContainer = styled.View({
  position: 'absolute',
  alignItems: 'center',
  paddingHorizontal: 16,
  marginBottom: 24,
  bottom: 0,
  width: '100%',
});

export const WelcomeText = styled(textP2Regular)`
  color: ${colorUsage.menuActiveTextColor};
  text-align: center;
`;

export const LoginButton = styled(PrimaryButton)({
  marginBottom: SpacingNumbers.m,
});

export const AccountCreationButton = styled(PrimaryButton)({});
