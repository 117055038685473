import React from 'react';
import { FourCharacterSpacedInput } from '../../../../components/molecules/FourCharacterSpacedInput/FourCharacterSpacedInput';
import {
  ErrorMessage,
  InputAndErrorContainer,
} from '../../PaymentInfosScreen.style';

type Props = {
  onChangeText: (text: string) => void;
  value: string;
  error?: string;
};

const IbanInput = ({ onChangeText, value, error }: Props): JSX.Element => {
  const ibanHasError = !!error && value !== 'FR';
  return (
    <InputAndErrorContainer>
      <FourCharacterSpacedInput
        label="IBAN"
        onChangeText={onChangeText}
        value={value}
        maxLength={33}
        isRequired
        hasError={ibanHasError}
        testID="IbanInput"
      />
      {ibanHasError && <ErrorMessage>{error}</ErrorMessage>}
    </InputAndErrorContainer>
  );
};

export default IbanInput;
