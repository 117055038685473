import styled from '@emotion/native';
import {
  BorderRadiusNumbers,
  BoxShadow,
  colorUsage,
  SpacingNumbers,
  textP1Bold,
  textP2Regular,
} from '../../../stylesheet';

export const MonthTitle = styled(textP1Bold)({
  textAlign: 'left',
  color: colorUsage.monthlyDeclarationMonthTitle,
});

export const CardDetails = styled(textP2Regular)({
  color: colorUsage.monthlyDeclarationCardDetails,
  marginLeft: SpacingNumbers.s,
  alignItems: 'center',
});

export const CardContainer = styled.TouchableOpacity({
  borderRadius: BorderRadiusNumbers.s,
  ...BoxShadow.shadowLight,
  shadowOpacity: BoxShadow.shadowLightOpacity,
  elevation: BoxShadow.shadowLightElevation,
  padding: SpacingNumbers.m,
  backgroundColor: colorUsage.monthlyDeclarationCardBackground,
  marginBottom: SpacingNumbers.m,
});

export const CardDetailsContainer = styled.View({
  flexDirection: 'row',
  marginTop: SpacingNumbers.s,
});

export const ReportsCountContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
