import React, { useState, useEffect } from 'react';
import { Camera } from 'expo-camera';
import { Text, View } from 'react-native';
import {
  Container,
  TakePictureButton,
  StyledCamera,
} from './CameraScreen.style';
import { useNavigation, useRoute } from '../../../../navigation/types';

export default function CameraScreen(): JSX.Element {
  const { goBack } = useNavigation();
  const {
    params: { onPhotoChange },
  } = useRoute<'CameraScreen'>();
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  let camera: Camera | null;

  const onPictureTaken = async () => {
    const photo = await (camera as Camera).takePictureAsync({
      base64: true,
      quality: 0.01,
    });
    onPhotoChange(photo);
    goBack();
  };

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <Container>
      <StyledCamera
        type="back"
        ref={ref => {
          camera = ref;
        }}
      >
        <TakePictureButton
          testID="RoundButton"
          onPress={() => {
            onPictureTaken();
          }}
        />
      </StyledCamera>
    </Container>
  );
}
