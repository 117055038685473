import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { buttonTextColorState } from '../../molecules/PrimaryButton/PrimaryButton';
import StyledCalendarPicker from '../../molecules/CalendarPicker/CalendarPicker';
import {
  Container,
  StyledButton,
} from './CalendarPickerModalContent.style.native';

type Props = {
  date: Date | null;
  onPressValidate: (date: Date | null) => void;
  setDate: (date: Date) => void;
  minDate?: Date;
  initialDate: Date;
};

const CalendarPickerModalContent = ({
  date,
  onPressValidate,
  setDate,
  minDate,
  initialDate,
}: Props): JSX.Element => {
  const isButtonDisabled: boolean = date === null;

  return (
    <Container>
      <StyledCalendarPicker
        initialDate={initialDate}
        date={date}
        setDate={setDate}
        minDate={minDate}
      />
      <StyledButton
        title="Valider"
        onPress={() => onPressValidate(date)}
        fullWidth
        LeftIcon={
          <AntDesign
            name="check"
            size={16}
            color={buttonTextColorState(false, isButtonDisabled)}
          />
        }
        disabled={isButtonDisabled}
      />
    </Container>
  );
};

export default CalendarPickerModalContent;
