import { getDistance, convertDistance } from 'geolib';

export const calcDistance = (
  originLat: number,
  originLng: number,
  destinationLat: number,
  destinationLng: number,
): number => {
  return convertDistance(
    getDistance(
      { latitude: originLat, longitude: originLng },
      { latitude: destinationLat, longitude: destinationLng },
    ),
    'km',
  );
};
