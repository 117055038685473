/* eslint-disable max-lines */
import React from 'react';
import StepperScreen from '../../../../components/organism/StepperScreen/StepperScreen';
import {
  Container,
  StyledInput,
  FormContainer,
  StyledSingleChoiceInput,
  StyledNumberInput,
  CheckBoxContainer,
  Label,
} from '../../CandidateIdentity.style';
import { Formik } from 'formik';
import { AntDesign } from '@expo/vector-icons';
import { colorUsage } from '../../../../stylesheet';
import PrimaryButton from '../../../../components/molecules/PrimaryButton/PrimaryButton';
import { Gender } from '../../../FindCaregiverBeneficiaryInfo/types';
import { useNavigation } from '../../../../navigation/types';
import { useUpdateCareGiver } from '../../../../redux/users/hook';
import { CareGiver, Gender as GENDER } from '../../../../redux/users/types';
import {
  IdentityFormValuesType,
  initValues,
  validationSchema,
} from './service';
import CustomKeyboardAvoidingView from '../../../../components/molecules/KeyBoardAvoidingView/KeyboardAvoidingView';
import { StyledCandidateIdentityAddressInput } from './CandidateIdentityForm.style';
import Switch from '../../../../components/molecules/Switch/Switch';

interface Props {
  careGiver: CareGiver;
}

export default function CandidateIdentityForm({
  careGiver,
}: Props): JSX.Element {
  const [, doUpdateCareGiver] = useUpdateCareGiver();
  const { navigate } = useNavigation();

  let isButtonDisabled: boolean;
  let onPressChoice: (value: string) => void;

  const submitForm = async (values: IdentityFormValuesType): Promise<void> => {
    await doUpdateCareGiver({
      careGiverCharacteristics: {
        person: {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender as GENDER,
          address: values.address,
          phone: values.phoneNumber,
          numberSocialSecurity: values.socialSecurityNumber,
        },
        hasCar: values.hasCar,
      },
    });
    navigate('IdentityDocuments');
  };

  return (
    <CustomKeyboardAvoidingView>
      <Container>
        <StepperScreen title={`1/2 -\n Renseignez votre identité`}>
          <Formik
            initialValues={initValues(careGiver)}
            validationSchema={validationSchema}
            onSubmit={submitForm}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              isValid,
              setFieldValue,
            }) => {
              isButtonDisabled = !isValid;
              onPressChoice = (value: string) => setFieldValue('gender', value);
              return (
                <FormContainer>
                  <StyledSingleChoiceInput
                    label="Civilité"
                    choices={Gender}
                    isRequired
                    onPressChoice={onPressChoice}
                    chosenTitle={values.gender}
                  />
                  <StyledInput
                    label="Nom de famille"
                    onChangeText={handleChange('lastName')}
                    value={values.lastName}
                    placeholder="Nom"
                    placeholderTextColor={colorUsage.placeHolderText}
                    isRequired
                  />
                  <StyledInput
                    label="Prénom"
                    onChangeText={handleChange('firstName')}
                    value={values.firstName}
                    placeholder="Prénom"
                    placeholderTextColor={colorUsage.placeHolderText}
                    isRequired
                  />

                  <StyledCandidateIdentityAddressInput />

                  <StyledNumberInput
                    label="Téléphone"
                    onChangeText={handleChange('phoneNumber')}
                    value={values.phoneNumber}
                    placeholder="Téléphone"
                    placeholderTextColor={colorUsage.placeHolderText}
                    isRequired
                  />

                  <StyledNumberInput
                    label="Numéro de sécurité sociale"
                    onChangeText={handleChange('socialSecurityNumber')}
                    value={values.socialSecurityNumber}
                    placeholder="Numéro de sécurité sociale"
                    placeholderTextColor={colorUsage.placeHolderText}
                    isRequired
                  />

                  <CheckBoxContainer>
                    <Label>Avez-vous une voiture ?</Label>
                    <Switch
                      testID="carCheckBox"
                      onValueChange={value => {
                        setFieldValue('hasCar', value);
                      }}
                      value={values.hasCar}
                    />
                  </CheckBoxContainer>

                  <PrimaryButton
                    title="Prochaine étape"
                    onPress={handleSubmit}
                    light
                    RightIcon={
                      <AntDesign
                        name="arrowright"
                        size={13}
                        color={
                          isButtonDisabled
                            ? colorUsage.buttonDisabledTextLight
                            : colorUsage.buttonTextLight
                        }
                      />
                    }
                    disabled={isButtonDisabled}
                  />
                </FormContainer>
              );
            }}
          </Formik>
        </StepperScreen>
      </Container>
    </CustomKeyboardAvoidingView>
  );
}
