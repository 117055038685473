import { AntDesign, Feather } from '@expo/vector-icons';
import React, { FunctionComponent, useState } from 'react';
import { Image } from 'react-native';
import {
  useFetchApplications,
  useGetAnnounceByFamily,
  useValidateApplicationById,
} from '../../../../redux/announce/hooks';
import ModalPopin from '../../../../components/molecules/ModalPopin/ModalPopin';
import { Application } from '../../../../redux/announce/types';
import { Gender } from '../../../../redux/users/types';
import { colorUsage } from '../../../../stylesheet';
import { makeCall } from '../../../__Utils__/Linking';
import { getGroupedSchedulesSortedByDays } from '../../../__Utils__/scheduleProcessing';
import { formatPhoneNumber } from '../../../__Utils__/textFormatting';
import Availability from '../Availability/Availability';
import ConfirmationModalContent from '../ConfirmationModalContent/ConfirmationModalContent';
import {
  ApplicationCardContainer,
  Certification,
  FirstName,
  GreetingsText,
  HeaderContainer,
  HeaderInfosContainer,
  PhoneContainer,
  Phone,
  BodyContainer,
  StyledPrimaryButton,
  UnderLinedText,
} from './ApplicationCard.style.native';

interface Props {
  application: Application;
}

const getImageSourceFromGender = (gender: Gender) => {
  if (gender === 'woman') {
    return require('../../../../assets/images/avatar-woman.png');
  }
  return require('../../../../assets/images/avatar-man.png');
};

const getCertificationText = (gender: Gender | undefined) => {
  return `Qualifié${gender === 'woman' ? 'e' : ''} par Click&Care`;
};

export const ApplicationCard: FunctionComponent<Props> = ({ application }) => {
  const groupedSchedulesSortedByDays = getGroupedSchedulesSortedByDays(
    application.schedules,
  );
  const careGiver = application.careGiver.person;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [, doValidateApplicationById] = useValidateApplicationById();

  const [, doUseFetchAnnounceByFamily] = useGetAnnounceByFamily();

  const [, doUseFetchApplications] = useFetchApplications();

  const onPressConfirm = () => {
    doValidateApplicationById({ id: application.id });
    doUseFetchApplications();
    doUseFetchAnnounceByFamily();
    setIsConfirmationModalOpen(false);
  };

  return (
    <ApplicationCardContainer>
      <HeaderContainer>
        {careGiver.gender && (
          <Image
            source={getImageSourceFromGender(careGiver.gender)}
            style={{ width: 48, height: 48 }}
          />
        )}
        <HeaderInfosContainer>
          <GreetingsText>Bonjour, je suis</GreetingsText>
          <FirstName>{careGiver.firstName}</FirstName>
          <Certification>
            {getCertificationText(careGiver.gender)}
          </Certification>
        </HeaderInfosContainer>
      </HeaderContainer>
      <BodyContainer>
        {careGiver.phone !== undefined && (
          <PhoneContainer>
            <Feather
              name="phone"
              size={13.33}
              color={colorUsage.phoneNumberTextColor}
            />
            <UnderLinedText>
              <Phone
                onPress={() =>
                  makeCall(formatPhoneNumber(careGiver.phone as string))
                }
              >
                {formatPhoneNumber(careGiver.phone)}
              </Phone>
            </UnderLinedText>
          </PhoneContainer>
        )}
        <Availability
          firstName={careGiver.firstName as string}
          groupedSchedulesSortedByDays={groupedSchedulesSortedByDays}
        />
      </BodyContainer>

      <StyledPrimaryButton
        title={`Recruter ${careGiver.firstName}`}
        onPress={() => setIsConfirmationModalOpen(true)}
        LeftIcon={
          <AntDesign name="check" size={18} color={colorUsage.buttonText} />
        }
      />
      <ModalPopin
        title={`C'est parti avec ${careGiver.firstName} ?`}
        onClose={() => setIsConfirmationModalOpen(false)}
        visible={isConfirmationModalOpen}
        content={
          <ConfirmationModalContent
            onPressAbort={() => setIsConfirmationModalOpen(false)}
            firstName={careGiver.firstName as string}
            onPressConfirm={onPressConfirm}
          />
        }
      />
    </ApplicationCardContainer>
  );
};
