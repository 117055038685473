import { DrawerNavigationOptions } from '@react-navigation/drawer';
import {
  NavigationProp,
  PathConfig,
  useNavigation as useNavigationBase,
} from '@react-navigation/native';
import { IdCheckRoute } from '../types';
export interface DrawerRoutes
  extends IdCheckRoute<
    DrawerNavigationOptions,
    CareGiverDrawerParamList & FamilyDrawerParamList
  > {
  pathConfig?: PathConfig<CareGiverDrawerParamList & FamilyDrawerParamList>;
}

export enum CareGiverDrawerScreenTitles {
  MY_RDV = 'Mes RDVs',
  DECLARE_MY_HOURS = 'Déclarer mes heures ',
  MY_SALARY = 'Mes salaires',
  MY_CONTRACTS = 'Mes contrats',
  FIND_A_MISSION = 'Trouver une mission',
  CANDIDATE_PROFILE = 'Mon profil candidat',
  SETTINGS = "Paramètres de l'app",
}

export enum CareGiverDeepLinkPath {
  MY_RDV = 'homes',
  DECLARE_MY_HOURS = 'my-hours',
  MY_SALARY = 'my-salary',
  MY_CONTRACTS = 'my-contracts',
  FIND_A_MISSION = 'find-a-mission',
  CANDIDATE_PROFILE = 'profile',
  SETTINGS = 'settings',
}

export type CareGiverDrawerParamList = {
  Home: undefined;
  DeclareMyHours: undefined;
  MySalary: { type: 'CAREGIVER' | 'FAMILY' };
  MyContracts: undefined;
  FindAMission: undefined;
  CandidateProfile: undefined;
  Settings: undefined;
};

export type CareGiverDrawerRouteName = keyof CareGiverDrawerParamList;

export const useNavigation = (): NavigationProp<CareGiverDrawerParamList> =>
  useNavigationBase<NavigationProp<CareGiverDrawerParamList>>();

export enum FamilyDrawerScreenTitles {
  FIND_A_CAREGIVER = 'Trouver un intervenant',
  SETTINGS = "Paramètres de l'app",
  VALIDATE_HOURS = 'Valider les heures',
  PAYMENTS = 'Mes paiements',
}

export enum FamilyDeepLinkPath {
  FIND_A_CAREGIVER = 'find-a-caregiver',
  SETTINGS = 'settings',
  VALIDATE_HOURS = 'validate-hours',
  PAYMENTS = 'payments',
}

export type FamilyDrawerParamList = {
  FindACaregiver: undefined;
  Settings: undefined;
  ValidateHours: undefined;
  Payments: { type: 'CAREGIVER' | 'FAMILY' };
};

export type FamilyDrawerRouteName = keyof FamilyDrawerParamList;
