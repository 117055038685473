import { ANNOUNCE_TYPES } from './../announce/types';
export interface Beneficiary {
  id: string;
  person: Person;
  onSiteEquipment: OnSiteEquipment[];
  pets: Pets[];
  pathology: Pathology[];
  diet: Diet[];
}
export interface Institution {
  id: string;
  name: string;
  address: Address;
}

export interface Address {
  address: string;
  city: string;
  postCode: string;
  gpsLat?: number;
  gpsLng?: number;
}

export interface Person {
  firstName?: string | null;
  lastName?: string | null;
  birthDay?: string | null;
  phone?: string;
  email?: string;
  numberSocialSecurity?: string;
  gender?: Gender;
  address: Address | null;
}

export enum OnSiteEquipment {}

export enum Pets {}

export enum Pathology {}

export enum Diet {}

export type Gender = 'man' | 'woman';

export interface CareGiver {
  id: string;
  distanceMaxCriteria: number | null;
  startDateCriteria: string | null;
  announceTypesCriteria: ANNOUNCE_TYPES[];
  siret: string;
  nanCesu: string;
  sapNova: string;
  hasCar: boolean | null;
  person: Person;
}

export type CareGiverCharacteristics = {
  distanceMaxCriteria?: number;
  startDateCriteria?: string;
  siret?: string;
  nanCesu?: string;
  sapNova?: string;
  hasCar?: boolean;
  person?: Partial<Person>;
};

export enum USER_TYPES {
  FAMILY = 'family_user',
  CARE_GIVER = 'care_giver',
}

export enum CAREGIVER_DOCUMENT_TYPE {
  CNI_VERSO = 'cni-verso',
  CNI_RECTO = 'cni-recto',
}

export type CareGiverDocuments = {
  cniRectoId?: string;
  cniVersoId?: string;
};
