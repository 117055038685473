import { AntDesign } from '@expo/vector-icons';
import React, { useState } from 'react';
import { SectionList } from 'react-native';
import ModalPopin from '../../components/molecules/ModalPopin/ModalPopin';
import { useNavigation } from '../../navigation/types';
import { usePostValidateHourDeclaration } from '../../redux/hourDeclaration/hooks';
import { colorUsage, SpacingNumbers } from '../../stylesheet';
import {
  getGroupedDetailsSortedByAppointmentDate,
  getSalaryForDeclaration,
} from '../__Utils__/hourDeclarationProcessing';
import {
  formatDateWithDayInWeek,
  formatHour,
} from '../__Utils__/textFormatting';
import {
  DateTitle,
  ScheduleText,
  ScreenContainer,
  StyledButton,
} from './HourDeclarationDetailScreen.style';
import { getModalContent, getModalType, Props, getModalTitle } from './utils';

const HourDeclarationDetailScreen = ({ route }: Props): JSX.Element => {
  const hourDeclaration = route.params.hourDeclaration;
  const canBeValidated = route.params.hourDeclarationCanBeValidated;

  const modalType = getModalType(hourDeclaration);
  const ModalContent = getModalContent(modalType);
  const modalTitle = getModalTitle(modalType);

  const sortedAndGroupedDetails = getGroupedDetailsSortedByAppointmentDate(
    hourDeclaration.details,
  );
  const section = Object.values(sortedAndGroupedDetails).map(details => ({
    date: formatDateWithDayInWeek(details[0].appointmentDate),
    data: details,
  }));

  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
  const [, validateHourDeclaration] = usePostValidateHourDeclaration();
  const { goBack } = useNavigation();

  const onPressValidate = async (cesuAmount?: number) => {
    await validateHourDeclaration({ id: hourDeclaration.id, cesuAmount });
    setIsValidateModalOpen(false);
    goBack();
  };

  return (
    <ScreenContainer>
      <SectionList
        contentContainerStyle={{ paddingTop: SpacingNumbers.l }}
        sections={section}
        keyExtractor={(item, index) => item.id + index}
        renderItem={({ item }) => (
          <ScheduleText>
            {formatHour(item.startHour)} à {formatHour(item.endHour)}
          </ScheduleText>
        )}
        renderSectionHeader={({ section: { date } }) => (
          <DateTitle>{date}</DateTitle>
        )}
        stickySectionHeadersEnabled={false}
      />
      {canBeValidated && (
        <>
          <StyledButton
            title={`Je valide les heures (${getSalaryForDeclaration([
              route.params.hourDeclaration,
            ])}€)`}
            LeftIcon={
              <AntDesign name="check" size={18} color={colorUsage.buttonText} />
            }
            onPress={() => setIsValidateModalOpen(true)}
          />
          <ModalPopin
            title={modalTitle}
            visible={isValidateModalOpen}
            onClose={
              /* istanbul ignore next */
              () => {
                setIsValidateModalOpen(false);
              }
            }
            content={<ModalContent onPressValidate={onPressValidate} />}
          />
        </>
      )}
    </ScreenContainer>
  );
};

export default HourDeclarationDetailScreen;
