import styled from '@emotion/native';
import PrimaryButton from '../../components/molecules/PrimaryButton/PrimaryButton';
import {
  colorUsage,
  SpacingNumbers,
  textP2Regular,
  titleH1,
  titleH3,
} from '../../stylesheet';
import Chip from '../../components/atoms/Chip/Chip';
import Underline from '../../components/atoms/Underline/Underline';

export const MissionScreenContainer = styled.ScrollView({
  paddingTop: SpacingNumbers.l,
  paddingHorizontal: SpacingNumbers.m,
  backgroundColor: colorUsage.contentBackground,
});
export const MissionScreenTitle = styled(titleH1)({
  textAlign: 'left',
  marginBottom: SpacingNumbers.m,
});

export const GeneralInfoContainer = styled.View({
  alignItems: 'flex-start',
  borderBottomWidth: 1,
  borderBottomColor: colorUsage.missionScreenLineColor,
  width: '100%',
  paddingBottom: SpacingNumbers.s,
});

export const ServiceContainer = styled.View({
  marginTop: SpacingNumbers.s,
  marginBottom: SpacingNumbers.m,
});

export const ServiceTitle = styled(titleH3)({
  color: colorUsage.missionScreenTitle,
  marginTop: SpacingNumbers.xs,
  marginBottom: SpacingNumbers.s,
});

export const StyledText = styled(textP2Regular)({
  color: colorUsage.missionScreenTextContent,
  marginLeft: SpacingNumbers.s,
  marginRight: SpacingNumbers.m,
});

export const StyledTextUnderlined = styled(textP2Regular)({
  color: colorUsage.missionScreenTextContent,
});

export const IconTextContainer = styled.View({
  marginBottom: SpacingNumbers.m,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
});

export const ChipsContainer = styled.View({
  marginVertical: SpacingNumbers.xs,
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const StyledChip = styled(Chip)({
  marginVertical: SpacingNumbers.xs,
  marginRight: SpacingNumbers.s,
});

export const UnderlinedText = styled(Underline)({
  marginLeft: SpacingNumbers.s,
  marginRight: SpacingNumbers.m,
  borderBottomColor: colorUsage.missionScreenTextContent,
});

export const StyledButton = styled(PrimaryButton)({
  alignSelf: 'center',
  marginTop: SpacingNumbers.xs,
  marginBottom: SpacingNumbers.xxxl,
});
