import { Linking, Platform } from 'react-native';

export const openMap = (address: string): void => {
  Linking.openURL(`https://www.google.com/maps/search/?api=1&query=${address}`);
};

export const makeCall = (phoneNumber: string | undefined): void => {
  if (phoneNumber === undefined) return;
  if (Platform.OS === 'android') {
    phoneNumber = `tel:${phoneNumber}`;
  } else {
    phoneNumber = `telprompt:${phoneNumber}`;
  }
  Linking.openURL(phoneNumber);
};
